.ColorClass {
    text-align: center;
    color: $black_solid;
}
.ChatView {
    display: flex;
    align-items: flex-end;
}
.Color-class-mango {
    color: $darkMango;
}
.active-video-container {
    background-color: white !important;
    background-image: url("../../assets/images/iconsBrandBra02.png") !important;
    background-repeat: no-repeat !important;
    background-size: 145px 145px !important;
    background-position-x: center !important;
    background-position-y: 35% !important;
    .active-video-container__wrap{
        width: 100% !important;
        height: 100vh !important;
    }
}
.active-video-container__avatar-name {
    color :$black_solid !important
}
.extensionButtonSpacing{
    padding :10px;
    .buttonwidth{
        width: 130px !important;
        padding: 10px 28px !important;
    }
}
.active-video-container__avatar{
    position:relative !important;
    top: 70px !important;
}
@media (max-width: 1366px) {
    .main-layout {
        height: 973px;
    }
    .active-video-container {
        background-position-y: 24% !important;
    }
}
@media (max-width: 1024px) {
    .main-layout {
        height: 716px;
    }
    .active-video-container {
        background-position-y: 24% !important;
    }
    //     background-position-y: 24% !important;

}
@media (max-height: 769px) {
    // .active-video-container {
    //     background-position-y: 24% !important;
    // }
}
