.lesson-card {
    margin: 11px;
    float: right;
    white-space: break-spaces;
    .lesson-card-content {
	position: relative;
	width: 132px;
	height: 206px;
	margin: 17px;
	font-family: $primary_font_Medium;
	font-size: 16px;
	line-height: 22px;
	.lesson-number {
	    color: $darkMango;
	}
	.lesson-name {
	    color: $darkGrey;
	}
	.completion {
	    position: absolute;
	    height: 49px;
	    width: 49px;
	    left: 79px;
	    top: 144px;
	}
    }
}
