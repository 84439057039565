.tutor-review {
    .custom-check {
        width: fit-content;
        float: left;
    }
    .custom-check .checkmark {
        top: 4px;
        left: 0;
        height: 19px;
        width: 18px;
    }
    .custom-check .container .checkmark:after {
        left: 5px;
        top: 0px;
    }
    .sub-heading {
        font-weight: 800;
    }
}
.no-border {
    border: none !important;
}
.nav-btn-container {
    margin-top: 10px !important;
}
.tutor-review-content {
    margin: auto;
    max-width: 70%;
    font-family: $primary_font_Medium;
    border-bottom: 1px solid $paleGrey;
    padding-bottom: 10px;
    .button {
        .outline-button {
            color: $blue;
            border: solid 2px $blue;
            background-color: $white_solid;
        }
        .no-outline-button {
            color: $white_solid;
            border: solid 2px $blue;
            background-color: $blue;
        }
    }
    .MuiCheckbox-root {
        padding-left: 0;
    }
    .availability {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        .head {
            padding: 0px;
            padding-right: 5px;
        }
    }
    .basic-info {
        margin-bottom: 20px !important;
        display: flex;
        .tutor-info {
            width: calc(100% - 162px);
            padding-left: 24px;
        }
    }
    .row {
        margin: 0px;
    }
    img {
        border-radius: 100%;
        max-width: 100%;
    }

    h2 {
        display: inline-block;
        font-size: 1.953rem;
    }

    h3 {
        font-size: 1.1rem;
        display: inline-block;
    }

    p {
        color: $battleshipGrey;
        margin: 0;
    }
    .email {
        font-size: 20px;
        margin-bottom: 1.6rem;
    }

    .edit_button {
        width: 25px;
        height: 25px;
        display: inline-block;
        background-image: url(../../../assets/images/edit-blue.png);
        background-position: center center;
        background-repeat: no-repeat;
        margin-left: 10px;
        cursor: pointer;
        margin-bottom: -3px;
        svg {
            display: none;
        }
    }
    .edit_button.open {
        background-image: url(../../../assets/images/icons/check.svg);
    }
    .info_edit_block {
        width: 100%;
        p {
            width: fit-content;
            display: inline-block;
            font-family: $primary_font_SemiBold;
            font-size: 16px;
            color: $black_solid;
        }
        @media (max-width: 768px) {
            p {
                margin: auto;
                color: $black_solid;
            }
        }
        h2 {
            font-size: 30px;
            font-weight: 800;
        }
        h3 {
            font-size: 16px;
            font-weight: 800;
        }
        input {
            color: $mine_shaft;
        }
    }
    .rates_block {
        p {
            display: inline-block;
        }
    }
    .form-file-picker {
        width: 140px;
        height: 140px;
    }
    .profile-pic {
        width: 162px;
        height: 162px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $battleshipGrey;
    }
    @media (max-width: 1024px) {
        max-width: 90%;
    }
    @media (max-width: 768px) {
        max-width: 75%;
        .basic-info {
            .img-container {
                width: 20%;
            }
            .basic-info-text {
                padding: 0;
                width: 80%;
            }
        }
    }
    @media (max-width: 414px) {
        max-width: 90%;
        .basic-info {
            display: block;
            margin-bottom: 0 !important;
            .img-container {
                width: 100%;
                margin-bottom: 20px;
                img {
                    margin: auto;
                }
            }
            .basic-info-text {
                width: 100%;
            }
        }
    }
}

.tutor-review-availability {
    width: 100%;
    padding: 0;
    .day:not(:last-of-type) {
        border-bottom: #e1e1e1 1px dashed;
    }
    .day {
        height: fit-content;
        display: flex;
        padding: 5px 0px;
        .not-available {
            font-size: 14px;
            color: $battleshipGrey;
        }
        .slot-mobile {
            display: none;
        }
        p {
            margin: auto 0;
            width: 48px;
            font-size: 14px;
            font-family: $primary_font_Bold;
        }
        .selected-slots {
            margin: auto 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            span {
                margin: 1px 0;
            }
            .all-slots {
                width: 100%;
                .tabs {
                    p {
                        width: 100%;
                        text-align: left;
                        padding-left: 10px;
                    }
                }
            }
            .tabs {
                height: fit-content;
                width: fit-content;
                background-color: transparent;
                margin: 0;
                display: block;
                margin-right: 10px;
                p {
                    color: $white_solid;
                    width: fit-content;
                    width: 150px;
                    height: 29px;
                    background-color: $darkMango;
                    text-align: center;
                    line-height: 29px;
                    border-radius: 3px;
                }
            }
        }
    }
}

.tutor-review-availability-mobile {
    display: none;
    @media only screen and (max-width: 768px) {
        display: block;
        padding: 0;
        .simple-dropdown {
            width: 100%;
            min-height: fit-content;
            padding: 0 15px;
            .MuiSelect-selectMenu {
                height: 38px;
            }
        }
        .day {
            margin-bottom: 16px;
            display: flex;
            .not-available {
                display: none;
            }
            .slot {
                display: none;
            }
            .break {
                flex-basis: 100%;
                height: 0;
            }
            .item {
            }
            .slots-accordion {
                height: fit-content;
                min-height: 52px;
                padding: 16px 24px 14px;
                border-radius: 7px;
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
                width: 100%;
                margin-left: 20px;
                display: flex;
                flex-wrap: wrap;
                svg {
                    margin-left: auto;
                }
                i {
                    color: $battleshipGrey;
                    font-size: 16px;
                    font-family: $primary_font_Regular;
                }
                .no-of-slots {
                    flex: 1;
                    font-size: 16px;
                    font-family: $primary_font_SemiBold;
                }
                .slot-mobile:not(:last-of-type) {
                    border-bottom: solid 1px #e1e1e1;
                }
                .slot-mobile {
                    height: 52px;
                    width: 100%;
                    font-size: 16px;
                    font-family: $primary_font_SemiBold;
                    p {
                        line-height: 52px;
                    }
                }
            }
            .day-title {
                height: 52px;
                min-width: 60px;
                width: 60px;
                text-align: center;
                line-height: 52px;
                background-color: $battleshipGrey;
                color: $white_solid;
                border-radius: 6px;
                font-size: 14px;
                font-family: $primary_font_SemiBold;
            }
            .selected {
                background-color: $darkMango;
            }
        }
    }
}

table:nth-child(1) > thead:nth-child(1) > tr:nth-child(1) > th:nth-child(1),
table:nth-child(1) > thead:nth-child(1) > tr:nth-child(1) > th:nth-child(2) {
    width: 80px;
    height: 20px;
}

.mt-20 {
    margin-top: 20px;
}

@media only screen and (min-width: 769px) {
    .tutor-review-availability ul,
    .visible-xs {
        display: none;
    }
}
@media only screen and (min-width: 769px) {
    .info_mobile_block {
        display: none;
    }
}
@media only screen and (max-width: 768px) {
    .tutor-review-content .profile-pic {
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    .hidden-xs {
        display: none !important;
    }
    table {
        @media (max-width: 576px) {
            .button button {
                width: 150px;
            }
            .session-detail-card {
                width: unset;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .tutor-review-availability {
        ul {
            padding: 0 0 0 15px;
            list-style: none;

            li {
                display: flex;
                .dropdown-box-continer {
                    display: none;
                }
                .svg-inline--fa {
                    display: none;
                }
                input[type="text"]:focus {
                    outline: none !important;
                    outline-offset: 0 !important;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 65px;
                    border-radius: 6px;
                    background-color: $darkMango;
                    color: white;
                    text-transform: uppercase;
                }

                .multi-select-drop-down {
                    margin: 0;
                    padding: 0;
                    width: calc(100% - 80px);

                    .input-label {
                        display: none;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .tutor-review-content .info_edit_block {
        width: 100%;
        display: flex;
        align-items: flex-start;

        justify-content: space-between;

        h2 {
            font-size: 1.1rem;
        }
        h2,
        span,
        p {
            width: 100%;
            clear: both;
            display: block;
        }
        span.edit_button {
            background-color: $blue;
            height: 40px;
            width: 40px;
            min-width: 40px;
            background-size: 40px 40px;
            border-radius: 50%;
            display: flex;
            margin-left: 0;
            svg {
                display: block;
                margin: auto;
            }
        }
    }
    .flex-column {
        flex-direction: column;
    }
    .mt-25 {
        margin-top: 25px;
    }
    .tutor-name {
        width: calc(100% - 70px) !important;
    }
    .info_mobile_block {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        padding-left: 28px;
        padding-right: 5px;
        margin-bottom: 10px;
        .heading {
            color: $black_solid;
            font-family: $primary_font_SemiBold;
        }
        p {
            font-size: 18px;
            margin-bottom: 0px;
        }
        span.edit_button {
            background-color: $blue;
            height: 40px;
            width: 40px;
            background-size: 40px 40px;
            border-radius: 50%;
            display: flex;
            svg {
                display: block;
                margin: auto;
            }
        }
    }
    .rates_block {
        .info,
        .button {
            display: flex;
        }
        .info {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 414px) {
    .info_mobile_block {
        p {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 415px) {
    .tutor-review-content .form-file-picker {
        width: 120px;
        height: 120px;
    }
    .tutor-review-content .info_edit_block span.edit_button {
        height: 40px;
    }
    .tutor-review-content .rates_block p {
        font-size: 0.95rem;
    }
}
li.dropdown-item .custom-check .container .checkmark::after {
    top: 0;
    left: 0;
}
* > input {
    outline: none;
}

.no-padding {
    padding: 0 !important;
}
.checkbox-label {
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: $battleshipGrey;
    font-family: $primary_font_Regular;
}
.checkbox-label-bold{
    font-family: $primary_font_Bold;
}
.subscription-optins {
    .checkbox-label {
        font-weight: 600;
    }
}
@media (max-width: 767px) {
    .tutor-review-availability {
        table {
            display: none;
        }
    }
}
@media (max-width: 768px) {
    .tutor-review-availability {
        display: none;
    }
}
@media (max-width: 390px) {
    .tutor-review-availability {
        .multi-select-drop-down {
            input::-webkit-input-placeholder,
            input:-moz-placeholder,
            input::-moz-placeholder,
            input:-ms-input-placeholder {
                font-size: 12px;
            }
        }
    }
}
