.white-section {
    .promo-code {
        width: auto;
        padding: 35px 0px;
        margin-left: 35px;
        margin-right: 35px;
        justify-content: space-between;
        padding-top: 0px;
        @media (max-width: 768px) {
            margin-left: 110px;
            margin-right: 110px;
        }
        @media (max-width: 767px) {
            flex-direction: row;
            margin-left: 35px;
            margin-right: 30px;
        }
        @media (max-width: 576px) {
            flex-direction: row;
            margin-left: 15px;
            margin-right: 15px;
        }
        .promo-code-title {
            margin-bottom: 10px;
        }
        .custom-input {
            padding-bottom: 0px;
        }
        .promo-button-container {
            display: flex;
            margin: auto;
            .button {
                width: 100%;
                margin-left: 10px;
                margin-bottom: 20px;
                @media (max-width: 414px) {
                    margin: auto;
                    display: flex;
                }
                button {
                    @media (max-width: 414px) {
                        width: 120px;
                    }
                    width: inherit;
                    height: 38px;
                    font-size: 14px;
                }
            }
        }
        p {
            font-family: "Montserrat-Medium" !important;
            margin-bottom: 0px;
            font-size: 16px;
        }
    }
}
.subscription-payment {
    .white-section {
        .width100 {
            width: 100%;
        }
        .promo-details {
            margin-top: 10px;
            font-size: 14px;
        }
        .promo-code {
            margin-top: 25px;
            margin-left: 0px;
            margin-right: 0px;
            border-bottom: 1px solid #efeff3;
        }
    }
}
