.rating-group {
    display: inline-flex;
    margin-bottom: 20px;
}
.rating__icon {
    pointer-events: none;
}

.rating__input {
    display: none;
}

.rating__label {
    cursor: pointer;
    padding: 0 0.9375rem;
    font-size: 2.7rem;
    margin: 0;
    height: 56px;
    display: inline-block;
}

.rating__icon--star {
    color: $darkMango;
    g {
        fill: $darkMango;
        fillRule: evenodd;
    }
    @media (max-width:525px) {
        height: 25px;
        width: 25px;
    }
}

.rating__icon--none {
    color: #eee;
    g {
        fill: #eee;
        fillRule: evenodd;
    }
}

.rating__input--none:checked + .rating__label .rating__icon--none {
    color: red;
    g {
        fill: red;
        fillRule: evenodd;
    }
}

.rating__input:checked ~ .rating__label .rating__icon--star {
    color: #ddd;
    g {
        fill: #ddd;
        fillRule: evenodd;
    }
}

.rating-group:hover .rating__label .rating__icon--star {
    color: $darkMango;
    g {
        fill: $darkMango;
        fillRule: evenodd;
    }
}

.rating__input:hover ~ .rating__label .rating__icon--star {
    color: #ddd;
    g {
        fill: #ddd;
        fillRule: evenodd;
    }
}

.rating-group:hover .rating__input--none:not(:hover) + .rating__label .rating__icon--none {
    color: #eee;
    g {
        fill: #eee;
        fillRule: evenodd;
    }
}

.rating__input--none:hover + .rating__label .rating__icon--none {
    color: red;
    g {
        fill: red;
        fillRule: evenodd;
    }
}
