.step-bar {
    width: 100%;
    height: 5px;
    background-color: $battleshipGrey;
    position: fixed;
    top: 0;
    z-index: 9999;

    .mango {
        background-color: $darkMango;
    }
    .complete {
        height: 5px;
    }
}

@media only screen and (max-width: 768px) {
    .step-bar {
        top: unset;
        bottom: 0;
        z-index: 9999;
    }
}
