.background-container {
    width: 100%;
    overflow: hidden;
    @extend .position-relative;
    @extend .align-self-center;
}
.background-svg {
    z-index: -100;
    min-width: 100vw;
    width: 100%;
    height: 55vh;
    background: url(../../../assets/images/on-boarding-bg.svg) bottom -135px center no-repeat;
    background-size: cover;
    @extend .fixed-bottom;
}

@media only screen and (max-width: 768px) {
    .background-svg.hidden-xs {
        display: none;
    }
}
