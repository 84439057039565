.horizontal-scroll-container {
    overflow-x: scroll;
    width: 100%;
}
.scroll-button-container {
    position: relative;
}
.scroll-button {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: $darkMango;
}
.scroll-content::-webkit-scrollbar {
    display: none;
}
.scroll-content {
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.horizontal-scroll-container::-webkit-scrollbar {
    display: none;
}
.horizontal-scroll-container {
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.row-action-button {
    margin-top: 27px;
}

