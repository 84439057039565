//font variable
$primary_font_Black: Montserrat-Black;
$primary_font_BlackItalic: Montserrat-BlackItalic;
$primary_font_Bold: Montserrat-Bold;
$primary_font_BoldItalic: Montserrat-BoldItalic;
$primary_font_ExtraBold: Montserrat-ExtraBold;
$primary_font_ExtraBoldItalic: Montserrat-ExtraBoldItalic;
$primary_font_ExtraLight: Montserrat-ExtraLight;
$primary_font_ExtraLightItalic: Montserrat-ExtraLightItalic;
$primary_font_Italic: Montserrat-Italic;
$primary_font_Light: Montserrat-Light;
$primary_font_LightItalic: Montserrat-LightItalic;
$primary_font_Medium: Montserrat-Medium;
$primary_font_MediumItalic: Montserrat-MediumItalic;
$primary_font_Regular: Montserrat-Regular;
$primary_font_SemiBold: Montserrat-SemiBold;
$primary_font_SemiBoldItalic: Montserrat-SemiBoldItalic;
$primary_font_Thin: Montserrat-Thin;
$primary_font_ThinItalic: Montserrat-ThinItalic;
$secondary_font_regular: OpenSans-Regular;
$side_bar_width: 200px;


//font sizes
$extra_large_font: 24px;
$large_font: 20px;
$medium_font: 18px;
$small_font: 16px;
$extra_small_font: 14px