.scroll-content {
    .session-detail-card {
        width: 280px;
    }
}

.md-screen {
    .session-detail-card {
        button {
            right: 30px;
            left: auto;
        }
    }
}
.session-detail-card {
    width: 100%;
    height: 195px;
    position: relative;
    margin: 0px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 10px;
    float: left;
    > div {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 20px 15px;
    }
    img {
        height: 38px;
        width: 38px;
        border-radius: 50%;
    }
    .card-content {
        font-size: 14px;
        color: $darkGrey;
        line-height: 20px;
        font-family: $primary_font_Medium;

        .tutor-name {
            font-size: 20px;
            color: $darkMango;
            line-height: 28px;
            margin: 0;
        }
        .subject-name {
            font-size: $extra_small_font;
            line-height: 18px;
            margin-bottom: 15px;
            font-family: $primary_font_Medium;
        }
        .session-date {
            margin: 8px 0 11px 0px;
        }
        .duration-label {
            font-family: $primary_font_Regular;
        }
    }
    button {
        position: absolute;
        left: 70px;
        bottom: -24px;
        width: 160px;
        font-size: $extra_small_font;
        font-family: $primary_font_Bold;
        height: 40px;
    }
    .outline-button {
        width: 160px;
        height: 40px;
    }
    .tutor-name {
        width: 150px;
        // background-color: greenyellow;
    }
}
@media (max-width: 1024.95px) {
    .session-detail-card {
        width: 100%;
        margin: 20px;
        margin-right: 0px;
        margin-left: 0px;
        height: auto !important;
    }
    .dashboard-upcoming-card-view {
        display: flex;
        padding: 15px;
    }
    .dashboard-upcoming-card-content {
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
        width: 80%;
    }
}
@media (max-width: 576px) {
    .session-detail-card {
        height: auto !important;
    }
    .dashboard-upcoming-card-content {
        width: inherit;
        flex-direction: column;
        justify-content: unset;
    }
    .upcoming-time-div {
        position: relative;
        left: -50px;
    }
}
