
.modal {
    @extend .d-flex;
}
.modal-dialog {
    @extend .m-auto;
    @extend .mw-100;
}
.popup-view {
    .modal-content {
        width: 938px;
        height: 426px;
        border-radius: 25px 0;
    }
    .close-modal {
        text-align: end;
    }

    .modal-container {
        margin-top: 39px;
        @extend .text-center;
        .main-content {
            @extend .normal-font-style;
            font-family: $primary_font_Medium;
            font-size: 30px;
            font-weight: 500;
            line-height: 1.53;
            color: $battleshipGrey;
        }
        .sub-content {
            @extend .normal-font-style;
            @extend .font-weight-normal;
            font-family: $primary_font_Regular;
            font-size: 20px;
            line-height: 1.4;
            color: $darkMango;
        }
    }
}

//Alert-box
.alert-box {
    .modal-content {
        @extend .text-center;
        border-radius: 20px;
    }
    .modal-body {
        padding: 1rem 4rem;
        .title {
            width: 200px;
            font-weight: 600;
            font-size: 16px;
        }
        .sub-title {
            font-size: 14px;
            width: 200px;
        }
    }
    .modal-footer {
        @extend .justify-content-center;
        color: $blue;
    }
}
.toast-notification {
    .success-toast {
        background-color: $greenishTeal !important;
    }
    .error-toast {
        background-color: $coral !important;
    }
    .toast.fade {
        @extend .normal-font-style;
        position: fixed;
        bottom: 23px;
        right: 42px;
        z-index: 999;
        max-width: 70%;
        font-family: $primary_font_Medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        color: #ffffff;
        display: flex;
    }
    .toast.show {
        @extend .toast.fade;
        .toast-body {
            padding: 1.1rem;
        }
        .close-toast-container {
            display: flex;
            margin: auto 15px;
            .close-toast {
                cursor: pointer;
            }
        }
    }
}
.paymet-card-modal{
    height: fit-content !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto;
    .custom-modal-content{
        margin-top: 0 !important;
    }
}
.extension-modal{
    height: fit-content ;
}