.extention-modal-body {
    .custom-modal-content {
        margin-top: 30px !important;
        h2 {
            font-size: 38px;
        }
        h3 {
            font-size: 30px;
        }
        h4 {
            font-size: 26px;
        }
        .button {
            button {
                margin-top: 10px;
            }
            .outline-button {
                width: 200px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .extention-modal-body {
        .custom-modal-content {
            max-height: 90vh;
        }
    }
}
@media (max-width: 768px) {
    .extention-modal-body {
        .custom-modal-content {
            max-height: 80vh;
            h2 {
                font-size: 30px;
            }
            h3 {
                font-size: 26px;
            }
            h4 {
                font-size: 20px;
            }
        }
    }
}
@media (max-width: 414px) {
    .extention-modal-body {
        .custom-modal-content {
            margin: 0px !important;
            height: 100vh !important;
            width: 100vh !important;
            max-height: 100vh !important;
            border-radius: 0px !important;
        }
    }
}
