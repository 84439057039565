.landing-page {
    @extend .position-relative;
    @extend .d-flex;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    .landing-page-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        margin: 40px 50px;
        border-radius: 50px 0;
        // min-height: 100%;
        width: 100%;
        background: url("../../../assets/images/landing-screen.svg");
        background-repeat: no-repeat;
        background-size: cover;
        .welcome-logo-btn-signin{
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 30px;
        }
        .color-logo-sigin-screen{
            height: 75pt;
        }
        .sign-in-button {
            @extend .d-block;
          
           Button{
            height: 62px !important;
            width: 166px !important;
            border-radius: 31px !important;
            @media (max-width:576px){
                height: 45px !important;
                width: 120px !important;
            }

           }

        }
        .start-button {
            padding-bottom: 20px;
            padding-top: 40px;
            margin: 0px auto;
        }
        .content {
            margin: 0px auto;
            width: 60%;
            height: 80%;
            @media (min-width:576px) and (max-width:768px){
                margin: 0 45px;
            }
            .header {
                max-width: 689px;
                .heading {
                    @extend .normal-font-style;
                    font-family: $primary_font_SemiBold;
                    // width: 600px;
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 54px;
                    color: $primary_solid_color;
                }
                .sub-heading {
                    @extend .normal-font-style;
                    font-family: $primary_font_Medium;
                    font-size: 92px;
                    font-weight: 400;
                    line-height: 110px;
                    color: $darkGrey;
                    @media (min-width:992px) and (max-width:1200px){
                        font-size: 64px;
                        line-height: 78px;
                    }
                }
            }
            hr {
                @extend .d-inline-block;
                width: 187px;
                height: 1px;
                opacity: 0.75;
                background-color: $primary_solid_color;
            }
            .description {
                
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                .title {
                    @extend .normal-font-style;
                    font-family: $primary_font_SemiBold;
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 38px;
                    color: $darkGrey;
                    padding-top: 15px;
                    padding-bottom: 15px;                   // padding-bottom: 1.125em;
                }
                .sub-title {
                    @extend .normal-font-style;
                    font-family: $primary_font_Regular;
                    font-size: 24px;
                    line-height: 38px;
                    color: #686c80;
                    padding-bottom: 3em;
                }
            }
            button {
                margin-bottom: 4em;
                width: 191px;
                height: 52px;
                border-radius: 50px;
                font-size: 18px;
            }
        }
    }
    @media (max-width: 413px) {
        .landing-page-container {
            margin: 20px 30px;
            .content {
                width: 250px;
                margin: 0 10px;
                hr {
                    width: 115px;
                }
                .header {
                    .sub-heading {
                        font-size: 64px;
                        line-height: 78px;
                    }
                    .heading {
                        font-size: 28px;
                    }
                }
                .description {
                    .title {
                        font-size: 28px;
                    }
                    .sub-title {
                        font-size: 20px;
                        padding-bottom: 3em;
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        .landing-page-container {
            margin: 20px 30px;
            .content {
                width: 340px;
                .header {
                    .sub-heading {
                        font-size: 64px;
                        line-height: 78px;
                    }
                    .heading {
                        font-size: 28px;
                    }
                }
                .description {
                    width: 90%;
                    .title {
                        font-size: 28px;
                     
                    }
                    .sub-title {
                        font-size: 20px;
                        padding-bottom: 3em;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        .landing-page-container .content {
            width: 520px;
            .header {
                .sub-heading {
                    font-size: 64px;
                }
                .heading {
                    font-size: 28px;
                }
            }
            .description {
                .title {
                    font-size: 28px;
                    line-height: 38px;
                }
                .sub-title {
                    font-size: 20px;
                    line-height: 38px;
                    padding-bottom: 2.5em;
                }
            }
        }
    }
    @media (max-height: 770px) {
        .landing-page-container {
            margin: 25px;
            .content {
                .start-button {
                    padding-bottom: 20px;
                    padding-top: 5px;
                    margin: 0px auto;
                
                }
                
            }
        }
    }
    
}
@media (max-width:360px) {
    .landing-page-container{
        .header {
            .sub-heading {
                font-size: 46px !important;
                line-height: 78px !important;
            }
            .heading {
                font-size: 1.35rem;
            }
        }
        .description{
            .sub-title{
                width: 80%;
            }
        }
    }
}