.profile-tabs {
    &.tab {
        border-bottom: 1px solid $paleGrey;
        .tab-button {
            color: $battleshipGrey;

            font-size: 1rem;
            line-height: 2rem;
            &:not(:last-of-type) {
              margin-right: 50px;
            }
            &.active {
                color: $darkGrey;
                font-size: 1.625rem;
                position: relative;
                line-height: 3rem;
                &::after {
                    position: absolute;
                    content: "";
                    width: 70px;
                    height: 3px;
                    bottom: -1px;
                    left: -10px;
                    background: $darkMango;
                }
            }
        }
    }
}

.MuiButtonGroup-root {
  border-radius: 0;
}