html > #zmmtg-root {
    display: none !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 10000px !important;
    background-color: black;
}
tcd-p {
    margin-bottom: 0;
    span {
        font-size: 0.875rem;
    }
}
.chargePolicy {
    font-size: 16px;
    padding-left: 1.5%;
    padding-bottom: 30px
}
.tcdCut {
    color: darkgray !important;
    font-size: 20px !important;
}
.resume-booking {
    max-width: 100vw;
    align-items: flex-start;
    margin: 0;
    height: 100%;

    & .section {
        @media (max-width: 767px) {
            margin-bottom: 50px;
        }
    }
    h2 {
        font-size: 36px;
    }
    .white-section {
        padding: 40px 45px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
            padding-left: 27px;
            color: $darkMango;
            margin-bottom: 30px;
            font-size: 38px;
            @media (max-width: 768px) {
                font-size: 32px;
                text-align: left;
            }
        }
        h3 {
            font-family: $primary_font_Regular;
            font-size: 30px;
            color: $battleshipGrey;

            span {
                display: block;
            }
        }

        .header {
            padding-left: 27px;
            padding-bottom: 40px;
            border-bottom: solid 1px $paleGreyThree;
            .avatar {
                margin-left: 3px;
                margin-right: 39px;
                img {
                    width: 102px;
                    height: 102px;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }
            .tutor-name {
                text-transform: capitalize;
            }
            .description {
                h3 {
                    span {
                        font-size: 30px;
                        @media (max-width: 768px) {
                            font-size: 28px;
                        }
                    }
                }
            }
        }
        .rating {
            @media (max-width: 991px) {
                flex-wrap: wrap;
            }
            svg:last-of-type {
                color: $paleGrey;
            }
            p {
                @extend .p-link;
                font-size: 14px;
            }
        }
        .when {
            width: 100%;
            margin: 45px 0;
            padding: 0 27px;
            align-items: center;
            @media (max-width: 768px) {
                padding: 0 105px;
            }
            @media (min-width: 577px) and (max-width: 767px) {
                padding: 0 30px;
            }
            @media (max-width: 576px) {
                padding: 0 10px;
            }
            .icon-box {
                display: flex;
                align-items: center;

                h4 {
                    margin: 0;
                }
            }
            .multiAvail {
                overflow-y: scroll;
                height: 140px;
            }

            .availability {
                width: 100%;
                margin: 10px 0 0 10px;
                padding: 0;
                position: relative;
                @media (max-width: 768px) {
                    margin-top: 0;
                }

                li {
                    list-style: none;
                    margin-bottom: 11px;
                    font-size: 14px;
                }
            }
            .booking-duration {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                @media (max-width: 991px) {
                    flex-wrap: wrap;
                }
                @media (max-width: 991px) and (min-width: 768px) {
                    span.button {
                        margin: auto;
                    }
                }
                @media (max-width: 768px) {
                    flex-wrap: nowrap;
                    img {
                        height: 35px;
                    }
                }
                .button .outline-button {
                    width: 140px;
                    height: 38px;
                    color: $vividBlue;
                    font-size: 0.875rem;
                }

                .duration-info {
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 14px;
                    }
                }
            }
            .booking-schedule {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                margin: 10px 0 10px 6px;
                @media (max-width: 768px) {
                    img {
                        height: 35px;
                        margin-top: -10px;
                    }
                }
                h6 {
                    font-size: 14px;
                }
            }
        }
        .subtotal {
            border-top: 1px solid $paleGreyThree;
            border-bottom: 1px solid $paleGreyThree;
            padding: 35px 0px;
            margin-left: 35px;
            margin-right: 35px;
            justify-content: space-between;
            @media (max-width: 768px) {
                margin-left: 110px;
                margin-right: 110px;
            }
            @media (max-width: 767px) {
                flex-direction: row;
                margin-left: 35px;
                margin-right: 30px;
            }
            @media (max-width: 576px) {
                flex-direction: row;
                margin-left: 15px;
                margin-right: 15px;
            }
            .width100 {
                width: 100%;
            }
            .promo-details {
                font-size: 14px;
            }
            p {
                font-family: "Montserrat-Medium" !important;
                margin-bottom: 0px;
                font-size: 20px;
            }
            span {
                font-size: 20px;
                color: $darkMango;
            }
        }
        .cancelation {
            padding: 55px 35px 0px 35px;
            color: $battleshipGrey;
            @media (max-width: 768px) {
                padding: 20px 105px 0;
                .image {
                    img {
                        height: 35px;
                        width: auto;
                    }
                }
            }
            @media (max-width: 767px) {
                padding: 20px 35px 0;
            }
            @media (max-width: 576px) {
                padding: 20px 15px 0;
            }
            .description {
                margin: 0px 15px;
                p {
                    span {
                        font-weight: 600;
                    }
                    & ~ p {
                        @extend .p-link;
                    }
                }
            }
        }
    }
    .gray-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        height: 100%;
        margin: 0;
        padding: 40px 45px 81px;
        width: 100%;
        color: white;
        background-color: $battleshipGrey;
        .header {
            h2 {
                font-size: 38px;
                @media (max-width: 768px) {
                    font-size: 32px;
                }
            }
        }
        .checkbox-wrapper {
            display: flex;
            justify-content: flex-start;
            span {
                align-items: flex-start;
            }
            .checkbox-label {
                padding-top: 12px;
                margin-bottom: 0;
            }
        }
        & > form {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        @media (max-width: 768px) {
            padding: 50px 125px 81px !important;
            .tcd-footer {
                .tcd-p {
                    padding-left: 0 !important;
                }
                .chargePolicy {
                    padding-left: 0 !important;
                    padding-bottom: 30px !important;
                }
                .PrivateSwitchBase-root-1 {
                    padding-left: 0;
                }
            }
        }
        @media (min-width: 577px) and (max-width: 767px) {
            padding: 50px 50px 50px 81px !important;
        }
        @media (max-width: 576px) {
            padding: 50px 35px 81px !important;
        }
        .multi-select-drop-down {
            margin-top: 70px;
            padding: 0;
            .custom-input {
                .input-label {
                    color: white;
                }
            }
        }

        .password-section {
            margin-top: 45px;
            .custom-input {
                padding: 0;
            }
            .input-label {
                color: white;
                font-size: 14px;
                font-weight: 300;
            }
            .input-label-p {
                color: white;
                font-size: 16px;
                font-weight: 300;
                margin-bottom: 5px;
            }
        }
        .tcd-footer {
            margin-top: 20%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
                margin-bottom: 0;
                span {
                    font-size: 0.875rem;
                    color: white;
                    padding-left: 0;
                    @media (max-width: 768px) {
                        padding-left: 0;
                    }
                }
                .checkbox-label {
                    margin-bottom: 5px;
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 20px;
                    color: white;

                    font-family: $primary_font_Regular;
                }
                .MuiCheckbox-colorSecondary.Mui-checked {
                    color: white;
                }
            }
            .button {
                align-self: center;
                margin-top: 62px;
                button {
                    font-size: 18px;
                    margin-top: 60px;
                }
            }
        }
        & > * {
            color: $white;
        }
    }
    & > * {
        color: $battleshipGrey;
        max-width: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
    }
    .card-select {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        img {
            width: 35px;
        }
        span {
            @media (max-width: 768px) {
                font-size: 18px;
            }
        }
    }
    .input-container {
        position: relative;
        .card-select {
            cursor: pointer;
            padding: 0 55px 0 25px;
            border: 0px solid #ffffff;
            height: 65px;
            width: 100%;
            border-radius: 7px;
            box-shadow: 0 10px 10px 0 #0000000d;
            background: white;
            color: $battleshipGrey;
        }
        .error-box {
            margin-left: 2px;
            position: absolute;
            right: 0;
            height: 100%;
        }
        input {
            border-radius: 7px !important;
        }
    }
    .multi-select-drop-down .dropdown-box-continer {
        top: 30px;
    }
}
.d-flex {
    display: flex;
}
.d-flex.end {
    justify-content: flex-end;
}

.availability ~ div {
    margin-top: 50px;

    .button .outline-button {
        width: 120px;
    }
}
.text-right {
    float: right;
}

.addnewcard {
    margin-top: 10px;
    display: block;
    float: right;
    clear: both;
    font-size: 14px;
}
.addnewCardIndropdown {
    background: white;
    width: 100%;
    height: 78px;
    /* border-top: 8px; */
    margin-top: 0px;
    padding-top: 5px;
    padding-left: 18px;
    text-size-adjust: 24px;
    font-size: 16px;
}

.multi-select-drop-down .dropdown-box-continer .dropdown-box.paymentdropdown {
    height: unset;
    max-height: 360px;
}

.privacy-modal {
    text-align: center;
    color: $battleshipGrey;

    h1 {
        font-size: 1.875rem;
        line-height: 2.875rem;
    }

    p {
        padding: 0px 35px;
        font-family: $primary_font_Regular;
        font-size: 1rem;
        font-weight: 400;
    }
}

.resume-page-input {
    label {
        font-family: $primary_font_Regular;
        font-size: 0.875rem;
    }
}

.resume-booking .gray-section .footer p span {
    color: white;
    text-decoration: none;
}
.privacy-policy-text {
    padding-top: 30px !important;
    padding-bottom: 72px !important;
}
.custom-modal-content-p {
    margin: auto;
    padding: 25px;
    width: 75vw;
    max-width: 900px;
    height: auto;
    background: white;
    border-radius: 25px 0px;
}
.nonCircumventionAgreement {
    .text {
        padding-bottom: 50px !important;
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
}
.session-edit-mob {
    .edit_button {
        width: 40px;
        height: 40px;
        display: inline-block;
        background-image: url(../../../../assets/images/icons/edit-bg.svg);
        background-position: center center;
        background-repeat: no-repeat;
        margin-left: 10px;
        cursor: pointer;
        background-size: 40px 40px;
    }
}
.custom-modal-content-payment {
}
