.dashboard-container {
    @extend .normal-font-style;
    padding: 50px;
    .total-count {
        display: flex;
        padding-top: 25px;
        justify-content: space-between;
        button {
            font-family: $primary_font_Bold;
            font-size: 20px;
        }
    }
    .back-arrow {
        height: 36px;
        width: 36px;
        border-style: solid;
        border-radius: 50%;
        border-color: $vividBlue;
        border-width: 2px;
        display: flex;
        display: inline-flex;
        align-items: center;
        padding: 6px;
        margin-right: 20px;
        margin-top: auto;
        margin-bottom: auto;
        float: left;
    }
    .title {
        font-size: $extra_large_font;
        font-family: $primary_font_SemiBold;
    }
    .tutors-advisors-container {
        .tabs-container {
            .sort-label-container {
                font-family: $primary_font_SemiBold;
                .sort-label {
                    font-size: $small_font;
                }
                .sort-active-label {
                    font-size: $small_font;
                    color: #272727;
                }
            }
        }
        .session-detail-card {
            height: 120px;
            margin-bottom: 20px;
            .card-view {
                button {
                    right: 30px;
                    left: auto;
                    background-color: $vividBlue;
                }
            }
        }
        .no-results-card-container {
            .no-results-card {
                padding-left: 0;
                padding-top: 30px;
                padding-bottom: 30px;
            }
            button {
                position: relative;
                right: 0;
                bottom: 25px;
            }
        }
    }
    .dashboard-left-panel {
        position: relative;
        max-height: 90vh;
    }
    @media (max-width: 768px) {
        .dashboard-left-panel {
            display: none;
        }
        .back-arrow {
            float: left;
        }
        .tutors-advisors-container {
            .cards-container {
                padding: 0px 15px;
            }
        }
        .student-dashboard-h {
            padding: 30px 0px;
        }
        #right-col {
            padding-left: 50px !important;
            padding-right: 50px !important;
        }
    }

    @media (max-width: 576px) {
        .tutors-advisors-container {
            .session-detail-card {
                height: 195px;
                margin-bottom: 10px;
            }
        }
        #right-col {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .dash-section-title {
        padding-left: 15px;
        .dashboard-container {
            @extend .normal-font-style;
            padding: 68px;
            .dash-section-title {
                padding-left: 15px;
                padding-top: 67px;
                font-family: $primary_font_Medium;
                font-size: 26px;
                line-height: 38px;
                color: $darkMango;
            }
            @media (max-width: 768px) {
                padding: 30px 35px !important;
            }
        }
        .panel-body {
            width: 90%;
        }
        #right-col {
            padding-right: 3em;
            padding-left: 3em;
            @media (max-width: 768px) {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
        .coming-soon {
            position: relative;
            border: 10px solid #fff;
            // text-align: center;
            height: 148px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // background:  url(../../../assets/images/backgrounds/bg-coming-soon.png) top center no-repeat;
            // background-size: cover;
            padding-left: 15px;
            color: #686c80;

            .no-results-card {
                padding: 0;
                z-index: 10;

                p {
                    margin: 0;
                }
            }
        }
        .lessons.coming-soon {
            border: 0;
        }
        padding-top: 67px;
        font-family: $primary_font_Medium;
        font-size: 26px;
        line-height: 38px;
        color: $darkMango;
    }
    .lg-screen {
        display: block;
        #right-col {
            padding-right: 0px;
            padding-left: 30px;
        }
    }
    .md-screen {
        display: none;
    }
    .round-div {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-family: $primary_font_Medium;
        font-size: $small_font;
        color: $white_solid;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 1024.95px) {
        .lg-screen {
            display: none;
        }
        .md-screen {
            display: block;
        }
    }
    .tutors-advisor-container {
        margin-top: 20px;
        margin-left: 20px;
        .card-container:not(:last-child) {
            padding-right: 15px;
        }
        .card-container:not(:first-child) {
            padding-left: 15px;
        }
    }

    @media (max-width: 1024.95px) {
        .tutors-advisor-container {
            margin-top: 20px;
            margin-left: 0px;
            .card-container:not(:last-child) {
                padding-right: 0px;
            }
            .card-container:not(:first-child) {
                padding-left: 0px;
            }
        }
    }
    @media (max-width: 768px) {
        .tutors-advisor-container {
            margin-top: 20px;
            .card-container:not(:last-child) {
                padding-right: 15px;
            }
            .card-container:not(:first-child) {
                padding-left: 15px;
            }
        }
    }
    @media (max-width: 414.95px) {
        .tutors-advisor-container {
            margin-top: 20px;
            margin-left: 0px;
            .card-container:not(:last-child) {
                padding-right: 0px;
            }
            .card-container:not(:first-child) {
                padding-left: 0px;
            }
        }
    }
    .upcoming-title {
        font-size: $extra_large_font;
        margin-left: 25px;
        font-family: $primary_font_Medium;
    }
    @media (max-width: 1024px) {
        .upcoming-title {
            margin-left: 0px;
        }
    }
}
.panel-body {
    width: 90%;
}

.coming-soon {
    position: relative;
    border: 10px solid #fff;
    height: 148px;
    padding-left: 15px;
    color: #686c80;

    .no-results-card {
        padding: 0;
        z-index: 10;

        p {
            margin: 0;
        }
    }
}
.lessons.coming-soon {
    border: 0;
}
.display-inline-flex {
    display: inline-flex;
}
.student-dashboard-container {
    .no-session-card-container {
        display: flex;
        flex-direction: column;
        .button {
            margin: auto;
            button {
                position: relative;
                right: -10px;
                z-index: 999;
                bottom: 30px;
            }
        }
    }
}
@media (max-width: 768px) {
    .student-dashboard-container {
        padding: 30px 10px !important;
    }
}
@media (max-width: 767px) {
    .tutors-advisor-container {
        .MuiGrid-grid-sm-6 {
            flex-grow: 0;
            max-width: 100% !important;
            flex-basis: 100% !important;
        }
    }
}
