html {
    scroll-behavior: smooth;
    min-width: 0px !important;
    min-height: 0px !important;
}
body {
    scroll-behavior: smooth;
    min-width: 0px !important;
    min-height: 0px !important;
}
.lesson-flexible {
    .display-flex {
        display: flex;
    }
    .text-center {
        text-align: center;
    }
    .float-left {
        float: left;
    }
    .float-right {
        float: right;
    }
    .lesson-title {
        color: $white_solid;
        font-size: 55px;
        line-height: 55px;
        font-family: $primary_font_Medium;
        text-align: center;
        .lesson-title-long-span {
            font-size: 2rem;
            line-height: 45px;
            @media (max-width: 1100px) {
                font-size: 1.6rem;
                line-height: 35px;
            }
            @media (max-width: 576px) {
                font-size: 1.5rem;
                padding-bottom: 2rem;
            }
        }
    }
    .lesson-header {
        background: linear-gradient(rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.77)),
            url(../../assets/images/lessonHeadBackground.png);
        height: 400px;
        padding-left: 2%;
        padding-right: 2%;
        background-size: cover;
        display: flex;
        max-width: 100% !important;
        position: relative;
    }
    .lesson-exit {
        top: 30px;
        left: 30px;
        padding: 20px 0;
        width: calc(100% - 60px);
        & + div {
            margin: auto;
            padding: 0 10%;
        }
    }
    .lesson-button {
        width: 207px;
        height: 62px;
        float: right;
        color: $white_solid;
        border-radius: 27px;
        span.MuiButton-label {
            height: 22px;
            margin: 1px 3px 1px 0;
            font-family: $primary_font_Medium;
            font-size: 18px;
            color: $white_solid;
            text-transform: none;
        }
    }
    .lesson-content {
        .main-content {
            padding-left: 30px;
            padding-right: 30px;
            margin: auto;
        }
        .description {
            margin-top: 68px;
            padding: 0px;
            max-width: 100% !important;
            img {
                max-width: 100%;
            }
        }
        p {
            font-family: $primary_font_Regular;
            font-size: 20px;
            line-height: 28px;
            color: $battleshipGrey;
        }
        p.heading {
            font-family: $primary_font_Medium;
            font-size: 40px;
            line-height: 50px;
            color: $darkGrey;
            margin-top: 60px;
            margin-bottom: -40px;
        }
        h1 {
            margin-top: 60px;
            font-family: $primary_font_Medium;
            font-size: 38px;
            line-height: 54px;
            color: $darkGrey;
        }
        ol {
            list-style: none;
            margin-left: -25px;
            counter-reset: my-awesome-counter;
            li {
                counter-increment: my-awesome-counter;
            }
            li::before {
                content: counter(my-awesome-counter) ". ";
                color: $darkMango;
                font-weight: bold;
            }
        }
        li {
            font-family: $primary_font_Regular;
            font-size: 20px;
            line-height: 28px;
            color: $battleshipGrey;
            a {
                line-height: 28px;
                font-size: 20px;
                color: $darkMango;
            }
        }
        a {
            line-height: 28px;
            font-size: 20px;
            color: $darkMango;
        }
        .module-references {
            margin-bottom: 67px;
            p {
                font-family: $primary_font_Medium;
                font-size: 30px;
                line-height: 54px;
                color: $darkGrey;
                margin-bottom: 0px;
                a {
                    font-family: $primary_font_Regular;
                    font-size: 24px;
                    line-height: 28px;
                    color: $darkMango;
                }
            }
        }
        .lesson-video {
            margin-top: 56px;
            padding: 0pt;
        }
        .video-player {
            justify-content: center;
            display: flex;
        }
        .lesson-portrait {
            margin-top: 60px;
            border-style: solid;
            border-radius: 25px 0pt;
            border-color: $primary_solid-color;
            padding: 30px;
            .portrait-heading {
                font-size: 38px;
                line-height: 54px;
                color: $darkGrey;
                font-family: $primary_font_Medium;
            }
        }
        .related-modules {
            padding-left: 0px;
            padding-right: 0px;
            margin-top: 42px;
            margin-bottom: 42px;
            .btn {
                width: 207px;
                height: 62px;
                float: right;
                color: $white_solid;
                border-radius: 27px;
                span.MuiButton-label {
                    height: 22px;
                    margin: 1px 3px 1px 0;
                    font-family: $primary_font_Medium;
                    font-size: 18px;
                    color: $white_solid;
                    text-transform: none;
                }
            }
            .btn-wrapper {
                margin-left: auto;
                order: 2;
            }
            .title {
                p {
                    color: $primary_solid-color;
                    font-size: 24px;
                    line-height: 32px;
                    font-family: $primary_font_Medium;
                }
            }
        }
        .lesson-video-break {
            margin-top: 60px;
        }
        .justify-center {
            margin: auto;
            display: flex;
            @media (max-width: 768px) {
                flex-direction: column;
                .button {
                    padding: 0 !important;
                }
            }
        }
    }
    .lesson-navigation {
        color: $vividBlue;
        font-size: 20px;
        font-family: $primary_font_Medium;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        .previous {
            margin-right: auto;
            .back-arrow {
                height: 36px;
                width: 36px;
                border-style: solid;
                border-radius: 50%;
                border-color: $vividBlue;
                border-width: 2px;
                display: flex;
                display: inline-flex;
                align-items: center;
                padding: 6px;
                margin-right: 20px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .next {
            margin-left: auto;
            .forward-arrow {
                height: 36px;
                width: 36px;
                border-style: solid;
                border-radius: 50%;
                border-color: $vividBlue;
                border-width: 2px;
                display: flex;
                display: inline-flex;
                align-items: center;
                padding: 6px;
                margin-left: 20px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        u {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    .one-on-one-session {
        background-color: $darkMango;
        display: flex;
        justify-content: center;
        padding-top: 66px;
        padding-bottom: 66px;
        font-size: 38px;
        color: $white_solid;
        font-family: $primary_font_Medium;
        .justify-center {
            justify-content: center;
            display: flex;
        }
        .btn {
            background-color: $white_solid;
            color: $vividBlue;
            width: 207px;
            height: 62px;
            font-size: 18px;
            border: solid 2px $vividBlue;
        }
    }
    @media (max-width: 1200px) {
        .lesson-title {
            line-height: 55px;
        }
    }
    @media (max-width: 1023.95px) {
        /* .lesson-exit {
            display: flex;
            justify-content: space-between;
        }
        .lesson-button {
            float: none;
        } */
        .lesson-title {
            text-align: center;
            line-height: 42px;
            font-size: 38px;
        }
        .lesson-content {
            .lesson-portrait {
                padding: 30px;
            }
            .lesson-video-break {
                margin-top: 3.85rem;
            }
            .lesson-portrait {
                margin-top: 30px;
            }
        }
    }
    @media (max-width: 768.95px) {
        .lesson-header {
            height: auto;
            margin-top: 0px !important;
        }
        .lesson-title {
            font-size: 30px;
            line-height: 28px;
        }
        .lesson-button {
            width: 200px;
            height: 48px;
            span.MuiButton-label {
                font-size: 16px;
                line-height: 22px;
                display: flex !important;
            }
        }
        .lesson-content {
            .description {
                margin-top: 36px;
            }
            p {
                font-size: 16px;
                line-height: 22px;
            }
            a {
                font-size: 16px;
                line-height: 22px;
            }
            p.heading {
                font-size: 26px;
                line-height: 38px;
                margin-top: 30px;
                margin-bottom: -20px;
                line-height: 30px;
            }
            h1 {
                margin-top: 30px;
                font-size: 20px;
                line-height: 30px;
            }
            li {
                font-size: 16px;
                line-height: 22px;
            }
            .module-references {
                margin-bottom: 30px;
                p {
                    font-size: 20px;
                    line-height: 30px;
                    a {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
            .lesson-video {
                margin-top: 28px;
            }
            .lesson-portrait {
                padding: 32px;
                .portrait-heading {
                    font-size: 20px;
                    line-height: 38px;
                }
            }
            .related-modules {
                .btn {
                    width: 140px;
                    height: 38px;
                    margin: 1px 3px 1px 0;
                    font-size: 14px;
                }
                .title {
                    p {
                        font-size: 20px;
                        line-height: 38px;
                        font-family: $primary_font_Regular;
                    }
                }
            }
        }
        .lesson-navigation {
            font-size: 15px;
            font-family: $primary_font_Regular;
            .previous {
                .back-arrow {
                    margin-right: 10px;
                }
            }
            .next {
                .forward-arrow {
                    margin-left: 10px;
                }
            }
        }
        .one-on-one-session {
            background-color: $primary_solid-color;
            padding-top: 31px;
            padding-bottom: 31px;
            font-size: 26px;
            font-weight: $primary_font_Regular;
            .btn {
                width: 140px;
                height: 38px;
                font-size: 14px;
            }
        }
    }
    @media (max-width: 413.95px) {
        .lesson-content {
            justify-content: center;
            flex-direction: column-reverse;
            .main-content {
                padding-left: 15px;
                padding-right: 15px;
            }
            .lesson-navigation {
                margin-left: 15px;
                margin-right: 15px;
            }
            .related-modules {
                .title {
                    display: flex;
                    justify-content: center;
                    p {
                        line-height: 26px;
                        text-align: center;
                    }
                }
                .btn-wrapper {
                    margin: auto;
                }
            }
        }
        .lesson-navigation {
            .lesson {
                display: none;
            }
        }
        .one-on-one-session {
            font-size: 20px;
        }
    }
}
.lesson-copyright {
    color: #fff;
    font-size: 0.75rem;
    text-transform: capitalize;
}
.lesson-btn-show-mob {
    display: none !important;
}
.lesson-copyright-mob-show {
    display: none !important;
}
@media (max-width: 767.95px) {
    .lesson-logo {
        text-align: center;
        width: 100% !important;
        img {
            height: 50px !important;
        }
        .lesson-btn-hide-mob {
            display: none;
        }
    }
    .panel .panel-body .MuiContainer-root {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
    }
    .lesson-copyright-mob-hide {
        display: none;
    }
    .lesson-btn-show-mob {
        display: block !important;
        margin: auto;
    }
    .lesson-copyright-mob-show {
        display: block !important;
        text-align: center;
    }
    .onbaording-subscription-content{
        padding: 50px 0 30px;
    }
}