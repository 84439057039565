.dev-banner {
    position: fixed;
    top: 5px;
    right: 10px;
    opacity: 0.4;
    color: grey;
    pointer-events: none;
    font-size: x-large;
    font-family: "Montserrat-Bold";
    // transform: rotateZ(45deg);
    z-index: 10000;
}
