.message-page {
    @media (max-width: 768px) {
        .panel-body{
            
        }
        .dont-show-connected-people {
            display: none !important;
        }
        .dont-show-chat {
            display: none !important;
        }
        .show-connected-people {
            height: 90vh !important;
        }
        .show-chat {
            height: 85vh !important;
        }
        .back_icon {
            position: absolute;
            top: 12px;
            left: 9px;
            z-index: 9999999;
            display: block !important;
            svg {
                background-color: $white_solid;
                width: 53px;
                height: 53px;
                border-radius: 50%;
                padding: 10px;
                cursor: pointer;
            }
        }
        .chat-container {
            margin-top: 20px;
        }
        .bottom-tool-bar {
            position: fixed !important;
            bottom: 20px !important;
        }
    }
    .back_icon {
        display: none;
    }
    .message-title {
        font-family: Montserrat-Medium;
        font-size: 38px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: $darkMango;
    }

    .message-card {
        box-shadow: 0 10px 10px 0 #0000000d;
        margin: 17px 0px;
        display: flex;
        align-items: center;
        height: 89px;
        border-radius: 7px;
        .message-card-avatar {
            margin: auto 12px auto 22px;
            width: 52px;
            height: 52px;
            display: flex;
            overflow: hidden;
            position: relative;
            font-size: 1.25rem;
            background-color: gray;
            color: white;
            align-items: center;
            flex-shrink: 0;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            line-height: 1;
            user-select: none;
            border-radius: 50%;
            justify-content: center;
        }
        .message-contain {
            width: 80px;
            .user-name {
                font-family: Montserrat-Medium;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                color: #686c80;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                text-transform: capitalize;
            }
            .last-message {
                font-family: Montserrat-Regular;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: #686c80;
                white-space: nowrap;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
        }
        .message-time {
            font-family: Montserrat-Regular;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: center;
            color: #686c80;
            min-width: 76px;
            min-height: 20px;
        }
        .rectangle {
            width: 9px;
            height: inherit;
            display: block;
            position: absolute;
            background-color: #e4e8f1;
            border-radius: 7px 0px 0px 7px;
        }
        .active_chat {
            @extend .rectangle;
            width: 45px;
        }
        .unread-chat {
            @extend .active_chat;
            background-color: $darkMango;
        }
    }

    .chat-container {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #f6f6f6;
    }
    .tool-bar {
        height: 100%;
        margin: 0px;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 90px;
        background-color: #ffffff;
        .user-avatar {
            margin: auto 12px;
            width: 40px;
            height: 40px;
            display: flex;
            overflow: hidden;
            position: relative;
            font-size: 1.25rem;
            background-color: gray;
            color: white;
            align-items: center;
            flex-shrink: 0;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            line-height: 1;
            user-select: none;
            border-radius: 50%;
            justify-content: center;
        }
        .user-contain {
            width: 210px;
            .user-name {
                text-transform: capitalize;
                font-family: Montserrat-Medium;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                color: #686c80;
            }
            .last-message {
                font-family: Montserrat-Regular;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: #686c80;
            }
        }
    }

    .message-button {
        margin-top: 5px;
        font-size: 22px;
        background-color: white !important;
        color: $darkMango !important;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05) !important;
        &:hover {
            color: white !important;
            background-color: $darkMango !important;
        }
    }

    .bottom-tool-bar {
        // @extend .tool-bar;
        display: block;
        position: absolute;
        width: 100%;
        height: 90px;
        left: 3px;
        background-color: #ffffff;
        bottom: -50px;
    }

    .camera-icon-block {
        margin-right: 10px;
        height: 45px;
        width: 45px;
        background-color: $white_solid;
        border-radius: 50%;
        color: $darkMango;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        label {
            margin-bottom: 0px;
            cursor: pointer;
            svg {
                height: 25px;
                width: 25px;
                fill: $darkMango;
            }
        }
    }

    .chat-input-area {
        clear: both;
        margin-top: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        text-align: center;
        justify-content: center;
        margin: 14px 0px;
        position: absolute;
        bottom: 0;
        width: 100%;
        .chat-input {
            display: flex;
            width: 75%;
            background-color: $white_solid;
            border-radius: 30px;
            input {
                height: 41px;
                width: 90%;
                border-radius: 20.5px;
                padding-top: 9px;
                border: none;
                padding-bottom: 9px;
                padding-left: 20px;
                color: $battleshipGrey;
                box-shadow: none;
                -webkit-user-select: initial !important;
            }
        }
    }

    .no-message-container {
        text-align: center;
        font-family: Montserrat-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #686c80;
    }

    .message-card-avatar {
        margin: auto 12px auto 22px;
        width: 52px;
        height: 52px;
        display: flex;
        overflow: hidden;
        position: relative;
        font-size: 1.25rem;
        background-color: gray;
        color: white;
        align-items: center;
        flex-shrink: 0;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1;
        user-select: none;
        border-radius: 50%;
        justify-content: center;
    }
}
@media (min-width:600px) and (max-width:769px) {
    .messgae-inner-card-tab{
        padding: 0 80px;
    }
}
@media (max-width:769px) {
    .message-page{
        .chat-container{
            .tool-bar{
                position: fixed;
            top: 70px;
            z-index: 999999;
            }
            .messages-container-body{
                height: 100% !important;
               
            }
            .messages-container-body-inner{
                // position:relative;
                top: 80px;
                height: calc(100% - 70px) !important;
            }
        }
        .bottom-tool-bar{
            bottom: 0px !important;
        }
    }
    
}