.tutor-advisor-application {
    @extend .position-relative;
    min-height: 40vh;
    .card-container {
        width: 360px;
        height: 325px;
        border-radius: 0 0 25px 0;
        box-shadow: 0 10px 10px 0 $light_black;
        background-color: $white_solid;
        align-items: center;
        display: grid;
    }
    .form-container {
        min-height: 250px;
    }
    .intent-card .content .text-container {
        padding: 30px 20px;
    }
    img{
        height: 87px;
        width: 87px;
    }
    @media (max-width: 413px) {
        .onboarding-header {
            .heading {
                font-size: 16px;
            }
            .sub-heading {
                font-size: 2.5rem;
            }
        }
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 40px auto;
                height: 181px;
                padding: 0;
                .content {
                    width: 300px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                        .heading {
                            font-size: 1.375rem;
                        }
                        .sub-heading {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
        .form-container {
            min-height: auto;
        }
    }
    @media (min-width: 414px) and (max-width: 768px) {
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 40px auto;
                height: 181px;
                .content {
                    width: 354px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
        .form-container {
            min-height: auto;
        }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 40px auto;
                height: 181px;
                .content {
                    width: 354px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
        .button-container {
            position: inherit;
            flex-direction: column !important;
            text-align: center;
        }
        .button-container > :first-child {
            margin-bottom: 20px;
        }
        .form-container {
            min-height: auto;
        }
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 40px auto;
                height: 181px;
                .content {
                    width: 354px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
        .button-container {
            position: inherit;
            flex-direction: column !important;
            text-align: center;
    
        }
        .button-container > :first-child {
            margin-bottom: 20px;
        }
        .form-container {
            min-height: auto;
        }
    }
    @media (min-width: 120px) {
    }

    .multi-select-drop-down .dropdown-box-continer .dropdown-box {
        margin-bottom: 60px;
    }
    .onboarding-actions {
        margin-top: 40px;
        padding-bottom: 45px;
    }
}
