$darkMango: #f28334;

.meeting-info-container.meeting-info-container--right-side {
    display: none !important;
}

#zmmtg-root {

   .video-avatar__avatar-title {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white !important;
        background-image: url("../../assets/images/iconsBrandBra02.png") !important;
        background-repeat: no-repeat !important;
        background-size: 145px 145px !important;
        background-position-x: center !important;
        background-position-y: 35% !important;

        .video-avatar__avatar-name {
            color: #000!important;
            font-size: 50px !important;
            font-weight: bold;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            word-break: break-all;
            max-width: 95%;
        }
    }

    .sharee-container__viewport.react-draggable {
        margin-top: 0 !important;
        transform: translate(0px, 0px) !important;
        height: 100% !important;
        width: 100% !important;
    }

    .sharing-layout {
        display: flex !important;
        align-items: center !important;
        justify-content: center;
        margin: auto;
        align-content: center;
        height: 100%;

        .sharee-container {
            position: relative;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin: auto;
            width: 100%;

            .sharee-container__indicator {
                display: none !important;
            }
        }
    }

    .wc-tooltip {
        display: none !important;
    }

    .sharing-audio-feature-tips {
        display: none !important;
    }

    [aria-label="Raise Hand"] {
        display: none !important;
    }

    [aria-label="Settings"] {
        display: none !important;
    }

    .meeting-info-container--left-side {
        display: none !important;
    }

    .single-main-container__main-view {
        background-color: white !important;
        align-items: flex-start !important;
    }

    .audio-option-menu.dropup.btn-group {
        display: none !important;
    }

    .video-option-menu.dropup.btn-group {
        display: none !important;
    }

    .video-share-layout {
        background-color: white !important;
        bottom: 52px;
        height: 100%;
    }

    button.footer-button-base__button.ax-outline.footer-button__button {
        display: none;
    }

    .sharing-setting-dropdown-menu-container {
        display: none;
    }
    [aria-label="open the chat pane"] {
        display: none;
    }
    div.more-button {
        display: none;
    }

    div.security-option-menu {
        display: none;
    }
    [aria-label="Record"] {
        display: none;
    }
    [aria-label="open the manage participants list pane,1 particpants"] {
        display: none;
    }
    [aria-label="open the participants list pane,[2] particpants"] {
        display: none;
    }
    div.meeting-app {
        width: 80% !important;
    }
    div.meeting-client-inner {
        position: relative;
    }
    div.meeting-client {
        width: calc(100% - 345px);
        right: 0 !important;
        left: auto !important;
        top: 100px;
    }
    div.join-dialog {
        width: 100% !important;
    }
    .sharee-container__viewport {
        margin-top: 0 !important;
        transform: translate(0px, 0px) !important;
        height: max-content !important;
        width: max-content !important;
    }
}

.root-inner, .meeting-app, .meeting-client, .meeting-client-inner, .webinar-client-inner {
    height: calc(100% - 50px) !important;
}

.meeting-client {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
}
#content {
    position: relative;
}
#content img {
    position: fixed;
    top: 30px;
    right: 40px;
    z-index: 10;
}

.ToggleButton {
    z-index: 2;
    position: fixed;
    left: 14%;
    top: 33%;
    border-radius: 50px;
    background-color: white;
}
.Oval-Copy-4 {
    width: 54px;
    height: 54px;
    margin: 0 11px 1px 175px;
    padding: 16px 15px 14px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
}
.ShapeVideo {
    width: 38px;
    height: 38px;
    margin-left: 10px;
    margin-top: 10px;
}
.meetingButton {
    width: 177px !important;
    height: 55px !important;
    margin: 0 1px 24px 32px;
    padding: 12px 0px;
    font-size: 14px !important;
    background-color: #dddee8 !important;
}
.ipadNotSupported {
    position: fixed;
    bottom: 0px;
    right: 37%;
    width: 100px;
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    background: transparent;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
    color: #CCC;
    font-family: sans-serif;
}
.whiteBoardButton {
    width: 177px !important;
    height: 55px !important;
    margin: 0 1px 24px 32px;
    padding: 12px 0;
    font-size: 14px !important;
    background-color: #dddee8;
}
.position {
    position: fixed;
    left: calc(100% - 27% - 345px);
    top: 2.5%;
    z-index: 2;
    .button {
        padding: 0px;
    }
}

.display-none {
    display: none;
}

.top-bar {
    display: none;
    .back-arrow {
        height: 30px;
        width: 30px;
        border-style: solid;
        border-radius: 50%;
        background-color: white;
        border-color: white;
        border-width: 2px;
        display: flex;
        display: inline-flex;
        align-items: center;
        padding: 6px;
        margin-right: 0px;
        margin-top: 5px;
    }
}
.float-right {
    float: right;
}
@media (max-height: 901px) {
    .ToggleButton {
        z-index: 2;
        position: fixed;
        left: 16%;
        top: 28%;
        border-radius: 50px;
        background-color: white;
    }
    .ShapeVideo {
        width: 38px;
        height: 38px;
        margin-left: 10px;
        margin-top: 10px;
    }
}
@media (max-width: 1024px) {
    .position {
        left: calc(100% - 17% - 345px);
    }
    #content img {
        top: 20px;
        right: 20px;
        width: 125px;
    }
    .meetingButton {
        width: 137px !important;
    }
    .whiteBoardButton {
        width: 137px !important;
    }
}
@media (max-width: 768px) {
    .position {
        display: none;
    }
    .zm-modal {
        min-width: 350px !important;
    }
    #zmmtg-root {
        div.meeting-client {
            width: 100% !important;
            right: 0 !important;
            left: auto !important;
            margin-top: 40px;
            height: calc(100% - 40px);
        }
        div.more-button {
            display: block;
        }
        .open {
            .dropdown-menu {
                display: block;
            }
        }
    }
    #content img {
        top: 60px;
        right: 30px;
        width: 125px;
    }
    .top-bar {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        height: 40px;
        background-color: $darkMango;
    }
}
@media (max-width: 414px) {
    #content img {
        top: 50px;
        right: 20px;
        width: 100px;
    }
}
@media (max-height: 768px) {
    .ToggleButton {
        z-index: 2;
        position: fixed;
        left: 15%;
        top: 12%;
        border-radius: 50px;
        background-color: white;
    }
}
.popUp {
    position: fixed;
    top: 80%;
    right: 1%;
    z-index: 10;
    height: 8%;
}
.meetingButtonshape {
    border-radius: 0px !important;
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
}
.whiteBoardshape {
    border-radius: 0px !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
}
