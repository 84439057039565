.rate-picker {
    min-width: 60px;
    display: inline-block;
    cursor: pointer;
    .display-value {
        width: 60px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        color: $primary_solid_color;
        border-bottom: dashed 1px $primary_solid_color;
        margin: -8px 0px;
    }
    .empty-value-error {
        border-bottom: dashed 1px $red;
    }
    .list-container {
        width: 100%;
        position: relative;
        overflow: hidden;

        .list-view {
            min-width: 60px;
            position: absolute;
            height: 242px;
            text-align: center;
            overflow: auto;
            box-shadow: 8px 10px 10px 10px $light_black;
            max-height: 360px;
            background-color: $white_solid;
            margin-top: -6rem;
            z-index: 20;
            .wrapper {
                min-width: 60px;
                position: absolute;
                height: 242px;
                text-align: center;
                overflow: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                .list-value {
                    color: $battleshipGrey;
                    font-size: 1.375rem;
                    font-family: $primary_font_Regular;
                    cursor: pointer;
                    &:hover {
                        font-size: 1.625rem;
                        color: $primary_solid_color;
                        padding: 5px 0px;
                        margin: 5px 0px;
                        border-top: solid 1px $paleGrey;
                        border-bottom: solid 1px $paleGrey;
                    }
                }
            }
            .wrapper::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
