.no-session-card-container {
    position: relative;
    margin-bottom: 40px;
    button {
        position: absolute;
        bottom: 0px;
        right: 32%;
    }
    .card-view {
        padding: 20px;
    }
    .title {
        margin-bottom: 20px;
        text-align: center;
        font-size: $medium_font;
    }
    .subtitle {
        font-family: $primary_font_Regular;
        font-size: $small_font  ;
        line-height: 20px;
        color: $battleshipGrey;
        text-align: center;
        margin-bottom: 40px;
    }
}
