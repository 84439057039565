$darkMango: #f28334;
.next-session {
    .next-session-title {
        font-family: "Montserrat-Regular";
        font-size: 14px;
        color: #686c80;
    }
    .next-session-value {
        font-family: "Montserrat-Medium";
        font-size: 18px;
        color: $darkMango;
    }
}