.plus_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-weight: 500;
    z-index: 30;

    img {
        transition: all 0.3s ease;
        width: 40px;
        margin: 10px;
    }
}

.delete_button {
    margin-top: 10px;
    margin-left: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    span {
        width: 50px;
        height: 50px;
        background: url(../../../assets/images/modal-close.svg) center center no-repeat;
    }
}
.input-container {
    .fa-calendar {
        color: $darkMango;
        z-index: 30;
    }
}
.positioned-dropdown{
    .datepicker-wrapper.p-30 {
        padding-left:15px;
        padding-right: 15px;
        @media (max-width:768px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    padding-left: 0px;
    padding-right: 0px;
    @media (max-width:768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.wrap {
    width: 100%;
    padding: 1rem;

    .form-file-picker {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed $paleGrey;
        border-radius: 7px;
        width: 100%;
        height: 60px;
        box-shadow: none;
        background-color: white;

        span {
            position: relative;
            color: $battleshipGrey;
            padding-left: 30px;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 23px;
                height: 24px;
                background: url(../../../assets/images/link.png) center center no-repeat;
            }
        }

        input#upload_button {
            display: none;
        }
    }
    .form-file-picker.error-input-file {
        border: 1px dashed #f00;
    }
    .info-file {
        font-size: 1rem;
        font-family: "Montserrat-Regular";
        span {
            color: $mine_shaft;
            font-size: 0.73rem;
            margin-right: 10px;
        }
        
    }
}
.divider {
    width: 98%;
    height: 1px;
    display: block;
    margin: 2% 1% 2% 1%;
}
input::-webkit-calendar-picker-indicator {
    display: none;
}
.react-datepicker-wrapper {
    width: 100%;
}
.z-10 {
    z-index: 10;
}
.z-15 {
    z-index: 15;
}

.year-handle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    .drop-w-div {
        width: 100%;
        margin: 0px;
        padding:15px 0px;
        .MuiInput-formControl{
        margin-top: 7px;
        }
        .simple-dropdown{
            padding:0 15px !important;
        }
    }
    .drop-w-button {
        cursor: pointer;
        width: 30px;
        margin: 20px 0 0 0;
        padding: 0;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        color: $darkMango;
    }
    
}
.m-space {
    margin: 3% 1% 1% 1%;
}