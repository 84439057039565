$vividBlue: #262ffa;
.modal-reschedule-request-receive{

    img{
        width: 130px;
        height: 130px;
        margin: 20px auto !important;
        text-align: center;
        border-radius: 50%;
        display: block;
    }
    h3{
        font-size: 30px;
        color: #686c80;
        text-align: center;
        line-height: 46px;
    }
    h4{
        font-size: 18px;
        color: #686c80;
        text-align: center;
        line-height: 28px;
    }
  
    .grey_div{
        max-width: 562px;
        margin: 30px auto 0;
        padding: 20px 25px;
        border-radius: 7px;
        background-color: #f6f6f6;
        text-align: center;
        h4{
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            color: #686c80;
            line-height: 29px;
        }
    }
   .reschedule-action-btn{
       margin: 30px 40px 0;
       display: flex;
       justify-content: space-between;
       .reject-reschedule-btn{
           color: #ef5350;
           border: 2px solid #ef5350;
       }
       .accept-reschedule-btn{
        color: #fff;
        background-color: $vividBlue;
        border: 2px solid $vividBlue;;
    }
   }
    @media (max-width:576px) {
        h3{
            font-size: 26px;
        }
        h4{
            font-size: 16px !important;
        }
        .reschedule-action-btn{
            flex-direction: column;
            align-items: center;
        }
    }
   
}
.modal-student-accept-reschedule{

    img{
        width: 130px;
        height: 130px;
        margin: 20px auto !important;
        text-align: center;
        border-radius: 50%;
        display: block;
    }
    h3{
        font-size: 28px;
        color: #686c80;
        text-align: center;
    }
    h4{
        font-size: 18px;
        color: #686c80;
        text-align: center;
    }

    .grey_div{
        max-width: 562px;
        margin: 30px auto 0;
        padding: 20px 25px;
        border-radius: 7px;
        background-color: #f6f6f6;
        text-align: center;
        h4{
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            color: #686c80;
        }
    }

       .accept-reschedule-btn{
        color: #fff;
        background-color: $vividBlue;
        border: 2px solid $vividBlue;

   }
    @media (max-width:576px) {
        h4{
            font-size: 18px !important;
        }
    }

} 
.modal-student-reject-reschedule{
    max-width: 900px;
    .imgs_div{
        text-align: center;
        max-width: 450px;
        margin: auto;
        padding-bottom: 15px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 1px;
                border-bottom: 3px dotted $vividBlue;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: #686c80;
        font-size: 30px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }
    .grey_div{
        max-width: 562px;
        margin: 30px auto 0;
        padding: 20px 25px;
        border-radius: 7px;
        background-color: #f6f6f6;
        text-align: center;
        h4{
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            color: #686c80;
        }
    }
    .reject-reschedule-req-btn{
        color: #fff;
        background-color: $vividBlue;
        border: 2px solid $vividBlue;
    }
    @media (max-width:768px) {
        h2{
            font-size: 26px;
        }
    }
}