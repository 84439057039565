$lightHeading:rgb(45,50,52);
.i-am {
    min-height: 100vh;
    width: 100%;
    .card-container {
        width: 360px;
        height: 325px;
        border-radius: 0 0 25px 0;
        box-shadow: 0 10px 10px 0 $light_black;
        background-color: $white_solid;
        align-items: center;
        display: grid;
    }
    .heading{
        color: $lightHeading;
    }
    
    @media (max-width: 413px) {
        .onboarding-header .sub-heading {
            font-size: 3rem;
        }
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 40px auto;
                height: 181px;
                padding: 0;
                .content {
                    width: 300px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                        .heading {
                            font-size: 1.375rem;
                            
                        }
                        .sub-heading {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
    }
    @media (max-width: 768px) {
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 45px auto;
                .content {
                    width: 354px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
    }
    @media (min-width: 992px) {
        .multiple-cards{
            margin-top: 0;
            padding: 4rem 0;
        }
    }
}
