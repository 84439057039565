@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/style.scss";
@import "./assets/styles/component-import.scss";
@import "./assets/styles/page-import.scss";

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

body {
    background-color: white !important;
}

body > #zmmtg-root{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left:10000px !important;
    background-color: black;
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.backDrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1100;
    color: white;
    background-color: #f28334;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 190px;
        height: 190px;
        background: rgba(255, 222, 165, 0.4);
        box-shadow: 0px 0px 20px 2px rgb(255, 222, 165);
        border-radius: 100%;
        animation: pulse 1.3s infinite;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
        box-shadow: 0px 0px 20px 2px rgb(255, 222, 165);
    }
    50% {
        transform: scale(1);
        box-shadow: 0px 0px 90px 2px rgb(255, 222, 165);
    }
    100% {
        transform: scale(0.8);
        box-shadow: 0px 0px 20px 2px rgb(255, 222, 165);
    }
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.card-box {
    border: 1px solid $battleshipGrey;
    font-family: $primary_font_Regular;
    padding: 10px 5px;
    margin: 20px;
    border-radius: 5px;
}

.card-error {
    margin: 0px 20px;
    color: $monza;
}
.button{
    padding: 5px;
}
.extenion-modal-body{
    height: auto !important;
}
@media (max-width:767px) {
.Cancel-modal-btn{
    flex-direction: column !important;
}
}

.label-tip{
    font-size: 12px !important;
    color: $vividBlue !important;
    margin-bottom: 0 !important;
}