// $mango: #f9a722;
$darkMango: #f28334;
$battleshipGrey: #686c80;
.modal-container {
    height: 34vh;
    max-height: 700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .header {
        color: $battleshipGrey;
        margin-top: 20px;
        font-size: 30px;
        font-weight: 400;
    }
    .sub-header {
        color: $darkMango;
        font-size: 18px;
        font-weight: normal;
    }
}
