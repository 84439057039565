.study-room-card {
    margin: 10px;
    float: right;
    white-space: break-spaces;
    .study-room-card-content {
	height: 134px;
	width: 163px;
	background-position: center;
	background-size: cover;
	border: 10px solid $white_solid;
	border-radius: 25px 0;
	.study-room-card-title {
	    color: $white_solid;
	    font-family: $primary_font_Medium;
	    font-size: 20px;
	    line-height: 28px;
	    padding-top: 43px;
	    text-align: center;
	}
    }
}
