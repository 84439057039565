.wallet {
    min-height: 50px;
    background: #f28334;
    border-radius: 7px;
    display: flex;
    color: white;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .balance {
        padding: 10px;

        @media (max-width: 768px) {
            padding: 9px 20px;
            align-self: center;
        }

        div {
            border-right: 1px solid white;

            @media (max-width: 768px) {
                align-items: center;
                border-right: 0px;
                border-bottom: 1px solid white;
                min-width: 200px;
            }

            p {
                margin-bottom: 0rem;
                
                @media (max-width: 768px) {
                    text-align: center;
                }
            }

            h2 {
                font-size: 20px !important;

                @media (max-width: 768px) {
                    text-align: center;
                }
            }
        }
    }

    .description {
        padding: 10px;
        color: white;
        display: flex;

        p {
            font-size: 12px;
        }

        .circle {
            margin-right: 10px;
            color: #fff;
            font-size: 20px;
            line-height: 28px;
            width: 45px;
            height: 21px;
            min-width: 16px;
            max-width: 16px;
          }
    }
}
