.dashboard-nav {
    font-family: $primary_font_Medium;
    line-height: 26px;
    margin-bottom: 0px;
    vertical-align: text-bottom;
    text-align: center;
    white-space: nowrap;
    a{
        text-decoration: none !important;
    }
    a:hover {
        text-decoration: none;
    }
    .dashboard-tab {
        font-size: 16px;
        color: $black_solid;
        @media (max-width:575px) {
            font-size: 12px;
        }
    }
    .dashboard-tab-selected {
        font-size: 26px;
        
        @media (min-width:576px) and (max-width: 1380px) {
            font-size: 20px;
        }
        @media (max-width:575px) {
            font-size: 14px;
        }
        color: $black_solid;
        position: relative;

        &:after {
            content: "";
            background-color: $darkMango;
            width: 80px;
            height: 3px;
            position: absolute;
            bottom: -19px;
            display: block;
        }
    }
}
.dashnav-divider {
    width: 100%;
    height: 3px;
    position: relative;
    .divider-left {
        background-color: $darkMango;
        width: 80px;
        height: 3px;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .divider-right {
        width: 100%;
        height: 1px;
        background-color: $paleGrey;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}

.tutor-view {
    .dashboard-tab-selected {
        &:after {
            left: 35px;
        }
    }
}
.advisor-view {
    .dashboard-tab-selected {
        &:after {
            left: 20px;
        }
    }
}
