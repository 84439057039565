// .sidebar{
//     display: flex;
// }
.lessons-overview-page {
    .subscription-option-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .button {
            button {
                width: 297px;
                margin-top: 0px;
            }
        }
    }
    .left-panel {
        padding-left: 60px;
        padding-right: 20px;
        margin-top: 78px;
        .lesson-list-item {
            font-family: $primary_font_Medium;
            /* min-height: 70px; */
            padding-bottom: 10px;
            /* margin-top: 10px; */
            .card-view {
                padding-left: 28px;
                width: 95%;
                padding-top: 16px;
                border-radius: 5px;
                box-shadow: 0px 0px 15px #eaeaea;
                @media (max-width:768px) {
                    width: 100%;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                & > p{
                    @media(min-width: 768px){
                        width: calc(100% - 115px);
                    }
                }
                .lesson-id {
                    font-weight: 600;
                }
                .lesson-name {
                    padding-left: 10px;
                }
                .view-more {
                    text-decoration: underline;
                    color: $vividBlue;
                    float: right;
                    padding-right: 30px;

                    .lesson-description {
                        position: relative;
                        margin-top: 30px;
                        p {
                            text-align: left;
                            font-size: 18px;
                            color: #455052;
                            line-height: 28px;
                        }
                    }
                    .modal-sub-wrapper {
                        width: 85% ;
                        height: 85%;
                        max-width: 70em;
                        p {
                            padding-left: 53px;
                            padding-right: 53px;
                        }
                        ul {
                            padding-left: 73px;
                        }
                        .modal-wrapper-exit {
                            z-index: 1;
                        }
                        position: relative;
                    }
                    .lesson-preview-label {
                        font-family: $primary_font_Medium;
                        font-size: 26px;
                        color: $darkMango;
                        line-height: 38px;
                        font-weight: 600;
                    }
                    .ln-1 {
                        margin-bottom: 0px;
                    }
                    .lesson-preview-title {
                        font-family: $primary_font_Regular;
                        margin-top: 0px;
                        margin-bottom: 0px;
                        text-align: left;
                        font-size: 38px;
                        line-height: 54px;
                        color: $darkGrey;
                        font-weight: 600;
                    }
                    .lesson-description-container {
                        margin-top: 61px;
                        @media (max-width: 1440px) {
                            margin-top: 61px;
                        }
                        @media (max-width: 1024px) {
                            margin-top: 49px;
                        }
                        @media (max-width: 768px) {
                            margin-top: 28px;
                        }
                        @media (max-width: 414px) {
                            margin-top: 27px;
                        }
                    }
                    .lesson-preview-actions {
                        margin-top: 40px;
                        .button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            button {
                                max-width: 297px;
                                height: 48px;
                                min-width: 200px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .card-view > * {
                display: inline-block;
            }
        }
        .back-button {
            padding: 6px;
            margin-bottom: 28px;
        }
        .overview-head {
            font-family: $primary_font_Medium;
            font-size: 60px;
            line-height: 78px;
            margin-bottom: 0px;
        }
        .overview-subhead {
            font-family: $primary_font_Medium;
            font-size: 26px;
            line-height: 38px;
            margin-bottom: 0px;
            margin-top: 10px;
        }
        .justify-center {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
        }
        .subscription-option-btn {
            width: fit-content;
            padding: 13px 25px;
            margin-top: 32px;
            text-align: center;
        }
        .subscription-option-btn-small {
            display: none;
        }
        .lessons-list {
            margin-top: 26px;
            font-size: 16px;
            margin-bottom: 40px;
            .view-lesson {
                cursor: pointer;
            }
        }
    }
    .right-panel {
        background-color: $battleshipGrey;
        font-family: $primary_font_Regular;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        p:first-child {
            margin-top: 121px;
        }
        p {
            text-align: left;
            margin-right: 61px;
            margin-left: 61px;
            margin-bottom: 55px;
            font-size: 18px;
            line-height: 28px;
        }
        .button {
            button {
                margin-top: 90px;
                width: 310px;
            }
        }
    }
    @media (max-width: 1024px) {
        .subscription-option-btn{
            display: none;
        }
        .subscription-option-btn-small{
            width: fit-content;
            padding: 9px 30px;
            margin-top: 22px;
            font-size: 14px;
            line-height: 20px;
            display: block !important;
        }
    }
    @media (max-width: 1023.95px) {
        .right-panel {
            margin-top: 61px;
            p:first-child {
                margin-top: 55px;
            }
        }
        .subscription-option-btn{
            display: none;
        }
        .subscription-option-btn-small{
            width: fit-content;
            padding: 9px 30px;
            margin-top: 22px;
            font-size: 14px;
            line-height: 20px;
            display: block !important;
        }
    }
    @media (max-width: 768.95px) {
        .back-button {
            display: none;
        }
        .left-panel {
            padding-left: 28px;
            padding-right: 28px;
            margin: auto;
            .lesson-list-item {
                .lesson-tile {
                    cursor: pointer;
                }
                .card-view {
                    .view-more {
                        .lesson-preview-title {
                            font-size: 30px;
                            line-height: 46px;
                            color: $battleshipGrey;
                        }
                        .modal-sub-wrapper {
                            // padding-top: 50px !important;
                            margin-top: 70px !important;
                            p {
                                padding-left: 10px;
                                padding-right: 10px;
                            }
                            ul {
                                padding-left: 30px;
                            }
                        }
                    }
                }
            }
            .overview-head {
                font-size: 38px;
                line-height: 54px;
                padding-top: 25px;
            }
            .overview-subhead {
                font-size: 20px;
                line-height: 28px;
                margin-top: 0px;
            }
            .subscription-option-btn-small {
                width: fit-content;
                padding: 9px 30px;
                margin-top: 22px;
                font-size: 14px;
                line-height: 20px;
                display: block;
            }
            .subscription-option-btn{
                display: none;
            }
            .lessons-list {
                margin: 26px auto auto;
                max-width: 520px;
                font-size: 14px;
                .view-lesson {
                    display: none;
                }
            }
        }
        .right-panel {
            margin-top: 40px;
            width: 100%;
            padding: 0 28px;
            p:first-child {
                margin: 39px auto auto;
                max-width: 520px;
            }
            p {
                margin: 39px auto auto;
                max-width: 520px;
                margin-bottom: 28px;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
    @media (max-width: 413.95px) {
        .right-panel {
            margin-top: 40px;
            p:first-child {
                margin: 39px auto auto;
            }
            p {
                margin: 39px auto auto;
                margin-bottom: 28px;
            }
        }
        .left-panel {
            .lesson-list-item {
                .card-view {
                    .view-more {
                        .modal-sub-wrapper {
                            padding-top: 50px !important;
                            width: 100%;
                            height: 100%;
                            border-radius: 0px;
                        }
                    }
                }
            }
        }
    }
}
