
.custom-input {
    .border-radius-on-error {
        border-radius: 7px 0px 0px 7px;
    }
    .border-radius-on-valid {
        border-radius: 7px;
    }
}
.input-icon {
    margin-left: -4rem;
    margin-top: 3.5rem;
    &:hover {
        cursor: pointer;
    }
}
