.book-session {
    @extend .position-relative;
    padding-top: 69px;
    min-height: 100vh;

    .book-header {
        font-family: "Montserrat-Medium";
         //display: flex;
         margin-top: 10px;
        // align-items: center;
        // justify-content: flex-start;

        .book-title {
            // margin-left: 20px;
            font-size: 38px;
            color: $darkMango;
            text-align: center;
            display: inline-block;
            max-width: 100%;
            @media (max-width:1024px){
                margin-left: 40px;
                margin-top:-44px;
            }
            @media (max-width:768px){
                margin-left: 0px;
                margin-top: 0px;
            }
            span {
                display: inline;
                color: $battleshipGrey;
            }
        }
        .book-subtitle {
            font-family: "Montserrat-Regular";
            color: rgb(104, 108, 128);
            font-size: 18px;
            position: relative;
            left: 70px;
            @media (min-width:992px) and (max-width:1100px) {
                font-size: 16px;
            }
        }
        .book-avatar {
            width: 46px;
            height: 46px;
            display: inline-block;
            border-radius: 100%;
            margin-left: 18px;
        }
        .book-back-button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 16px;
            margin-right: 12px;
            width: 40px;
            height: 40px;
            font-size: 12px;
            margin-top:10px;
        }
        
    }
    .subject-selection{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .book-select-subject {
        font-size: 26px;
        font-family: "Montserrat-Medium";
        line-height: 38px;
        color: rgb(104, 108, 128);
        text-align: center;
        white-space: nowrap;
        width: 210px;
        margin-top: 27px;
        margin-right: 10px;
        display: inline-block;
        @media(max-width: 768px){
            width: 100%;
            height: auto;
            text-align: left;
            margin-top: 20px;
        }
    }
    .book-select-subject + .dropdown-wrapper{
        display: inline-block;
        width: calc(100% - 220px);
        @media(max-width: 768px){
            width: 100%;
        }
        & > .input-label{ display: none; }
    }
    .book-subject {
        margin: 30px 0;
    }

    .book-slots {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        .section-header {
            display: flex;
            color: $darkMango;
            flex-direction: row-reverse;
            justify-content: "space-between";
            font-size: 14px;
            line-height: 14px;
            font-family: "Montserrat-Medium";
            flex-direction: row-reverse;
            .multiple-label {
                flex-direction: row-reverse;
                font-size: 14px;
                color: $battleshipGrey;
                span {
                    color: $darkMango;
                }
            }
            .rapid-book-meta {
                display: flex;
                position: absolute;
                left: 20pt;
                margin-top: -5pt;
                font-weight: normal;
                .rapid-book-clock {
                    margin-right: 5pt;
                    width: 18pt;
                    height: 18pt;
                }
                .rapid-book-title {
                    margin-right: 5pt;
                    color: $darkMango;
                    font-size: 14pt;
                    line-height: 20pt;
                }
                .rapid-book-info {
                    // margin-top: 10pt;
                }
            }
        }
        .section-content {
            width: 100%;
            padding-top: 15%;
            .line {
                display: flex;
                align-items: center;
                width: 100%;
                margin: 19px 0px 0px;
                min-height: 45px;
                &:first-child{
                    margin-top: 7px;
                }
                .slot-title {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    font-size: 16px;
                    font-family: "Montserrat-Medium";
                }
                .day {
                    text-transform: uppercase;
                    width: 40%;
                    height: 50px;
                    padding: 14px 0px 12px 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    // padding: 12px;
                    display: block;
                    color: $battleshipGrey;
                    font-size: 10pt;
                }
                .date-title {
                    width: 40%;

                    height: 50px;
                    padding: 12px 10px 12px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .section-content-tabs {
            padding-left: 10px;
            padding-right: 10px;
            overflow-y: auto;
            overflow-x: auto;
            .line {
                width: 100%;
                display: flex;
                align-items: center;
                margin: 10px 0px;
                .slot-title {
                    display: flex;
                    flex-direction: row;
                    background-color: blue;
                    justify-content: space-between;
                    width: 80%;
                }
            }
        }
    }
    & > * {
        font-weight: 600;
        font-family: $primary_font_Medium;
    }

    .multi-select-drop-down {
        padding: 0;
    }
}

.session-length {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 19px 0;
    align-items: center;

    input {
        width: 70px;
        color: $battleshipGrey;
        text-align: center;
        border: 0;
        position: relative;
    }
}
.session-length.disabled * {
    color: $paleGrey !important;
    pointer-events: none;
    user-select: none;
}

.navigation-panel {
    width: 100%;
    padding: 20px 0;
    color: $battleshipGrey;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-button {
        display: flex;
    }
    .nav-prev {
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
    .nav-next {
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .info-week {
        h3 {
            font-size: 20px;
            text-align: center;
        }
        .timezone-info {
            display: flex;
            align-items: center;
            justify-content: center;

            .timezone-text {
                font-size: 14px;
                line-height: 20px;
                font-family: "Montserrat-Regular";
            }
        }
    }
    .nav-icon {
        color: $darkMango;
        font-size: "25px";
        cursor: "pointer";
    }
}
.book-contact {
    margin-top: 40px;
    width: 100%;
    background-color: $battleshipGrey;
    padding: 2.5rem 0 1.5rem;
    color: $white;

    h2 {
        font-size: 26px;
        line-height: 38px;
    }
    h3 {
        font-size: 16px;
        font-family: "Montserrat-Regular";
        line-height: 22px;
    }
    p {
        font-size: 14px;
        margin: 1rem 1rem;
        line-height: 20px;
        font-family: "Montserrat-Regular";
    }

    .custom-input {
        padding: 0;
    }
    .actions{
        padding-left: 0 !important;
    }
    .button button {
        width: 250px;
        height: 65px;
        border-radius: 34.3px;
        background-color: $vividBlue;
    }
    @media (min-width:993px) and (max-width:1100px) {
       
    }
    @media (max-width:767px) {
        h2{
            font-size: 1.5rem;
        }
    }
    @media (max-width:576px) {
        h2{
            font-size: 1.3rem;
        }
    }
    @media (max-width:414px) {
        h2{
            font-size: 1.1rem;
        }
        
    }
}

.mobile-book-slots {
    height: 100%;
    width: 100%;
    .tabs {
        display: block;
        overflow-x: auto;
        min-height: 300px;
        width: 100%;
        input {
            display: none;
            &:checked + label {
                background-color: $darkMango;
                width: 100%;
                // border-radius: 5px;
                color: #ffffff;
                border: none;
                margin:2.5px 0;
                span {
                    white-space: nowrap;
                    z-index: 900;
                    background-color: none;
                    text-align: center;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                }
            }
            &:disabled + label {
                color: $paleGrey;
                background-color: #ffffff;
                border: none;
                user-select: none;
                cursor: default;
            }
            &:not(:checked) + label {
                width: 100%;
                span {
                    background-color: rgb(246, 246, 246);
                    border-radius: 5px;
                    width: 100%;
                }
            }
        }
    }


    .section-header {
        display: flex;
        color: $darkMango;
        flex-direction: row-reverse;
        justify-content: "space-between";
        font-size: 14px;
        line-height: 14px;
        font-family: "Montserrat-Medium";
        flex-direction: row-reverse;
        .multiple-label {
            flex-direction: row-reverse;
            font-size: 14px;
            color: $battleshipGrey;
            span {
                color: $darkMango;
            }
            @media (max-width:767px) {
                padding-top: 35px;
            }
        }
        .rapid-book-meta {
            display: flex;
            position: absolute;
            left: 20pt;
            margin-top: -5pt;
            font-weight: normal;
            .rapid-book-clock {
                margin-right: 5pt;
                width: 18pt;
                height: 18pt;
            }
            .rapid-book-title {
                margin-right: 5pt;
                color: $darkMango;
                font-size: 14pt;
                line-height: 20pt;
            }
            .rapid-book-info {
                // margin-top: 10pt;
            }
        }
    }
    .DatePicker_dateDayItem__29e_e{
        .DatePicker_dateLabel__2_yFu{
            font-size: 16px;
        }
    }
    @media (max-width:768px) {
        .DatePicker_container__2PmMa{
            padding: 20px 0;
        }
        #container{
            margin: 2px 8px 2px 8px !important;
        }
        .DatePicker_dateListScrollable__2GNL0{
            margin: 2px 8px 2px 8px !important;
        }
        .DatePicker_daysContainer__1FzwR{
            margin-left: 10px !important;
        }
    }
}

.tabs {
    min-width: 100%;
    box-shadow: #0000000d 0px 10px 10px 0px;
    background-color: #ffffff;
    display: inline-flex;
    border-radius: 0px;
    font-family: $primary_font_Medium;
    margin: 10px 0;
    padding: 0;
    height: 50px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    input {
        display: none;
        &:checked + label {
            background-color: $darkMango;
            // width: 110px;
            // border-radius: 5px;
            color: #ffffff;
            border: none;
            margin:0 2.5px;
            span {
                white-space: nowrap;
                z-index: 900;
                background-color: none;
                text-align: center;
                width: 120pt;
                display: flex;
                justify-content: flex-start;
                padding-left: 5px;
            }
        }
        &:disabled + label {
            color: $paleGrey;
            background-color: #ffffff;
            border: none;
            user-select: none;
            cursor: default;
        }
        &:not(:checked) + label {
            span {
                background-color: rgb(246, 246, 246);
                border-radius: 5px;
            }
        }
    }
    label {
        width: 100px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        color: $battleshipGrey;
        font-weight: 600;
        margin: 0;
        display: flex;
        justify-content: center;

        span {
            display: flex;
            width: 95px;
            height: 40px;
            margin: 2px;
            align-items: center;
            justify-content: center;
            @media (max-width:768px) {
                justify-content: flex-start !important;
                padding-left: 10px;
            }
        }
        span ~ span {
            font-size: 12px;
        }
        &:hover {
            cursor: pointer;
        }
    }
}

.tabs::-webkit-scrollbar {
    display: none;
}

.single-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat-Regular";
    font-size: 12pt;
    line-height: 20pt;
    text-transform: uppercase;
    label {
        width: max-content;
        span {
            width: 60pt;
        }
    }
}

/* @media screen and (min-width: 769px) {
    .book-session .book-header {
        width: 100%;
        margin-bottom: 51px;
    }
    .book-session .book-header .book-title {
        margin: auto;
    }
    .hidden-lg {
        display: none !important;
    }
    .book-session .book-slots .section-content .line .tabs {
        justify-content: space-between;
    }
    .session-length {
        padding: 10px 0;
    }
    .book-session .book-slots .section-content .line .tabs label {
        width: 33.33%;
    }
} */
.rounded-button:disabled svg {
    color: $paleGrey !important;
}

.book-subject .multi-select-drop-down .dropdown-box-continer .dropdown-box {
    height: unset;
    max-height: 300px;
}

.profile {
    position: absolute;
    top: 30px;
    left: 55px;
}

.not-available-label {
    margin: 5px;
    padding-left: 15px;
    display: flex;
    font-size: 12pt;
    font-style: italic;
    align-items: center;
    color: $battleshipGrey;
    width: 100%;
}
.MuiDialog-paperWidthSm {
    max-width: 500px !important;
}
.book-sessions-modal-btn-container{
    @media (max-width:768px) {
       display: flex;
       flex-direction: column !important;
    }
}
@media (max-width:768px) {
    .book-session{
        padding-top: 15px;
        .book-header{
            display: flex;
        }
        .book-title{
            text-align: left !important;
            font-size: 1.6rem !important;
            line-height: 2;
            padding-bottom: 10px;
            
            .book-back-button{
            
            }
        }
        .book-subtitle{
            font-size: 1rem;
            padding: 10px 0;
            line-height: 25px;
            display: none;
        }
        .book-select-subject{
            font-size: 22px;
        }
    }
}
@media (min-width:992px) and (max-width:1200px) {
    .book-session{
        .container{
            padding-left: 50px;
            padding-right: 50px;
        }
    }
    .book-contact{
        .actions{
            padding-left: 0 !important;
        }
        p{
            width: 100%;
            margin-right:0 !important;
        }
        
    }
}