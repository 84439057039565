.MuiContainer-root {
    max-width: 926px !important;
}
.booking-confirmed {
    padding-top: 40px;
    color: $battleshipGrey;
    // width: 926px;
    h1 {
        font-size: 2.375rem;
        color: $darkMango;
        text-align: center;
    }

    .session-data {
        border-radius: 25px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        padding: 50px;
        @media (max-width:768px) {
            padding: 40px 15px;
        }
        .multiAvail{
            overflow-y: scroll;
            height: 140px;
        }
        .availability {
            width: 100%;
            margin: 0;
            padding: 0;
            
            // border-right: 1px solid $paleGrey;
            min-height: 100px;
            li {
                font-family: $primary_font_Regular;
                color: $battleshipGrey;
                list-style: none;
                margin: 12px 0;
                font-size: 1rem;
            }
        }
        .subtotal {
            p {
                color: $darkMango !important;
            }
        }
        .total {
            height: 100%;
            p {
                line-height: 1;
                font-size: 1.625rem !important;
                color: $darkMango !important;
                cursor: text !important;
                text-decoration: none !important;
            }
            .number {
                cursor: text;
                text-decoration: none;
                color: $battleshipGrey !important;
                font-size: 1.625rem;
            }

            p:last-of-type {
                @extend .p-link;

                & ~ span {
                    margin-top: 60px;
                    display: inline-block;
                }
            }
        }
    }
    .actions {
        @extend .flex-center-between;
        padding: 40px 0;
        span {
            @extend .p-link;
        }
    }
    .actions {
        margin-bottom: 20px;
        @media (max-width:767px) {
            text-align: center !important;
            padding: 30px 0 0 !important;
        }
    }
}
.actions{
    @media (max-width:768px) {
        text-align: center !important;
        padding: 30px 0 0 !important;
    }
}
.NCA-modal-link{
    cursor: pointer;
    color: $darkMango;
    font-size: 14px;
    text-decoration: underline;
}
.NCA-modal{
    @media (min-width:768px) {
        height: fit-content !important;
    margin: auto !important;
    .custom-modal-content{
        margin: auto !important;
    }
    }
}