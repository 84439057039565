.rounded-button {
  outline: none;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  background-color: transparent;
  border: 0;
  border: solid 2px $vividBlue;

  &:focus {
      outline: none;
  }
}