@import 'variables';
@import 'theme';
@import 'fonts';

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-link {
  color: $darkMango;
  font-size: 0.875rem;
  text-decoration: underline;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.centered-button {
  margin: 20px auto;
  display: block;
}
.navigation {
  margin: 0;
  display: inline-block;
  color: $darkMango !important;
  text-decoration: underline;
  cursor: pointer;
}
.default-border-radius {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

