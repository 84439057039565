//input-label
.input-label {
    font-family: $primary_font_Medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.5px;
    color: $darkGrey;
}
//input-box

.custom-input {
    @extend .d-block;
    width: 100%;

    position: relative;
    z-index: 0;
    .input-container {
        @extend .d-flex;
        display: -ms-flexbox; /* IE10 */
        width: 100%;
        -webkit-user-select: initial !important;
        -khtml-user-select: initial !important;
        -moz-user-select: initial !important;
        -ms-user-select: initial !important;
        user-select: initial !important;

        .error-box {
            @extend .text-center;
            background: $red;
            border-radius: 0px 7px 7px 0px;
            color: $white_solid;
            min-width: 8px;
        }
        .success-box {
            @extend .text-center;
            background: $greenishTeal;
            border-radius: 0px 7px 7px 0px;
            color: $white_solid;
            min-width: 8px;
        }
        input {
            padding-left: 20px;
            border: 0px solid $white_solid;
            height: 65px;
            width: 100%;
            border-radius: 7px 0px 0px 7px;
            box-shadow: 0 10px 10px 0 $light_black;
            -webkit-user-select: initial !important;
            -khtml-user-select: initial !important;
            -moz-user-select: initial !important;
            -ms-user-select: initial !important;
            user-select: initial !important;
        }
        input::placeholder,
        textarea::placeholder {
            font-family: $primary_font_Regular;
            font-size: 16px;
            color: $battleshipGrey;
        }
        input:focus,
        textarea:focus {
            outline-offset: -2px;
            outline: none;
        }
        textarea {
            padding: 25px 15px;
            -webkit-user-select: initial !important;
            height: 100%;
            resize: none;
            padding-left: 20px;
            border: 0px solid $white_solid;
            width: 100%;
            border-radius: 7px 0px 0px 7px;
            box-shadow: 0 10px 10px 0 $light_black;
        }
    }
    .error-label {
        @extend .text-right;
        color: $red;
        font-size: 14px;
        line-height: 20px;
        min-height: 20px;
        font-family: $primary_font_Regular;
    }
    .success-label {
        @extend .text-right;
        color: $battleshipGrey;
        font-size: 14px;
        line-height: 20px;
        min-height: 20px;
        font-family: $primary_font_Regular;
    }
}

.input-icon {
    margin-left: -40px;
    margin-right: 20px;
    margin-top: 24px;
    &:hover {
        cursor: pointer;
    }
}

//check-box
.custom-check {
    padding: 10px 12px;
    .container {
        @extend .d-block;
        @extend .position-relative;
        padding-left: 35px !important;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        color: $battleshipGrey;
        font-family: $primary_font_Regular;
    }

    /* Hide the browser's default checkbox */
    .container input {
        @extend .position-absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        @extend .position-absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        border-radius: 4px;
        border: 2px solid $vividBlue;
        background-color: $white_solid;
    }

    .checkmark-error {
        border: 2px solid red;
    }
    .checkmark-error-text {
        color: red;
        @extend .position-absolute;
        font-size: small;
        margin-top: -5px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: $white_solid;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: $vividBlue;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        @extend .position-relative;
        @extend .d-none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        @extend .d-block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 7px;
        top: 1px;
        width: 5px;
        height: 14px;
        border: solid $white_solid;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

input[type="checkbox"]:disabled ~ .checkmark {
    border: 0;
    background: none;
}
.custom-check.circle {
    .container input:checked ~ .checkmark {
        background: url(../../assets/images/icons/check.svg) center center no-repeat;
        background-size: contain;
    }
    .container input:checked ~ .checkmark:after {
        @extend .d-none;
    }
}
.custom-input .input-container .error-box {
    z-index: 2;
}
.dropdown-box-continer {
    max-height: 300px;
}
.error-input-file {
    background: #ff0000;
    border-radius: 0px 7px 7px 0px;
    color: #ffffff;
    min-width: 8px;
}

.dropdown-wrapper {
    padding: 15px 0;
    .error-input-label {
        color: #ff0000;
        font-size: 14px;
        line-height: 20px;
        min-height: 20px;
        font-family: Montserrat-Regular;
        text-align: right;
        padding-right: 13px;
        margin-top: -10px;
    }
    .custom-input .success-label {
        display: none;
    }
}

.dropdown-wrapper.error-input {
    input {
        border-right: 7px solid #f00;
        border-radius: 7px;
    }
}

.input-tooltip {
    position: absolute;
    right: 33px;
    top: 48%;
    width: 15px;
    cursor: help;
}

div[role="tooltip"] > div {
    background: white;
    color: $battleshipGrey;
    font-size: 0.825rem;
    padding: 1.575rem;
    border: 0px solid #ffffff;
    border-radius: 7px 0px 0px 7px;
    box-shadow: 0 10px 10px 0 #0000000d;
    font-family: $primary_font_Regular;
}
