// $mango: #f9a722;
$darkMango: #f28334;
$battleshipGrey: #686c80;

.slider-marker {
    font-size: 12px;
    font-weight: 500;
    color: $battleshipGrey;
    text-transform: uppercase;
    width:34px;
}

.MuiTooltip-popper[x-out-of-boundaries] {
    display: none;
}

.Range {
    .row {
        .col {
            .rightRange {
                text-align: center;
            }
            .leftRange {
                margin-left: 16px;

            }
        }
    }
}