$darkMango: #f28334;
.payout-account {
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .heading {
            flex-basis: 100%;
            margin: 0 5px;
            font-size: 16px;
            line-height: 1.38rem;
            font-weight: 600;
        }
        .account-statuses {
            font-family: "Montserrat-Regular";
            font-size: 14px;
            line-height: 22px;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .account-status {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 5px 20px 0 0;
                div {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    padding: 5px;
                    margin: 0 10px;
                }
                p {
                    margin-top: 18px;
                }
            }
            .success {
                background-color: #5b9911;
            }
            .error {
                background-color: #ef5350;
            }
        }
        .account-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: wrap;
            .button button {
                min-width: 180px;
                min-height: 45px;
                width: initial;
                height: initial;
                padding-left: 17px;
                padding-right: 17px;
            }
        }
    }
    .add-payout-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 53px;
        .button button {
            min-width: 180px;
            min-height: 45px;
            width: initial;
            height: initial;
            padding-left: 17px;
            padding-right: 17px;
        }
    }
    .payout-account-alert {
        min-height: 62px;
        font-family: "Montserrat-Medium";
        font-size: 16px;
        line-height: 32px;
        color: white;
        background-color: #ef5350;
        svg {
            fill: white;
            width: 30px;
            height: 35px;
        }
    }
    .payout-account-detail-section {
        margin-top: 40px;
        .payout-account-detail-item {
            border-left: 0;
            border-right: 0;
            .avatar {
                background-color: $darkMango;
                width: 48px;
                height: 48px;
                padding: 13px;
            }
            .payout-account-item-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                .title {
                    margin: 0 10px 0 0;
                }
                .badges {
                    margin: 0 5px;
                    text-transform: capitalize;
                }
            }
            .payout-account-item-subtitle{
                font-size: 14px;
                line-height: 22px;
                color: #2d3234;
                margin: 0px 0;
            }
        }
    }
}