$battleshipGrey: #686c80;
$normalGrey: #dddee8;
$darkMango: #f28334;
$whiteTab:#fff;
$activeTabClr: #686c80;
.switch-tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    float: right;
    .tabs-container {
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-justify: center;
        border-radius: 7px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        padding: 4px;
        background-color: #ffffff;
        font-size: 1vw;
        .active-tab {
            background-color: $activeTabClr;
            height: 100%;
            display: flex;
            color: white;
            border-radius: 7px;
            align-items: center;
            cursor: pointer;
        }
        .tab {
            height: 100%;
            display: flex;
            align-items: center;
            color: $battleshipGrey;
            cursor: pointer;
            width: 160px !important;
        }
    }
}
.session-userdata{
    
    @media (max-width:768px) {
        padding-bottom: 10px;
        .session-card-headings{
            max-width: none;
        }
        p{
            margin-bottom: 5px;
            padding-right: 30px;

        }
    }
}

.my-sessions {
    $battleshipGrey: #686c80;
    $normalGrey: #dddee8;
    $darkMango: #f28334;
    .switch-tabs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: fit-content;
        float: right;
        
        .tabs-container {
            height: 60px;
            flex: 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-justify: center;
            border-radius: 7px;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
            padding: 0px;
            background-color:$whiteTab;
            font-size: 14px;
            .active-tab {
                background-color:$battleshipGrey;
                height: 100%;
                display: flex;
                color: white;
                border-radius: 7px;
                align-items: center;
                cursor: pointer;
                border-left: 1px solid white;
                min-width: 200px;
                span {
                    width: 25px;
                    height: 24px;
                    margin-left: 10px;
                    font-size: 18px;
                    border-radius: 100%;
                    background-color: white;
                    color: $darkMango;
                    display: flex;
                    justify-content: center;
                }
            }
            .tab {
                height: 100%;
                display: flex;
                align-items: center;
                color: $battleshipGrey;
                cursor: pointer;
                border-left: 1px solid white;
                min-width: 200px;
                span {
                    width: 25px;
                    height: 24px;
                    margin-left: 10px;
                    font-size: 18px;
                    border-radius: 100%;
                    
                    background-color:$darkMango;
                    color: $whiteTab;
                    display: flex;
                    justify-content: center;
                }
            }
        }
      
    }
}
@media (max-width:1024px) {
    .session-heading-grid{
        width: 100%;
        max-width: 100% !important;
    }
    .session-tabs-grid{
        width: 100%;
        max-width: 100% !important;
        min-width: 100% !important;
        padding: 15px 0;
    }
  
}
@media (min-width:768px) and (max-width:1023px) {
    .session-tabs-grid{
        display: flex;
        justify-content: center;
    }
    .my-sessions{
        width: 75%;
    }
}

@media (max-width:768px) {
    .my-sessions{
       
        .switch-tabs{
            width: 100%;
            .tabs-container{
                .tab{
                   
                    min-width: 100px !important;
                    padding-left: 5px !important;
                    padding-right: 5px !important;
                    font-size: 13px;
                    width: 33% !important;
                }
                .active-tab{
                  
                    min-width: 100px !important;
                    padding-left: 5px !important;
                    padding-right: 5px !important;
                    width: 33% !important;
                }
            }
            }
    }
}