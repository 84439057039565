// $mango: #f9a722;
$darkMango: #f28334;
$battleshipGrey: #686c80;
$paleGrey: #dddee8;
$darkGrey: #2d3234;
$vividBlue: #262ffa;
.tabs-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    .sort-label-container {
        vertical-align: bottom;
        .sort-active-label {
            font-weight: 500;
            color: $darkGrey;
            font-size: 26px;
            margin-right: 45px;
            line-height: 40px;
            cursor: pointer;
        }
        .sort-label {
            font-weight: 400;
            color: $battleshipGrey;
            font-size: 16px;
            margin-right: 45px;
            height: 40px;
            line-height: 45px;
            cursor: pointer;
            vertical-align: text-bottom;
        }
    }
    @media (max-width: 768px) {
        .sort-active-label {
            font-size: 16px !important;
            line-height: 40px;
            margin-right: 20px !important;
        }
        .sort-label {
            margin-right: 20px !important;
        }
    }
    .tab-indicator {
        background-color: $paleGrey;
    }
}

.highlight-active {
    height: 4px;
    background-color: $paleGrey;
    div {
        background-color: $vividBlue;
        width: 50%;
        height: 100%;
    }
}
.highlight-inactive {
    background-color: $paleGrey;
    height: 4px;
    div {
        height: 50%;
    }
}
