.navigation-card {
    padding: 15px;
    height: 240px;
    margin-bottom: 30px;
    .search-new {
        display: flex;
        margin-top: 16px;
        cursor: pointer;
        p {
            margin-bottom: 0;
            font-size: 15px;
            margin-left: 14px;
            line-height: 26px;
            text-decoration: underline;
            color: $vividBlue;
        }
    }
    img {
        width: 130px;
    }
    .title {
        margin-bottom: 5px;
        font-size: $extra_large_font;
    }
    .sub-title {
        color: $battleshipGrey;
        font-size: $small_font;
        font-family: $primary_font_Regular;
    }
    .bottom-content {
        position: absolute;
        bottom: 0;
        width: 100%;
        .session-detail {
            margin-bottom: 10px;
            .session-detail-text-container {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 10px;
                .session-detail-text {
                    font-size: $extra_small_font;
                    width: 70%;
                    margin-bottom: 0px;
                }
            }
        }
        .count {
            border-radius: 50%;
            background-color: $darkMango;
            width: min-content;
            font-family: $primary_font_Medium;
            font-size: $medium_font;
            display: inline-flex;
            color: $white_solid;
            padding: 5px;
            width: 60px;
            height: 60px;
            align-items: center;
            justify-content: center;
            .counter {
                margin: auto;
            }
        }
        .nav-footer {
            .float-left {
                float: left;
                margin: auto;
                margin-bottom: 10px;
                position: relative;
                margin-top: 10px;
            }
            .goto-arrow {
                position: absolute;
                bottom: 10px;
                display: none;
                margin-bottom: 10px;
                margin-top: 10px;
                float: right;
                right: 40px;
                position: relative;
                margin-left: 10px;
                svg {
                    right: 10px;
                    color: $vividBlue;
                }
            }
        }
    }
}

.navigation-button-container {
    position: absolute;
    bottom: -20px;
    text-align: center;
    width: 100%;
    right: 5px;
}

.lg-screen-image {
    margin-top: 20px;
    display: block;
}

.md-screen-image {
    display: none;
}

@media (max-width: 1024.95px) {
    .navigation-card {
        height: 200px;
        .search-new {
            margin-bottom: 20px;
        }
        .bottom-content {
            .nav-footer {
                .goto-arrow {
                    display: block;
                    position: absolute;
                    bottom: 10px;
                }
            }
        }
    }
    .navigation-button-container {
        display: none;
    }
    .lg-screen-image {
        display: none;
    }
    .md-screen-image {
        display: block;
    }
    .no-sessions-md-img {
        height: 72px !important;
        width: 79px !important;
    }
}

@media (max-width: 768.95px) {
    .navigation-card {
        height: 206px;
        img {
            width: 100px;
        }
        .title {
            font-size: $medium_font;
        }
        .sub-title {
            font-size: $extra_small_font;
        }
    }

    .no-sessions-md-img {
        height: 68px !important;
        width: 75px !important;
    }
}
@media (max-width: 576px) {
    .navigation-card {
        img {
            margin-bottom: 10px;
        }
    }
}
@media (min-width: 576px) and (max-width: 770px) {
    .navigation-button-container {
        display: block;
    }
    .student-dashboard-container {
        .goto-arrow {
            display: none !important;
        }
    }

    .session-text-center-on-md {
        text-align: center !important;
    }
    .md-screen-image {
        position: relative;
        bottom: 20px;
    }
    .md-screen {
        .button button {
            width: 150px;
            height: 40px;
            font-size: 14px;
        }
    }
    .session-text-center-on-md {
        button {
            background: #fff !important;
            color: $darkMango;
            outline: 2px solid $darkMango;
            border: 2px solid $darkMango;
        }
        .md-screen-image {
            img {
                position: relative;
                right: 15px;
            }
        }
    }
}
.inline-block {
    display: inline-block;
}
.display-flex {
    display: flex;
}
.dashboard-tutors-img {
    height: 56.3px;
    width: 200px !important;
    @media (max-width: 992px) {
        width: 150px !important;
        height: 41.73px;
    }
}
