// @import "~bootstrap/scss/bootstrap";

.modal-wrapper {
    @extend .d-flex;
    @extend .justify-content-center;
    max-width: 100vw;
    @extend .position-fixed;
    background-color: rgba(104, 108, 128, 0.75);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

.modal-sub-wrapper {
    @extend .d-flex;
    @extend .align-self-center;
    @extend .shadow;
    @extend .p-4;
    @extend .flex-column;
    border-radius: 20px 0px 20px 0px;
    background-color: white;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 692px;
    width: 692px;
}

.modal-wrapper-exit {
    @extend .d-flex;
    @extend .justify-content-end;
    img {
        width: 1rem;
        height: 1rem;
        &:hover {
            cursor: pointer;
        }
    }
}
@media (max-width:768px) {
    .modal-sub-wrapper{
        margin-top: 70px;
    }
}
@media (min-width: 300px) and (max-width: 320px) {
    .modal-sub-wrapper {
        width: 18rem;
    }
}
@media (min-width:577px) and (max-width:767px) {
    .modal-sub-wrapper{
        width: 30rem;
    }
}
@media (min-width: 280px) and (max-width: 576px) {
    .modal-sub-wrapper {
        width: 100%;
        .modal-box{
            padding: 20px 0;
        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .modal-sub-wrapper {
        width: 36rem;
    }
}
