// $mango: #f9a722;
$darkMango: #f28334;
.status-primary {
    background-color: $darkMango;
    color: white;
}
.status-secondary {
    background-color: white;
    border-color: $darkMango;
    border-radius: 20%;
    border: 2px;
    color: $darkMango;
}