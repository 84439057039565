.student-detail-card-container {
    width: 100%;
    font-family: $primary_font_Medium;
    padding-bottom: 1px;
    .avatar {
        border-radius: 50%;
        height: 46px;
        width: 46px;
        margin-top: 21px;
        margin-left: 20px;
    }
    .student-name-subject {
        padding-top: 0px;
        margin-left: 20px;
        .session-student-name {
            color: $darkMango;
            font-size: 18px;
            line-height: 28px;
        }
        .session-subject {
            color: $battleshipGrey;
            font-size: 14px;
            line-height: 20px;
            font-family: $primary_font_Regular;
        }
    }
    .session-label {
        font-size: 14px;
        color: $darkGrey;
        margin-bottom: 0px;
    }
    .student-session-datetime {
        font-size: 14px;
        color: $battleshipGrey;
        font-family: $primary_font_Regular;
    }
    .current-session-label {
        font-size: 14px;
        color: $greenishTeal;
    }
    .student-card-right-col-button {
        .button {
            button {
                width: 140px;
                height: 38px;
            }
        }
        text-align: right;
        padding-right: 20px;
    }
}
