.multi-select-drop-down {
    display: flex;
    flex-direction: column;
    padding: 12px;
    .custom-input {
        padding: 0px;

        input {
            cursor: pointer;
        }
    }
    .dropdown-box-continer {
        width: 100%;
        position: relative;

        .dropdown-box {
            //box-shadow: 0 10px 10px 0 $light_black;
            max-height: 300px;
            // background-color: $white_solid;
            padding: 0;
            
            overflow-y: auto;
            width: 100%;
            z-index: 2000;
            margin-top: -20px;
            @extend .position-absolute;
            .dropdown-item {
                background-color: $white_solid;
                white-space: normal;
                @extend .dropdown-item;
                font-family: $primary_font_Medium;
                padding: 1rem;
                color: #2d3234;
                cursor: pointer;
                width: 100%;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.options-box {
    @extend .d-flex;
    @extend .flex-row;

    flex-flow: row wrap;
    margin-top: 1rem;
    p {
        font-family: $primary_font_Medium;
        color: $white_solid;
        font-size: 14px;
        background-color: $darkGrey;
        border-radius: 0.5rem;
        margin-left: 1.5rem;
        padding: 0.5rem 3rem 0.5rem 1.2rem;
        position: relative;
        .option-x-icon{
            position: absolute;
            right: 15px;
        }
        svg {
            color: $battleshipGrey;
            width: 1.5rem;
            @extend .position-relative;
            z-index: 19;
            margin-left: 0.7rem;
            &:hover {
                cursor: pointer;
            }
         
        }
    }
}
.multi-select-drop-down .dropdown-box-continer .dropdown-box.dropup {
    height: 169px;
    margin-top: -132px;
    display: flex;
    flex-direction: column-reverse;
}

.multi-select-drop-down .dropdown-box-continer .dropdown-box.dropdown {
    margin-top: -20px;
}