$darkGrey: #2d3234;
.help-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 8%;
    .page-header {
        font-size: 60px;
        font-stretch: normal;
        font-weight: normal;
        color: $darkGrey;
        padding-bottom: 2rem;
        text-align: center;
        @media (max-width:768px) {
            font-size: 48px;
        }
    }
    .message{
        padding: 0 12px;
    }
    .input-container {
        // background-color: red;
        width: 100%;
        .dropdown-wrapper.simple-dropdown {
            padding: 0 12px;
            margin: 0;
            min-height: 120px;
            // width: 102%;
        }
    }
    .submit-button {
        margin-top: 60px;
        width: 171px;
        height: 45px;
        .button-updated button{
            height: 45px;
            border-radius: 31px;
            @media (max-width:768px) {
                height: 48px;
                border-radius: 26.3px;
            }
        } 
        div{
            font-size: 18px!important;
            @media (max-width:768px) {
                font-size: 16px!important;
            }
        }
        @media (max-width:768px) {
            width: 200px;
        }
    }
}
