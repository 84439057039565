.chat-bubble {
    margin: 20px;
    float: right;
    position: relative;
    border-radius: 0.6em 0em 0.6em 0.6em;
    max-width: 40%;
    min-width: 219px;
    // min-height: 59px;
    background-color: $lite_white;
    padding: 9px 16px;

    .user-details {
        font-family: $primary_font_SemiBold;
        font-size: 10px;
        font-weight: 600;
        font-stretch: normal;
        text-transform: capitalize;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: $lightUrple;
    }

    .user-message {
        font-family: $primary_font_Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        word-break: break-word;
        color: $battleshipGrey;
    }

    .message-attachment {
        width: 200px;
        min-height: 35px;
        border-radius: 5px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        background-color: $white_solid;

        .attachment-name {
            font-family: $primary_font_Regular;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $primary_solid_color;
        }

        .image-loader-container {
            position: relative;
            display: flex;

            .image-loader {
                height: inherit;
                width: inherit;
                position: absolute;
                color: $primary_solid_color;
                opacity: 0.9;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .image-message {
        .image-loader-container {
            position: relative;
            display: flex;

            .image-loader {
                height: 20vh;
                width: 20vh;
                position: absolute;
                color: $primary_solid_color;
                opacity: 0.9;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .message-time {
        font-family: $primary_font_Regular;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: right;
        color: #686c80;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0%;
        width: 0;
        height: 0;
        border: 18px solid transparent;
        border-left-color: $lite_white;
        border-right: 0;
        border-top: 0;
        margin-top: 0px;
        margin-right: -18px;
    }
}

.receive-chat-bubble {
    @extend .chat-bubble;
    background-color: $paleGrey;
    border-radius: 0em 0.6em 0.6em 0.6em;
    float: left;

    &::after {
        display: none;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0%;
        width: 0;
        height: 0;
        border: 18px solid transparent;
        border-right-color: $paleGrey;
        border-left: 0;
        border-top: 0;
        margin-top: 0px;
        margin-left: -18px;
    }
}

.unread-count-container {
    height: auto;
    width: 300px;
    margin: auto;
    text-align: center;
    .unread-count {
        position: relative;
        font-family: $primary_font_Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $white_solid;
        top: -24px;
    }
    .unread-message {
        margin-top: 34px;
        font-family: $primary_font_Regular;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: center;
        color: $battleshipGrey;
    }
}
