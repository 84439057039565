.button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 40px;
}
.backdrop {
    width: 100%;
    height: 100%;
    background: #000;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border: 1px solid $darkMango;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 27px;
        z-index: 60;

        svg {
            transform: rotate(180deg);
            fill: $darkMango;
            color: $darkMango;
        }
    }
    .description {
      h2 {
        margin-bottom: 10px;
        color: white;
      }
      p {
        max-width: 65%;
          color: white;
      }
    }
}
.take-picture-button-wrapper{
  @media (max-width:768px) {
    display: block;
    text-align:center;
    .button{
      display: block;
    }
  }
}
