.tutor-dashboard-container {
    .bottom-content {
        bottom: 0;
        width: 100%;
        .session-detail {
            margin-bottom: 10px;
        }
    }

    .dashboard-left-panel {
        position: relative;
        max-height: 90vh;
    }
    padding: 50px 0px 20px 33px;
    font-family: $primary_font_Medium;
    .calender-panel {
        height: fit-content;
    }
    svg {
        color: $darkMango;
    }
    .user-panel-head-name{
        position: relative;
        top: 20px;
    }
    .no-results-card-container {
        .card-view {
            box-shadow: unset !important;
        }
    }
    .tutor-dashboard-section-head {
        font-size: 26px;
        color: $darkGrey;
        margin: 0px;
    }
    .student-name-subject {
        margin-left: 0 !important;
    }
    .student-detail-card-container {
        padding: 10px;
        .columns {
            padding: 5px 0;
        }
        .student-card-profile-pic {
            .avatar {
                margin-top: 0px;
                margin-left: 0 !important;
                margin-right: 10px !important;
            }
        }
    }

    .tutor-dash-head {
        position: relative;
        width: 100%;
        padding-bottom: 0 !important;
        @media (max-width: 1024) {
            padding-bottom: 25px;
        }
        .tutor-dashboard-section-head {
            margin-top: 15px;
        }
        .total-student-counter {
            font-size: 14px;
            color: $darkMango;
        }
        .tutor-dash-search-container {
            .custom-input {
                height: 65px;
            }
            .input-label {
                height: 0px;
            }
            .input-container {
                width: 100%;
                position: absolute;
                top: 0;
            }
        }
    }
    .tutor-dashboard-detail-card-container {
        .upcoming-sessions {
            display: none;
        }
        .card-view {
            padding-left: 19px;
            height: 225px;
           @media (min-width: 768px) {
               height: 235px;
           }
            .tutor-detail-card-head {
                font-size: 90%;
                color: $darkGrey;
                margin-top: 8px;
                margin-bottom: 0px;
                @media(min-width: 768px) and (max-width: 1024px) {
                    font-size: 120%;
                }
                @media(min-width: 321px) and (max-width: 767px) {
                    font-size: 60%;
                }
                @media(max-width: 320px) {
                    font-size: 55%;
                }
                @media(min-width: 1400px) {
                    font-size: 98%;
                }
                span {
                    font-weight: lighter;
                    color: $darkGrey;
                    font-size: 7pt;
                    @media(min-width: 321px) and (max-width: 767px) {
                        font-size: 6pt;
                    }
                    @media(max-width: 320px) {
                        font-size: 5.5pt;
                    }
                }
            }
            .tutor-detail-card-head-no-margin {
                font-size: 90%;
                color: $darkGrey;
                margin-top: -17px;
                margin-bottom: 0px;
                @media(min-width: 768px) and (max-width: 1024px) {
                    font-size: 120%;
                }
                @media(min-width: 321px) and (max-width: 767px) {
                    font-size: 60%;
                }
                @media(max-width: 320px) {
                    font-size: 55%;
                }
                @media(min-width: 1400px) {
                    font-size: 98%;
                }
                span {
                    font-weight: lighter;
                    color: $darkGrey;
                    font-size: 7pt;
                    @media(min-width: 321px) and (max-width: 767px) {
                        font-size: 6pt;
                    }
                    @media(max-width: 320px) {
                        font-size: 5.5pt;
                    }
                }
            }
            .TD-para {
                font-size: 50%;
                margin-top: -12px;
                padding-right: 15%;
                @media(min-width: 768px) and (max-width: 1024px) {
                    font-size: 60%;
                }
                @media(min-width: 321px) and (max-width: 767px) {
                    font-size: 40%;
                    margin-top: -5px;
                }
                @media(max-width: 320px) {
                    font-size: 40%;
                    margin-top: -10px;
                }
            }
            .message-para {
                font-size: 60%;
                @media(min-width: 768px) and (max-width: 1024px) {
                    font-size: 80%;
                }
                @media(min-width: 321px) and (max-width: 767px) {
                    font-size: 50%;
                }
                @media(max-width: 320px) {
                    font-size: 50%;
                }
                @media(min-width: 1400px) {
                    font-size: 68%;
                }
            }
            .tutor-pending-requests-container {
                .request-counter {
                    font-size: 30px;
                    color: $darkMango;
                }
                .request-profile-pic-container {
                    margin-top: 79px;
                    .request-profile-pic {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin: 5px;
                    }
                }
            }
            .goto-arrow {
                position: absolute;
                bottom: 10px;
                right: -7px;
                padding-right: 10px;
                cursor: pointer;
            }
            .tutor-rating-container {
                .tutor-dashboard-number-rating-container {
                    .tutor-dashboard-number-rating {
                        font-size: 30px;
                        color: $darkMango;
                    }
                    font-size: 14px;
                    color: $battleshipGrey;
                }
                .tutor-dashboard-rating-text-counter {
                    margin-top: 52px;
                    margin-bottom: 0px;
                    font-size: 14px;
                    color: $battleshipGrey;
                }
                .tutor-dashboard-apple-rating {
                    font-size: 25px;
                    width: 70%;
                }
            }
            .tutor-hours-container {
                font-size: 14px;
                color: $darkMango;
                .request-counter {
                    font-size: 30px;
                    color: $darkMango;
                }
                .goto-arrow {
                    float: right !important;
                    padding-right: 10px;
                    cursor: pointer;
                }
            }
            .tutor-earning-container {
                font-size: 14px;
                color: $darkMango;
                .request-counter {
                    font-size: 100%;
                    color: $darkMango;
                    @media (max-width: 767px) {
                        font-size: 100%;
                    }
                }
                .request-counter-top-align {
                    font-size: 100%;
                    color: $darkMango;
                    margin-top: -15px;
                    @media (max-width: 767px) {
                        font-size: 100%;
                    }
                }
            }
        }
    }
}
.panel-body {
    width: 90%;
    margin-left: 110px !important;
}
#right-col {
    padding-right: 3em;
    padding-left: 3em;
    @media (max-width: 1024px) {
        padding-right: 0 !important;
        padding-left: 25px !important;
    }
    @media (max-width: 414px) {
        padding-right: 0 !important;
        padding-left: 0px !important;
    }
}
@media (max-width: 1024px) {
    .tutor-dashboard-container {
        .rapid-book {
            margin-left: 20px;
        }
        .student-card-view-height {
            display: none;
        }
        .calender-panel {
            height: auto;
        }
        .student-card-right-col-button {
            .button {
                button {
                    width: 150px !important;
                }
            }
        }
        .tutor-dash-head {
            display: none;
        }
        .student-name-subject {
            margin-left: 10px !important;
        }
        .tutor-dashboard-detail-card-container {
            .upcoming-sessions {
                display: block;
            }
            .card-view {
                margin-left: 20px !important;
                margin-right: 40px !important;
                margin-bottom: 20px;
            }
        }
    }
}
@media (max-width: 768px) {
    .tutor-dashboard-container {
        padding: 30px 20px;
        .student-card-view-height {
            height: 15% !important;
        }
        .student-detail-card-container {
            border-bottom: 2px dashed #0000000d;
        }
        .user-panel-container {
            display: none;
            .user-panel-body {
                padding: 5px;
            }
        }
        #right-col {
            padding-right: 1em;
            padding-left: 1em;
        }
        .student-name-subject {
            margin-left: 25px !important;
        }
        .student-card-right-col-button {
            .button {
                button {
                    width: 110px !important;
                }
            }
        }
        .tutor-dashboard-detail-card-container {
            margin: auto;
            .card-view {
                max-width: 90%;
                margin-left: 10px !important;
                margin-right: 10px !important;
                margin-bottom: 20px;
            }
        }
    }
}
@media (max-width:576px) {
    .tutor-dashboard-container{
        #right-col{
            padding-left: 0 !important;
        }
    }
}
@media (max-width: 414px) {
    .tutor-dashboard-container {
        .tutor-dashboard-detail-card-container {
            .card-view {
                .tutor-rating-container {
                    .tutor-dashboard-apple-rating {
                        display: none;
                    }
                    .tutor-dashboard-rating-text-counter {
                        margin-top: 92px;
                    }
                }
            }
        }
    }
}
@media (max-width: 390px) {
    .user-panel-calendar .react-calendar__month-view__weekdays__weekday > * {
        font-size: 12px !important;
    }
}
.student-card-view-height{
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
}
.card-view{
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
}