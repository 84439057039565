.looking-for-tutoring-container {
    @extend .d-flex;
    @extend .justify-content-center;
    min-height: 100vh;
}
.looking-for-tutoring-subcontainer {
    width: 70%;
}
@media (max-width: 413px) {
    .looking-for-tutoring-subcontainer {
        width: 90%;
        .button-container {
            position: relative;
        }
    }
}
@media (min-width: 414px) and (max-width: 768px) {
    .looking-for-tutoring-subcontainer {
        width: 90%;
        .button-container {
            position: relative;
        }
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .looking-for-tutoring-subcontainer {
        width: 90%;
    }
}

@media (min-width: 1024px) and (max-width: 1340px) {
    .looking-for-tutoring-subcontainer {
        width: 90%;
    }
}
