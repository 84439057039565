.mobile-title {
    @extend .d-none;
}
.mobile-description {
    @extend .d-none;
}
@mixin navigation-label() {
    font-family: $primary_font_SemiBold;
    color: $white_solid !important;
}
.card-view {
    z-index: 1;
    box-shadow: 0 10px 10px 0 $light_black;
    background-color: $white_solid;
    border-radius: 0 0 25px;
}
.intent-card {
    @extend .d-flex;
    z-index: 1;
    padding: 0 15px;
    .content {
        cursor: pointer;
        width: 330px;
        @extend .text-center;
        img {
            margin: -16px auto 0px;
        }
        .text-container {
            padding: 52px 20px;
            .heading {
                @extend .normal-font-style;
                @extend .text-center;
                font-family: $primary_font_SemiBold;
                font-size: 26px;
                line-height: 1.46;
            }

            .sub-heading {
                @extend .normal-font-style;
                @extend .text-center;
                font-family: $primary_font_Regular;
                font-size: 14px;
                line-height: 1.43;
                color: $battleshipGrey;
                padding: 0 15px
            }
        }
    }
}

.card-style-page-view {
    @extend .d-flex;
    @extend .overflow-hidden;

    .left-side-card-view {
        @extend .position-relative;
        background-image: url(../../assets/images/onboarding-card/rectagle-shape.svg),
            url(../../assets/images/onboarding-card/triangle-shape.svg);
        background-repeat: no-repeat;
        background-position: top 380px left 110px, top 113px right 40px;
        height: 730px;
        display: grid;
        width: 420px;
        background-color: $darkMango;
        z-index: 20;

        &::before {
            @include pseudo-bg;
            width: 420px;
            height: 730px;
            clip-path: circle(115% at 102% 148%);
        }
        .title {
            @extend .normal-font-style;
            @extend .position-relative;
            @extend .d-flex;
            padding: 97px 39px 22px;
            font-family: $primary_font_Medium;
            font-size: 60px;
            font-weight: 500;
            line-height: 1.3;
            color: $white_solid;
        }
        .navigation-description {
            @extend .normal-font-style;
            padding: 25px 39px;
            font-family: $primary_font_Medium;
            font-size: 18px;
            line-height: 1.56;
            color: $white_solid;
        }
        .navigation-details {
            @extend .normal-font-style;
            // width: 169px;
            font-family: $primary_font_Medium;
            font-size: 22px;
            font-weight: 500;
            line-height: 1.43;
            color: $white_solid;
            padding-top: 141px;
            margin-left: 42px;
            padding-bottom: 171px;
            display: block;
            .link {
                @include navigation-label();
             
            }
            u {
                @extend .normal-font-style;
                font-family: $primary_font_Medium;
                font-size: 18px;
                font-weight: 500;
                line-height: 1.43;
                color: $white_solid;
               
                display: block;
            }
        }
    }
    .right-side-card-view {
        z-index: 1;
        background-color: $white_solid;
        padding: 109px 64px 109px 60px;
    }
}

@mixin responsive-right-side-card-view() {
    background: $white_solid;
    padding: 36px 40px !important;
}

@mixin responsive-title() {
    font-family: $primary_font_Medium;
    font-weight: 500;
    font-size: 48px;
    color: $white_solid;
    line-height: 1.17;
    padding-top: 60px;
    padding-bottom: 52px;
    text-align: left;
}

@mixin responsive-description() {
    display: block !important;
    text-align: center;
    margin-top: 37px;
    color: $white_solid;
    font-size: 16px;
    font-family: $primary_font_Regular;
    margin-bottom: 149px;
}

@media (max-width: 413px) {
    .card-style-page-view {
        .left-side-card-view {
            display: none;
        }
        .right-side-card-view {
            @include responsive-right-side-card-view();
        }
    }
    .mobile-title {
        display: block !important;
        .title {
            @include responsive-title();
        }
    }
    .mobile-description {
        @include responsive-description();
        a {
            @include navigation-label();
        }
    }
}
@media (min-width: 414px) and (max-width: 768px) {
    .card-style-page-view {
        .left-side-card-view {
            display: none;
        }
        .right-side-card-view {
            @include responsive-right-side-card-view();
        }
    }
    .mobile-title {
        display: block !important;
        .title {
            @include responsive-title();
        }
    }
    .mobile-description {
        @include responsive-description();
        a {
            @include navigation-label();
        }
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .card-style-page-view {
        .left-side-card-view {
            display: none;
        }
        .right-side-card-view {
            @include responsive-right-side-card-view();
        }
    }
    .mobile-title {
        display: block !important;
        .title {
            @include responsive-title();
            padding: 51px 0px;
            text-align: center;
        }
    }
    .mobile-description {
        @include responsive-description();
        margin-bottom: 268px;
        a {
            @include navigation-label();
        }
    }
}
@media (min-width: 1024px) and (max-width: 1070px) {
    .card-style-page-view {
        .left-side-card-view {
            width: 380px;
        }
    }
}
@media (min-width: 1071px) and (max-width: 1439px) {
}
