
.collegeCounsellingContent {
    display: flex;
    flex-direction: column;
    .text-container {
        margin-bottom: 32px;
        text-align: center;
        .heading {
            font-family: $primary_font_Medium;
            font-size: 60px;
            font-weight: 500;
            line-height: 1.3;
            color: #2d3234;
        }
        .sub-heading {
            font-family: $primary_font_Regular;
            font-size: 20px;
            line-height: 1.56;
            color: #2d3234;
            font-weight: 400;
        }

        button {
            width: 288px;
        }
    }
}
.CollegeCardheading {
    font-family: $primary_font_Medium;
    font-size: 14;
    line-height: 22px;
    font-weight: 500;
    color: $darkMango;
    font-weight: 400;
    text-align: left;
}
.CollegeCard-sub-heading {
    font-family:$primary_font_Medium;
    font-size: 14;
    line-height: 22px;
    color: #2d3234;
    font-weight: 500;
    text-align: left;
    height: 5vh;
}
.grid-lesson-box{
    margin-bottom: 20px !important;
    min-height: 167px;
    max-height: 167px;
    height: 167px;
    display: inline-flex !important;
    flex-grow: 0 !important;
}
.preview {
    cursor: pointer;
    display: flex;
    flex: auto;
    flex-direction: row;
    color: $darkMango;
    z-index: 1;
    margin-right: 30px !important;
    height: auto !important;
    .preview-icon {
        color: $darkMango;
        width: 20px;
        height: 20px;
        z-index: 1;
    }
    .preview-label {
        z-index: 1;
        padding-left: 10px;
        color: $darkMango;
        font-family: $primary_font_Medium;
        font-size: 12px;
        line-height: 20px;
    }
}
.lesson-cards-holder{
    .text-container{
        padding: 20px !important
    }
}
.PreviewCard {
    font-family: $primary_font_Medium;
    font-size: 12px;
    font-weight: 400;
    color: $darkMango;
    font-weight: 400;
    text-align: center;
}
.column {
    float: left;
    width: 50%;
}
.row:after {
    content: "";
    display: table;
    clear: both;
}

.view-more {
    text-decoration: underline;
    color: $darkMango;
    float: right;
    padding-right: 30px;
    .lesson-description {
        position: relative;
        margin-top: 30px;
        p {
            text-align: left;
            font-size: 18px;
            color: #455052;
        }
        ul {
            color: #455052;
            padding-left: 15px;
        }
        pre {
            padding-left: 53px;
            padding-right: 53px;

            code {
                white-space: pre-line;
                word-wrap: break-word;
                text-align: left;
                font-family: $primary_font_Regular;
                font-size: 18px;
                box-sizing: border-box;
                font-weight: 400;
                line-height: 1.5;
                -webkit-text-size-adjust: 100%;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                color: #455052;
                display: block;
                margin-top: 0;
                margin-bottom: 1rem;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
            }
            ::after {
                box-sizing: border-box;
            }
        }
    }
    .modal-sub-wrapper {
        width: 85%;
        height: 85%;
        overflow-y: scroll;
        max-width: 85%;
      
        .modal-wrapper-exit {
            z-index: 1;
        }
        position: relative;
        .modal-button{
            text-align: center;
        }
    }
    .modal-sub-wrapper::after {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 1) 20%,
            rgba(255, 255, 255, 0) 80%
        );
    }
    .lesson-preview-label {
        font-family: $primary_font_Medium;
        font-size: 26px;
        color: $darkMango;
        line-height: 38px;
    }
    .ln-1 {
        margin-bottom: 0px;
    }
    .lesson-preview-title {
        font-family: $primary_font_Regular;
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: left;
        font-size: 38px;
        line-height: 54px;
        color: $darkGrey;
    }
}
.collegeCounseling-card-position {
    position: relative;
}
.collageCounseling-icon-div {
    position: absolute;
    right: 0;
    top: 0;
}
.college-counseling-back-btn{
    position: relative;
    top: 60px;
    left: -2rem;
    display: inline-block;
}

button.view-subscription-options-button {
    font-family: $primary_font_Medium;
    font-size: 16px;
    line-height: 22px;
    width: 300px;
    z-index: 1001;
}
.collageCounseling-cards-tab-view{
    .lesson-cards-holder{
        @media (min-width:1260px) {
            padding-left: 4rem;
        }
        @media (min-width:1177px) and (max-width:1260px){
            padding-left: 0.5rem;
        }
        @media (max-width:767px) {
            justify-content: center;
        }
    }
    @media (min-width:1280px){
      max-width: 1280px !important;
    }
    @media (min-width: 1025px) and (max-width:1280px){
        max-width: 1060px !important;
    }
}
@media (max-width: 768.95px) {
    .view-more {
        text-decoration: underline;
        color: $darkMango;
        float: right;
        padding-right: 30px;

        .lesson-description {
            position: relative;
            margin-top: 30px;
            p {
                text-align: left;
                font-size: 14px;
                color: #455052;
            }
            ul {
                padding-left: 30px;
            }
            pre {
                padding-left: 0px;
                padding-right: 0px;

                code {
                    white-space: pre-line;
                    word-wrap: break-word;
                    text-align: left;
                    font-family: $primary_font_Regular;
                    font-size: 14px;
                    box-sizing: border-box;
                    font-weight: 400;
                    line-height: 1.5;
                    -webkit-text-size-adjust: 100%;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    color: #455052;
                    display: block;
                    margin-top: 0;
                    margin-bottom: 1rem;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                }
                ::after {
                    box-sizing: border-box;
                }
            }
        }
        .modal-sub-wrapper {
            width: 85%;
            height: 85%;
            overflow-y: scroll;
            p {
                padding-left: 0px;
                padding-right: 0px;
            }
            .modal-wrapper-exit {
                z-index: 1;
            }
            position: relative;
        }
        .modal-sub-wrapper::after {
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-image: linear-gradient(
                to top,
                rgba(255, 255, 255, 1) 20%,
                rgba(255, 255, 255, 0) 80%
            );
        }
        .lesson-preview-label {
            font-family: $primary_font_Medium;
            font-size: 22px;
            color: $darkMango;
            line-height: 38px;
        }
        .ln-1 {
            margin-bottom: 0px;
        }
        .lesson-preview-title {
            font-family: $primary_font_Regular;
            margin-top: 0px;
            margin-bottom: 0px;
            text-align: left;
            font-size: 28px;
            line-height: 34px;
            color: $darkGrey;
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .collageCounseling-cards-tab-view {
        display: contents !important;
    }
}

@media (max-width:1024px) {
    .collegeCounsellingContent{
        .text-container{
            .heading{
                font-size: 48px;
            }
        }
    }
    .college-counseling-back-btn{
        left: -10px;
    }
}
@media (max-width:767px) {
    .collegeCounsellingContent{
        padding: 50px 0 30px;
        .text-container{
            .heading{
                font-size: 40px;
            }
        }
    }
    .college-counseling-back-btn{
        top: 0;
        left: 0;
    }
}
@media (max-width: 576px) {
    .view-more{
        .modal-sub-wrapper {
            padding-top: 50px !important;
            width: 100% !important;
            max-width: 100% !important;
        }
    }
    .collageCounseling-cards-tab-view {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 414.95px) {
    .view-more {
        .modal-sub-wrapper {
            max-height: 100%;
            height: 100%;
            border-radius: 0px;
            margin-top: 0px;
        }
    }
    button.view-subscription-options-button {
        width: 230px;
    }
}
@media (max-width:340px) {
    .collegeCounsellingContent{
        .text-container{
            button{
                width: 200px;
            }
        }
    }
    .grid-lesson-box{
        .intent-card{
            .content{
                width: 295px;
            }
        }
    }
}
