$battleshipGrey: #686c80;
$darkMango: #f28334;
$cancelRed:#ff5924;
.model_singleslotbookingrequested{
    max-width: 450px;
    width: 100%;
    margin: auto;
    .imgs_div{
        text-align: center;
        padding-bottom: 15px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 45%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: #686c80;
        font-size: 26px;
        line-height: 46px;
        text-align: left;
        margin-bottom: 10px;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
    }
    p{
        color: #686c80;
        font-size: 16px;
        line-height: 22px;
        text-align: left;
    }
    button{
        background-color: $darkMango;
        color: #fff;
        border-radius: 26.3px;
        border: solid 2px $darkMango;
        padding: 13px 20px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        text-align: center;
        margin: 20px auto;
        display: block;
        &:hover{
            background-color: #fff;
            color: $darkMango;
            border: solid 2px $darkMango;   
        }
    }
 

}

.model_tutoracceptrequest{
    max-width: 450px;
    width: 100%;
    margin: auto;
    text-align: center;
    .imgs_div{
        text-align: center;
        padding-bottom: 1rem;
        width: 90%;
        margin: auto;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 45%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: $battleshipGrey;
        font-size: 30px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 0px;
        font-family: "Montserrat-Medium";
    }
    h4{
        color: $battleshipGrey;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 0px;
        font-family: "Montserrat-Medium";
    }
    p{
        color: #686c80;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        margin-top: 10px;
        font-family: "Montserrat-Regular";
    }
    button{
        background-color: $darkMango;
        color: #fff;
        border-radius: 26.3px;
        border: solid 2px $darkMango;
        padding: 13px 20px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        text-align: center;
        margin: 0px auto;
        display: block;
        &:hover{
            background-color: #fff;
            color: $darkMango;
            border: solid 2px $darkMango;   
        }
    }
 

}
.model_tutorrejectsession{
    .imgs_div{
        text-align: center;
        max-width: 450px;
        margin: auto;
        padding-bottom: 15px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: #686c80;
        font-size: 26px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }
    .tutor_reject_session{
        text-align: center;
        margin-top: 35px;
        padding-bottom: 35px;
        button{
            background-color: #fff;
            color: $darkMango;
            border-radius: 26.3px;
            border: solid 2px $darkMango;
            padding: 13px 20px;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            text-align: center;
            margin: 20px auto;
            display: inline-block;
            margin: 0px 10px;
            width: 230px;
            &:hover{
                background-color: $darkMango;
                color: #fff;
                border: solid 2px $darkMango;   
            }
        }
    }
}

.model_std_when_tutor_accept_Request{
    font-family: "Montserrat-Medium";
    .imgs_div{
        padding-bottom: 15px;
        img{
            max-width: 100%;
            margin: 20px auto !important;
            display: block;
            width: 120px;
            height: 120px;
            border-radius: 50%;
        }
    }
    h2{
        font-size: 26px;
        color: $battleshipGrey;
        text-align: center;    }
    h3{
        svg{
            margin-right: 10px;
            vertical-align: middle;
        }
        font-size: 16px;
        text-align: center;
        padding: 20px 23px;
        background-color: $darkMango;
        color: #fff;
        margin: 20px 0;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }
    h4{
        font-size: 18px;
        line-height: 28px;
        color: #686c80;
        text-align: center;
    } 
    .form-group{
            font-family: "Montserrat-Regular";
            position: relative;
            cursor: pointer;
            line-height: 22px;
            font-size: 16px;
            margin: 20px;
            margin-left: 50px;
            color: $battleshipGrey;
            // .label{
            //     position: relative;
            //     display: block;
            //     float: left;
            //     margin-right: 10px;
            //     width: 20px;
            //     height: 20px;
            //     border: 2px solid #e4e4ec;
            //     border-radius: 100%;

            // }
  
        
    }
        input[type="radio"]:checked + .label{
            border-color: $darkMango;
            &:after{
                transform: scale(1);
                transition: all .2s cubic-bezier(.35,.9,.4,.9);
                opacity: 1;
                }
        }  
        .hidden{
            display: none;
        }
        align-items: center;
        align-content: center;
        display: flex;
        flex: 0 1 50%;
        flex-direction: column;
        // margin-top: 50px;
        button{
            &:hover{
                background-color: $darkMango;
                color: #fff;
                border: solid 2px $darkMango;
            }
        }
}
.reschedule-modal{
   
    .reschedule-time-modal{
    .imgs_div{
           padding-bottom: 2.5rem;
           width: 70%;
           margin: auto;
           @media (max-width:576px) {
               width: 100%;            
           }
       }
       .avatars{
           width: 100%;
           &:after{
               content: "";
               display: block;
               position: absolute;
               width: 50%;
               height: 1px;
               border-bottom: 3px dotted #262ffa;
               top: 50%;
               z-index: 10;
               margin: auto;
               text-align: center;
               left: 0;
               right: 0;
           }
           .img-rounded{
               border: none;
               padding: 0;
               margin: 0 !important;
               height: 100px;
               width: 100px;
               @media (max-width:768px) {
                height: 80px !important;
                width: 80px !important;
               }
           }
       }
       h2{
           color: $battleshipGrey;
           font-size: 30px;
           line-height: 46px;
           text-align: center;
           margin-bottom: 10px;
           font-family: "Montserrat-Medium";
       }
       h4{
           color: $battleshipGrey;
           font-size: 18px;
           line-height: 28px;
           text-align: center;
       }
       p{
           text-align: center;
           font-size: 16px;
       }
       ul{
           clear: both;
           li{
               margin: 15px 30px;
               padding: 0px 0px;
               font-size: 16px;
               color: $battleshipGrey;
               list-style: none;
               line-height: 28px;
               font-family: "Montserrat-Regular";
               // text-align: center;
               svg{
                   vertical-align: bottom;
                   margin-right: 10px;
               }
               &:first-child, &:nth-child(3){
                   svg{
                       color: #36d174; 
                   }
               }
               &:nth-child(2){
                   svg{
                       color: #ef5350;
                   }
               }
           }
       }
       .btn_stdwhentutoracceptsomeofmyreuests{
           align-items: center;
           align-content: center;
           display: flex;
           flex: 0 1 50%;
           flex-direction: column;
           margin-top: 25px;
           button{
               &:hover{
                   background-color: $darkMango;
                   color: #fff;
                   border: solid 2px $darkMango;
               }
           }
       }
   }
   .MuiDialog-paper{
    padding: 50px 50px 20px;
   }
   @media (max-width:768px) {
       .MuiDialog-paper {
            max-height: calc(100% - 100px);
            overflow-y: scroll;
            margin-left: 0;
            margin-right: 0;
            padding: 50px 30px 70px;
            margin-top: 100px;
        }
        .reschedule-time-modal{
            h2{
                font-size: 26px;
            }
            p{
                font-size: 14px;
            }
        }
        .reschedule-time-selector-input.time{
            margin-left: 0;
        }
            .MuiMenuItem-dense{
                padding: 0.5rem;
            }
   }

}
.model_stdwhentutoracceptsomeofmyreuests{
    .imgs_div{
        padding-bottom: 2.5rem;
        width: 70%;
        margin: auto;
        @media (max-width:576px) {
            width: 100%;            
        }
    }
    .avatars{
        width: 100%;
        &:after{
            content: "";
            display: block;
            position: absolute;
            width: 50%;
            height: 1px;
            border-bottom: 3px dotted #262ffa;
            top: 50%;
            z-index: 10;
            margin: auto;
            text-align: center;
            left: 0;
            right: 0;
        }
        .img-rounded{
            border: none;
            padding: 0;
            margin: 0 !important;
        }
    }
    h2{
        color: #686c80;
        font-size: 26px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
        font-family: "Montserrat-Medium";
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }  
    ul{
        clear: both;
        li{
            margin: 15px 30px;
            padding: 0px 0px;
            font-size: 16px;
            color: #686c80;
            list-style: none;
            line-height: 28px;
            font-family: "Montserrat-Regular";
            // text-align: center;
            svg{
                vertical-align: bottom;
                margin-right: 10px;
            }
            &:first-child, &:nth-child(3){
                svg{
                    color: #36d174; 
                }
            }
            &:nth-child(2){
                svg{
                    color: #ef5350;
                }
            }
        }
    }
    .btn_stdwhentutoracceptsomeofmyreuests{
        align-items: center;
        align-content: center;
        display: flex;
        flex: 0 1 50%;
        flex-direction: column;
        margin-top: 25px;
        button{
            &:hover{
                background-color: $darkMango;
                color: #fff;
                border: solid 2px $darkMango;
            }
        }
    }
    

}
.model_stddoestnotprocessmurequest{
    .imgs_div{
        max-width: 450px;
        margin: auto;
        padding-bottom: 15px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }  
    }   
    h2{
        color: #686c80;
        font-size: 26px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        max-width: 350px;
        margin: auto;
    }
    .tutor_reject_session {
        text-align: center;
        margin-top: 30px;
        .button{
            vertical-align: middle;
            button{
                background-color: #fff;
                color: $darkMango;
                border-radius: 26.3px;
                border: solid 2px $darkMango;
                padding: 13px 20px;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                text-align: center;
                margin: 20px auto;
                display: inline-block;
                margin: 0px 10px;
                width: auto;
                &:hover{
                    background-color: $darkMango;
                    color: #fff;
                    border: solid 2px $darkMango;
                }
            }
        }
    }  
}
.tutor-student-reschedule-modal{
    .modal-sub-wrapper{
        max-width: 790px;
        width: 790px;
        display: none;
    }
}
.modal-tutor-reject-reschedule{
    max-width: 900px;
    .imgs_div{
        text-align: center;
        max-width: 450px;
        margin: auto;
        padding-bottom: 15px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: #686c80;
        font-size: 30px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }
    .tutor-reject-reschedule-btn{
        text-align: center;
        margin-top: 35px;
        padding-bottom: 35px;
        button{
            color: #fff;
            border-radius: 26.3px;
            padding: 13px 20px;
            font-size: 19px;
            font-weight: 500;
            font-stretch: normal;
            text-align: center;
            margin: 20px auto;
            display: inline-block;
            margin: 0px 10px;
            width: 300px;
            &:hover{
                // background-color: #f9a722;
                // color: #fff;
                // border: solid 2px #f9a722;   
            }
            @media (max-width:390px) {
                width: 250px;
                font-size: 16px;;
            }
        }
    }
    @media (max-width:768px) {
        h2{
            font-size: 26px;
        }
        h4{
            font-size:16px
        }
    }
}
.modal-tutor-accept-rescheduled-request{
    max-width: 900px;
    
    .imgs_div{
        text-align: center;
        max-width: 450px;
        margin: auto;
        padding-bottom: 25px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: #686c80;
        font-size: 30px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }
    .tutor-reject-reschedule-btn{
        text-align: center;
        margin-top: 35px;
        padding-bottom: 35px;
        button{
            color: #fff;
            border-radius: 26.3px;
            padding: 13px 20px;
            font-size: 19px;
            font-weight: 500;
            font-stretch: normal;
            text-align: center;
            margin: 20px auto;
            display: inline-block;
            margin: 0px 10px;
            width: 200px;
            &:hover{
                // background-color: #f9a722;
                // color: #fff;
                // border: solid 2px #f9a722;   
            }
        }
    }
    @media (max-width:768px) {
        h2{
            font-size: 26px;
        }
        h4{
            font-size: 16px;
        }
    }
}
.modal-teacher-not-confirm-reschedule-req{
    max-width: 900px;
    .imgs_div{
        text-align: center;
        max-width: 450px;
        margin: auto;
        padding-bottom: 15px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: #686c80;
        font-size: 26px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
    }
    h4.cancel-reschedule-msg{
        color: $cancelRed;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }
    .tutor-reject-reschedule-btn{
        text-align: center;
        padding: 10px 0;
        button{
            color: #fff;
            border-radius: 26.3px;
            padding: 13px 20px;
            font-size: 19px;
            font-weight: 500;
            font-stretch: normal;
            text-align: center;
            margin: 20px auto;
            display: inline-block;
            margin: 0px 10px;
            width: 300px;
            background-color: #262ffa;
            &:hover{
                // background-color: #f9a722;
                // color: #fff;
                // border: solid 2px #f9a722;   
            }
            @media (max-width:390px) {
                width: 250px;
                font-size: 16px;;
            }
        }
    }
}
.modal-reschedule-request-sent{
    max-width: 900px;
    .imgs_div{
        text-align: center;
        max-width: 450px;
        margin: auto;
        padding-bottom: 15px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 145px;
            height: 145px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: #686c80;
        font-size: 30px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }
    .tutor-student-reschedule-btn{
        text-align: center;
        margin-top: 35px;
        padding-bottom: 35px;
        button{
            color: #fff;
            border-radius: 26.3px;
            font-size: 19px;
            font-weight: 500;
            font-stretch: normal;
            text-align: center;
            margin: 20px auto;
            display: inline-block;
            margin: 0px 10px;
            width: 230px;
            background-color: #262ffa;
            &:hover{
                // background-color: #f9a722;
                // color: #fff;
                // border: solid 2px #f9a722;   
            }
            @media (max-width:390px) {
                width: 250px;
                font-size: 16px;;
            }
        }
    }
    @media (max-width:768px) {

        h2{
            font-size: 26px;
        }
        h4{
            font-size: 16px;
        }
        .img_icons{
            height: 120px;
            width: 120px;
            @media (max-width:414px) {
                height: 102px;
                width: 102px;
            }
        }
    }
}
@media (max-width:576px) {
    .model_stdwhentutoracceptsomeofmyreuests{
        ul{
            padding-left: 0;
            li{
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }
}
.accepted-requests-modal{
    .modal-sub-wrapper{
        max-width: 692px !important;
        width: 692px !important;
        @media (max-width:576px) {
            max-width: 100% !important;
            width: 100% !important;
        }
    }
}

@media(max-width: 767px){
    .model_tutoracceptrequest{
        h2{
            color: $battleshipGrey;
            font-size: 25px;
            line-height: 46px;
            text-align: center;
            margin-bottom: 0px;
            font-family: "Montserrat-Medium";
        }
        h4{
            font-size: 16px;
            margin-bottom: 0px;
        }
        p {
            margin-top: 10px;
        }
    }
}
@media screen and (max-width: 800px) {
    .outlined-button-bottom-margin{
        margin-bottom: 15px !important;
    }
}
.search-tutor-p{
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: $battleshipGrey;
}
.session-time-selector-input input{
    color: $battleshipGrey;
}
.session-time-selector-input .MuiSvgIcon-root{
    fill: #262ffa;
}
.session-time-selector-wrapper.reschedule-session-time-selector-wrapper{
    margin-bottom: 0px;
}