.custom-modal-payment {
    .privacy-modal {
        text-align: left;
    }
    .header {
        margin-left: 0.75em;
    }
    .close-modal-button {
        text-align: end;
        color: $darkMango;
        svg {
            cursor: pointer;
        }
        &.gray-bg {
            margin: 25px;
        }
    }
    .button button {
        width: auto;
        padding: 5px 30px;
    }
    .custom-modal-payment-body {
        overflow-y: auto;
        display: flex;
        height: 100%;
        // width: 100%;
        .custom-modal-content-payment {
            margin: auto;
            padding: 25px;
            width: 75vw;
            height: auto;
            background: white;
            border-radius: 25px 0px;
            .header {
                text-align: left;
                .password-section {
                    .input-label-p {
                        font-family: Montserrat-Medium !important;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}
.privacy-modal p {
    padding: 0px 0px !important;
    .chargePolicy{

        font-size: 0.75rem;
        padding-left: 1.5% !important;
        .chargePolicy2{
        padding-left: 1.5% !important;        
        }
    }
    .tcdCut{
        color :darkgray !important;
        font-size: 0.75rem !important;
    }
}
.chargePolicy{

    font-size: 0.75rem !important;
    padding-left: 1.5% !important ;
}
.tcdCut{
    color :darkgray !important;
    font-size: 0.75rem !important;
}
@media (max-height: 900px) {
    .resume-booking {
        .gray-section {
            .tcd-footer {
                margin-top: 10rem;
                .button {
                    margin-top: 12px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .custom-modal-payment-body {
        .custom-modal-content-payment {
            width: 90vw !important;
        }
    }
    .resume-booking {
        h2 {
            font-size: 30px;
        }
        .white-section {
            padding: 25px 15px !important;
            .when {
                .availability {
                    text-align: left;
                }
            }
        }
        .gray-section {
            font-size: 1.5rem;
            padding: 25px;
            .tcd-footer {
                margin-top: 10%;
                .tcd-p {
                    padding-left: 5px;
                    text-align: left;
                }
            }
        }
    }
}
@media (max-width: 1081px) and(max-height :850px) {
    .resume-booking {
        .white-section {
            padding: 0px 0px;
            .when {
                .availability {
                    text-align: left;
                }
            }
        }
        .gray-section {
            font-size: 1.5rem;
            .tcd-footer {
                margin-top: 10%;
                .tcd-p {
                    padding-left: 5px;
                    text-align: left;
                }
            }
        }
    }
}
@media (max-width: 768px){
    .resume-booking {
        h2 {
            text-align: center;
        }
    }
}
