
.subscription-options {
    text-align: center;
    justify-content: center;
    max-width: max-content;
    margin: auto;
    @media (min-width: 992px) and (max-width: 1399.5px){
        max-width: 890px;
    }
    @media (max-width:768px) {
        padding: 0 30px;
        margin-top: -5px;
    }
    .subscription-head {
        font-family: $primary_font_Medium;
        color: $darkGrey;
        font-size: 38px;
        line-height: 78px;
        font-weight: bold;
        @media (min-width: 1024px) and (max-width: 1399.5px) {
            margin-bottom: 5px;
            font-size: 30px;
        }
        @media (max-width: 767.5px) {
            font-size: 170% !important;
        }
    }
    .subscription-subhead {
        font-family: $primary_font_Regular;
        color: $battleshipGrey;
        font-size: 18px;
        line-height: 28px;
        margin-top: 2rem;
        font-weight: 600;
        @media (min-width: 1024px) and (max-width: 1399.5px) {
            font-size: 14px;
            margin-bottom: -6px;
            margin-top: -12px !important;
        }
        @media (min-width: 1400px) {
            font-size: 17px;
            margin-bottom: 0px;
        }
    }
    .subscription-subhead-last {
        font-family: $primary_font_Regular;
        color: $battleshipGrey;
        font-size: 18px;
        line-height: 28px;
        margin-top: 2rem;
        @media (min-width: 1024px) and (max-width: 1399.5px) {
            font-size: 16px;
            margin-bottom: -6px;
            margin-top: 1.5% !important;
        }
        @media (min-width: 1400px) {
            font-size: 17px;
            margin-bottom: 0px;
        }
        @media (max-width: 768px) {
            margin-top: 0;
        }
    }
    .subscription-subhead-left {
        text-align: left;
        @media (max-width: 767.5px){
            text-align: center;
            margin-right: 2%;
            margin-left: 2%;
        }
        @media (min-width: 768px) and (max-width: 1023.5px){
            margin-left: 2%;
        }
    }
    .right-name {
        float: right;
        margin-right: 2%;
        font-family: OpenSans-SemiBoldItalic;
        margin-bottom: 10%;
    }
    .view-lesson-button {
        width: 160px;
        height: 37px;
    }
    .subscription-option-cards > * {
        overflow: visible;
        flex-wrap: wrap;
        align-content: center;
        display: inline-flex; //use this for subscrtiption
        margin: 15px;
    }
    .subscription-option-cards {
        max-width: 780px;
        margin: auto;
        font-family: $primary_font_Regular;
        margin-bottom: 50px;
        @media(min-width: 1400px) {
            margin-bottom: 25px;
        }
        .subscription-card-holder{
            padding: 10px 0 0;
        }
        .card-view {
            width: 360px;
            padding: 40px;
            padding-top: 10px;
            height: 330px;
            @media (min-width: 768px) and (max-width: 1399.9px) {
                height: 310px;
                width: 360px;
                padding: 32px;
            }
            @media (max-width:768px) {
                width: 100%;
                padding: 26px;
                height: 280px;
            }
            @media (max-width:360px) {
                height: auto;
            }
            .subscription-card-title {
                font-family: $primary_font_Regular;
                color: $darkGrey;
                font-size: 26px;
                margin-bottom: 35px;
                @media (min-width: 1024px) and (max-width: 1399.95px) {
                    font-size: 20.8px;
                    letter-spacing: normal;
                }
                @media (min-width: 768px) and (max-width: 1023.95px) {
                    font-size: 16px;
                }
            }
            .subscription-card-price {
                color: $darkMango;
                font-weight: 600;
                font-size: 30px;
                margin-top: -30px;
                @media (min-width: 768px) and (max-width: 1023.95px) {
                    font-size: 24px;
                }
                @media (min-width: 1024px) and (max-width: 1200px) {
                    font-size: 24px;
                }
            }
            .subscription-card-text {
                font-size: 17px;
                color: $battleshipGrey;
                font-weight: normal;
                @media (min-width: 320px) and (max-width: 1440px) {
                    font-size: 13.6px;
                }
            }
            position: relative;
            button {
                position: absolute;
                height: 38px;
                right: 28%;
                width: 140px;
                bottom: -19px;
                @media (min-width: 321px) and (max-width: 767.95px) {
                    right: 29%
                }
                @media (min-width: 768px) and (max-width: 1023.95px) {
                    right: 18%
                }
                @media (min-width: 1024px) and (max-width: 1200px) {
                    right: 25%;
                }
            }
        }
    }
    @media (max-width: 1023.95px) {
        .subscription-head {
            font-size: 2.5rem;
        }
        .subscription-subhead {
            margin-left: 74px;
            margin-right: 74px;
        }
    }
    
    @media (max-width: 767.95px) {
        .subscription-head {
            font-size: 2rem;
            margin-top: 25px;
        }
        .subscription-subhead {
            margin-left: 74px;
            margin-right: 74px;
        }
    }
    
    @media (max-width: 414.95px) {
        .subscription-head {
            font-size: 2rem;
            line-height: 1.5;
            margin: 5px 5px 10px;
        }
        .subscription-subhead {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
.top-space {
    margin-top: 1%;
    @media(min-width: 1400px) {
        margin-top: 0.5%;
    }
}
.top-space-extra{
    margin-top: 0.5%;
    @media(min-width: 1400px) {
        margin-top: 0.5%;
    }
    @media(min-width: 768px) and (max-width: 1023.5px) {
        margin-top: 3%;
    }
}
.bottom-space{
    margin-bottom: 2.5%;
    @media (max-width: 320px) {
        margin-bottom: 5%;
    }
    @media (min-width: 321px) and (max-width: 767px) {
        margin-bottom: 4%;
    }
}
.book-back-button-left {
    @media(min-width: 1400px) {
        position: relative;
        left: -250%;
    }
}

@media (max-width:768px){
    .subscription-option-cards{
        display: flex;
        flex-direction: column;
    }  
}
.subscription-subhead-no-bottom.subscription-quote{
    padding: 0 41px;
    @media (max-width:768px) {
        text-align: start;
        padding: 0;
       
    }
}
.subscription-options-onBoarding .subscription-options .subscription-subhead.subscription-quote{
    padding: 0 41px;
    @media (max-width: 1023.95px){
        margin: 0;
    }
    @media (max-width:768px) {
        margin: 0;
    text-align: start;
    padding: 0;
    }
}
.subscription-options .subscription-option-cards .card-view .subscription-card-title{
    font-size: 26px;
    @media (max-width: 1200px) {
        font-size: 20.8px;
    }
}