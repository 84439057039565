.sign-up {
    @extend .d-block;
    @extend .position-relative;
    min-height: 100vh;
    padding-left: 20px;
    padding-right: 20px;
    @extend .h-auto;

    .sign-up-continer {
        margin: 0 auto;
        width: 623px;

        .signup-back-btn {
            margin-top: 20px;

            @media (min-width: 768px) {
                margin-left: -45px;
            }
        }

        .title {
            font-size: 60px;
            padding-top: 75px;
            line-height: 74px;
            @extend .text-center;
            color: $darkGrey;
        }

        .sub-title {
            font-size: 18px;
            padding-top: 16px;
            line-height: 28px;
            @extend .text-center;
            color: $battleshipGrey;
        }

        .sign-up-form {
            display: grid;
            padding: 32px 0;
            margin: 0 auto;

            .phone-number-wrapper {
                padding-bottom: 24.5px;

                .phone-number {
                    box-shadow: 0 10px 10px 0 #0000000d;
                    width: 100%;
                    border-radius: 10px;
                    background-color: $white_solid;

                    input {
                        -webkit-user-select: all !important;
                    }

                    fieldset {
                        display: none;
                    }
                }
            }

            .first-line {
                @extend .d-flex;

                @media (max-width: 768px) {
                    flex-direction: column !important;
                }

                :last-child {
                    margin-left: 5px;

                    @media (max-width: 768px) {
                        margin-left: 0;
                    }
                }
            }

            .button {
                @extend .text-center;
                margin-top: 59px;
            }
            .subscription-optins.custom-check {
                .container {
                    font-family: $primary_font_Bold;
                }
            }
        }

        .other-registration {
            @extend .overflow-hidden;
            @extend .text-center;
            color: $battleshipGrey;
        }

        .other-registration:before,
        .other-registration:after {
            @extend .d-inline-block;
            background-color: $paleGrey;
            content: "";
            height: 1px;
            @extend .position-relative;
            @extend .align-middle;
            width: 50%;
        }

        .other-registration:before {
            right: 0.5em;
            margin-left: -50%;
        }

        .other-registration:after {
            left: 0.5em;
            margin-right: -50%;
        }

        .sign-up-options {
            @extend .text-center;
            @extend .position-relative;
            margin-top: 29px;
            margin-bottom: 29px;

            img {
                margin: 0px 17px;
            }
        }
    }

    .title-container {
        max-width: 750px;
        margin: auto;

        .signup-title {
            padding-top: 20px;
            font-size: 26px;
            text-align: center;
            font-weight: 600;
            margin: auto;

            @media (min-width: 768px) {
                padding-top: 75px;
                font-size: 30px;
            }
        }

        .inspired-signup-title {
            padding-top: 10px;
        }

        .signup-subtitle {
            font-family: $primary_font_SemiBold;
            text-align: center;
            font-size: 18px;

            .promo-code {
                color: $vividBlue;
            }
        }
    }

    .inspired-logo-bar {
        max-width: 900px;
        margin: auto;
        margin-top: 30px;

        img {
            width: 160px;
            height: 115px;
            object-fit: contain;
        }

        .inspired-logo {
            margin-left: auto;
        }

        @media (max-width: 414px) {
            margin-top: 10px;
        }
    }

    .switch-login-link {
        text-align: center !important;
        font-family: $primary_font_SemiBold;

        u {
            color: $darkMango;
            cursor: pointer;
        }
    }

    @media (max-width: 413px) {
        .first-line {
            display: flex;
            flex-direction: column;
        }

        .sign-up-continer {
            width: 100%;
            padding: 20px 10px;

            .title {
                padding-top: 40px;
                font-size: 40px;
            }

            .sub-title {
                font-size: 14px;
                padding-top: 12px;
            }
        }

        .input-label {
            font-size: 15px;
        }

        .custom-input .input-container {
            input::placeholder {
                font-size: 15px;
            }
        }
    }

    @media (min-width: 414px) and (max-width: 768px) {
        .sign-up-continer {
            width: 380px;
            padding: 20px 10px;

            .title {
                font-size: 40px;
            }

            .sub-title {
                font-size: 14px;
                padding-top: 12px;
            }
        }

        .input-label {
            font-size: 15px;
        }

        .custom-input .input-container {
            input::placeholder {
                font-size: 15px;
            }
        }
    }
}

a[href="/reset-password"] {
    color: $darkGrey;
}

@media (max-width: 767px) {
    .sign-up-continer {
        .signup-back-btn {
            margin-top: 25px !important;
        }

        .title {
            padding-top: 25px !important;
        }
    }
}
