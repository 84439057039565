.no-results-card-container {
    width: 100%;
    position: relative;
    .no-results-card {
        padding-top: 78px;
        padding-left: 40px;
        padding-bottom: 77px;
        .title-text {
            font-family: $primary_font_Medium;
            font-size: 20px;
            line-height: 28px;
        }
        .subtitle-text {
            font-family: $primary_font_Regular;
            font-size: 14px;
            line-height: 20px;
            color: $battleshipGrey;

            span {
                display: block;
                font-size: 20px;
                line-height: 28px;
                margin-top: 15px;
								color: $black_solid;
								text-align: center;
								padding-left: -15px;
            }
        }
    }
    button {
        position: absolute;
        bottom: 0px;
        right: 40px;
    }
}

.no-results-card.lessons {
	padding-top: 0;
	padding-left: 15px;
	padding-bottom: 0;
	margin-bottom: -17px;
}