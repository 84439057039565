.stripe-connect-country-selection-modals {
    .modal-wrapper {
        z-index: 999 !important;
    }
    max-width: 450px;
    width: 100%;
    margin: auto;
    .imgs_div{
        text-align: center;
        padding-bottom: 1rem;
        width: 90%;
        margin: auto;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 45%;
                height: 1px;
                border-bottom: 3px dotted $vividBlue;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: $battleshipGrey;
        font-size: 30px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
        font-family: $primary_font_Medium;
        font-weight: 600;
    }
    p{
        color: $battleshipGrey;
        text-align: center;
        font-family: $primary_font_Regular;
        font-size: 16px;
        line-height: 22px;
    }
    .action-items {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        button{
            background-color: $darkMango;
            color: #fff;
            border-radius: 26.3px;
            border: solid 2px $darkMango;
            padding: 13px 20px;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            text-align: center;
            margin: 10px auto 0 auto;
            display: block;
            &:hover{
                background-color: #fff;
                color: $darkMango;
                border: solid 2px $darkMango;   
            }
        }
        button.outlined{
            background-color: #ffffff;
            color: $darkMango;
        }
    }
}

.join-session-modals{
    .modal-sub-wrapper{
        max-width: 692px !important;
        width: 692px !important;
        @media (max-width:576px) {
            max-width: 100% !important;
            width: 100% !important;
            .action-items {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

@media(max-width: 767px){
    .model_join_session {
        h2{
            font-size: 25px;
            margin-bottom: 0px;
        }
        .action-items {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            button{
                background-color: $darkMango;
                color: #fff;
                border-radius: 26.3px;
                border: solid 2px $darkMango;
                padding: 13px 20px;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                text-align: center;
                margin: 10px auto 0 auto;
                display: block;
                &:hover{
                    background-color: #fff;
                    color: $darkMango;
                    border: solid 2px $darkMango;   
                }
            }
            button.outlined{
                background-color: #ffffff;
                color: $darkMango;
            }
        }
    }
}