.option-screen-component {
    .multiple-cards {
        @extend .d-flex;
        @extend .justify-content-center;
        margin: 10px 0;
        @media (max-width:992px){
            margin: 40px 0;
            display: block !important;
            .card-view{
                margin: 20px auto
            }
        }
    }
    .navigation {
        @extend .normal-font-style;
        
        margin: auto;
    display: table;
        font-family: $primary_font_Medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        color: $darkGrey;
        u {
            cursor: pointer;
        }
    }
}
.onbaording-navigate-btn-container{
    text-align: center;
    .navigation-button{
        position:relative;
        top: 24px;
    }
}
@media (max-width:992px) {
    .onbaording-navigate-btn-container{
        display: none;
    }

}
