.tutor-profile {
    min-height: 100vh;
    margin: 0;
    .margin-b-54 {
        margin-bottom: 54px;
    }
    .margin-b-17 {
        margin-bottom: 17px;
    }
    .margin-b-10 {
        margin-bottom: 10px;
    }
    .profile-left-block {
        box-shadow: 14px 16px 19px -3px rgba(0, 0, 0, 0.07);
        padding: 0;
        @media (max-width: 768px) {
            flex: auto;
            max-width: 100%;
            box-shadow: none;
        }
        button.book-back-button {
            top: 44px;
            left: 80%;
            @media (max-width: 768px) {
                left: 30px;
            }
            @media (max-width: 340px) {
                left: 20px;
            }
        }
    }
    .border-t-none {
        border-top: none;
    }
    .padding-l-0 {
        padding-left: 0;
    }
    .padding-r-0 {
        padding-right: 0;
    }
    .user-block {
        height: 368px;
        background-color: #f4f5f6;
        display: flex;
        align-items: center;
        .user-block-inner-div {
            display: flex;
            align-items: center;
            padding-left: 2rem;
            text-transform: capitalize;
            .content {
                .label {
                    font-family: $primary_font_SemiBold;
                    font-size: 32px;
                    font-weight: 500;
                }
            }
            @media (min-width: 769px) and (max-width: 1023px) {
                display: block;
                position: relative;
                top: 20px;
            }
            @media (max-width: 769px) {
                .content {
                    .label {
                        font-size: 38px;
                    }
                }
            }
            @media (min-width: 576px) and (max-width: 768px) {
                padding-left: 90px;
                .content {
                    .label {
                        font-size: 33px;
                    }
                }
            }
            @media (min-width: 340px) and (max-width: 576px) {
                padding-left: 30px;
                .content {
                    .label {
                        font-size: 33px;
                    }
                }
            }
            @media (max-width: 340px) {
                padding-left: 20px;
                .content {
                    .label {
                        font-size: 33px;
                    }
                }
            }
        }
        img {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            border: 5px solid $darkMango;
            @media (max-width: 340px) {
                width: 145px;
                height: 145px;
            }
        }
    }
    .caption-block {
        height: 235px;
        background-color: #f4f5f6;
        margin-top: -235px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .content {
            margin-left: 43px;
            margin-bottom: 10px;
            font-family: $primary_font_Medium;
            font-size: 38px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: normal;
            color: $white_solid;
            @media (max-width: 768px) {
                margin-left: 30px;
                margin-bottom: 20px;
            }
            .label {
                font-size: 38px;
                overflow-wrap: break-word;
                @media (max-width: 768px) and (min-width: 576px) {
                    width: calc(100% - 310px);
                }
                @media (max-width: 768px) {
                    font-size: 24px;
                }
                @media (max-width: 576px) {
                    width: calc(100% - 100px);
                }
            }
            .icons {
                font-size: 18px;
                width: calc(100% - 260px);
                max-width: 175px;
            }
        }
    }
    .rating-container {
        @media (max-width: 992px) {
            justify-content: left;
        }
        .rating-icon {
            @media (max-width: 992px) {
                margin-right: 0.3rem;
            }
            svg {
                @media (max-width: 768px) {
                    width: 16px;
                    height: auto;
                }
            }
        }
    }
    .booking-route {
        display: flex;
        justify-content: flex-end;
        @media (max-width: 768px) {
            position: relative;
        }
        .icon {
            background-color: $vividBlue;
            color: $white_solid;
            border-radius: 50px;
            font-size: 18px;
            padding: 15px 30px;
            margin-right: 30px;
            margin-top: -27px;
            /* @media (min-width:768px) and (max-width:1200px) {
                font-size: 1.5vh;
                padding: 12px;
            } */
            @media (max-width: 1399px) and (min-width: 769px) {
                padding: 15px 17px;
                margin-top: -37px;
            }
            @media (max-width: 768px) {
                position: relative;
                margin-top: 0;
                top: -30px;
                font-size: 16px;
                padding: 13px 30px;
            }
            @media (max-width: 576px) {
                padding: 12px;
                margin-top: 0px;
                top: -30px;
            }
            svg {
                @media (max-width: 576px) {
                    width: 25px;
                    height: 25px;
                }
            }
            span {
                display: inline-block;
                margin-left: 15px;
            }
        }

        .icon2 {
            background-color: $primary_solid_color;
            color: $white_solid;
            border-radius: 50px;
            font-size: 20px;
            padding: 15px 15px 15px 15px;
            margin-right: 25px;
            margin-top: -27px;
        }
    }
    .time-schedule {
        padding: 45px 68px 62px 76px;
        .title {
            font-family: $primary_font_SemiBold;
        }
        .subtitle {
            font-family: $primary_font_Bold;
            font-size: 15px;
        }
        .availability-block {
            .day-wise {
                display: flex;
                margin-bottom: 10px;
                .in-active-slot {
                    color: $battleshipGrey;
                    opacity: 0.5;
                    background-color: $light_blue;
                }
                .active-slot {
                    background-color: $battleshipGrey;
                    color: $white_solid;
                }
                .day {
                    width: 51px;
                    height: 27px;
                    font-family: $primary_font_Bold;
                    font-size: 10px;
                    padding: 4px 14px;
                    line-height: 2;
                }
                .timing {
                    margin-left: 27px;
                    font-family: $primary_font_Regular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    color: $battleshipGrey;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    color: $blue;
                    font-family: $primary_font_SemiBold;
                    .slot {
                        cursor: pointer;
                        margin-right: 10px;
                        &:not(:last-child) {
                            &:after {
                                content: ",";
                            }
                        }
                    }
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                    &:empty {
                        &:before {
                            font-family: $primary_font_SemiBold;
                            color: $battleshipGrey;
                            content: "N/A";
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        .title,
        .description {
            font-size: 14px;
        }
    }
    .profile-detail {
        margin: 41px auto 49px;
        width: 100%;
        max-width: 609px;
        @media (max-width: 1024px) {
            max-width: 433px;
        }
        @media (max-width: 768px) {
            margin: 34px auto 62px;
            max-width: calc(100% - 130px);
        }
        @media (max-width: 576px) {
            max-width: 100%;
            padding-left: 20px;
            padding-right: 29px;
        }
        .orange-button {
            border-radius: 26.3px;
            border: solid 2px $vividBlue !important;
            font-size: 14px;
            font-family: $primary_font_Medium;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: center;
            color: $vividBlue;
            padding: 9px 31px;
            margin-bottom: 54px;

            .favorites-button-content {
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-left: -10px;
                }
            }
            @media (max-width: 1024px) {
                padding: 3px;
                .favorites-button-content {
                    svg {
                        margin-left: 0;
                    }
                    & > div {
                        display: none;
                    }
                }
            }
        }
        .btn-outline-danger:hover {
            background-color: $white_solid !important;
        }
        .about {
            margin-bottom: 48px;
        }
        .rectangle-block {
            height: 130px;
            border-bottom-right-radius: 25px;
            box-shadow: 0 10px 10px 0 $black_transparent;
            background-color: $white_solid;
            widows: 100px;
            display: flex;
            margin-bottom: 57px;
            .card-icon {
                font-size: 19px;
                color: #686c80;
            }
            .item {
                flex: 1 1 0px;
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                @media (max-width: 414px) {
                    flex-direction: row;
                    justify-content: flex-start;
                    padding-left: 30px;
                    & > div:not(.label) {
                        margin-right: 18px;
                    }
                }
            }
            .label {
                font-family: $primary_font_Medium;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                text-align: center;
                color: $battleshipGrey;
                margin-top: 3px;
            }
        }
        .table {
            margin-bottom: 59px;
            .table-header {
                font-family: $primary_font_Medium;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: $darkGrey;
            }
            tr:not(:first-of-type) {
                border-top: 1px solid $paleGrey;
            }
            td {
                font-family: $primary_font_Regular;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: $battleshipGrey;
                border: 0;
                @media (max-width: 414px) {
                    font-size: 12px;
                }
            }
        }
        .rules-block {
            margin-bottom: 64px;
            .item {
                margin-bottom: 39px;
                display: flex;
                align-items: center;
                .icon {
                    margin-right: 28px;
                }
                .description {
                    font-family: $primary_font_Regular;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    color: $battleshipGrey;
                    font-weight: 500;
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                    .description-title {
                        font-weight: 800;
                        font-family: $primary_font_Medium;
                    }
                }
            }
        }
        .rating-block {
            .heading {
                font-family: $primary_font_Medium;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                color: $darkGrey;
                @media (max-width: 414px) {
                    font-size: 14px;
                }
            }
            .rating {
                width: 120%;
                margin-right: 0;
                @media (max-width: 576px) {
                    width: 100%;
                }
            }
            .rating-count {
                font-size: 38px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.42;
                letter-spacing: normal;
                color: $primary_solid_color;
                font-family: $primary_font_Regular;
                @media (max-width: 414px) {
                    font-size: 32px;
                }
            }
            .outOf {
                align-self: flex-end;
                padding-bottom: 3px;
                font-family: $primary_font_Medium;
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.4;
                letter-spacing: normal;
                color: $primary_solid_color;
                @media (max-width: 414px) {
                    font-size: 18px;
                }
            }
            .open-ratings-link {
                color: $darkMango;
                text-decoration: underline;
                cursor: pointer;
            }
            .ratings-component {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            @media (max-width: 768px) {
                svg {
                    width: 20px;
                }
            }
        }
    }
    & > div:not(.profile-left-block) {
        @media (max-width: 768px) {
            flex: auto;
            max-width: 100%;
        }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        .time-schedule {
            padding: 79px 40px 62px 40px;
        }
        .ratings-component {
            flex-wrap: wrap;
        }
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
        .time-schedule {
            padding: 79px 40px 62px 40px;
        }
    }
    @media (max-width: 768px) {
        .ratings-component {
            flex-wrap: wrap;
        }
        .time-schedule {
            padding: 34px 80px 0px;
        }
    }
    @media (max-width: 576px) {
        .time-schedule {
            padding-left: 29px;
            padding-right: 20px;
        }
    }
}
.tutor-reviews {
    align-items: center;
    .close-modal-button {
        padding: 25px 25px 0 0;
        .fa-times {
            cursor: pointer;
            color: $darkMango !important ;
        }
    }
    .custom-modal-body {
        display: block !important;
        .custom-modal-content {
            width: 85vw;
            max-width: 900px;
            padding: 0 0 15px 0;
            margin: 0;
        }
    }
    & * {
        font-family: $primary_font_Regular;
        // color: $battleshipGrey;
        --slider-height-percentage: 60%;
        --slider-transition-duration: 670ms;
        --organic-arrow-thickness: 3px;
        --organic-arrow-border-radius: 0px;
        --organic-arrow-height: 16px;
        --control-button-width: 5%;
        --control-button-height: 25%;
        --control-button-background: transparent;
        --loader-bar-height: 6px;
    }
    .review-item {
        // width: 127%;

        // padding: 0px 80px 5px;

        .header {
            display: flex;
            margin-bottom: 30px;
            .avatar {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 102px;
                height: 102px;
                border-radius: 100%;
                margin-right: 30px;
                object-fit: cover;
                @media (max-width: 768px) {
                    height: 85px;
                    width: 85px;
                }
            }
            .rating {
                height: 102px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                flex-direction: column;
                flex-direction: column-reverse;

                h2 {
                    font-family: $primary_font_Medium;
                    margin-top: 0px;
                    font-size: 1.875rem;
                }
            }
        }
        .content {
            margin: 20px 0;
            max-height: 250px;
            min-height: 210px;
            overflow-y: auto;
            p {
                font-size: 1.125rem;
                word-break: break-word;
            }
            .noContent {
                margin: 20px 0;
                max-height: 200px;
                min-height: 150px;
                overflow-y: auto;
                p {
                    font-size: 1rem;
                    word-break: break-word;
                    color: darkgray !important;
                }
            }
        }
        .bottom {
            display: flex;
            justify-content: space-between;

            span:nth-child(1) {
                font-size: 1rem;
                font-family: $primary_font_Medium;
                max-width: 75%;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
            span:nth-child(2) {
                font-size: 0.875rem;
                @media (max-width: 768px) {
                    font-size: 11px;
                }
            }
        }
    }

    .awssld__container {
        min-height: 450px;
        padding: 0;
    }
    .awssld__content {
        background: none;
        align-items: flex-start;
        background: white;
    }
    .awssld__bullets {
        display: none;
    }
    .awssld__controls {
        @media (max-width: 520px) {
            visibility: visible;
        }
    }
    .title {
        text-align: center;
        padding: 0 80px;
        h3 {
            font-family: $primary_font_SemiBold;
            font-size: 1.875rem;
            font-weight: 500;
            line-height: 1.53;
            text-align: inherit;
            color: $battleshipGrey;
        }
    }
    .report {
        width: 100%;
        padding: 0 60px 10px;

        fieldset {
            width: 100%;
        }
        .custom-input {
            margin-top: -20px;
            padding-left: 30px;
            .input-label {
                color: $darkMango;
            }
        }
    }
}
.no-reviews {
    font-family: $primary_font_SemiBold;
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 1.53;
    text-align: inherit;
    color: #686c80;
    text-align: center;
    margin-bottom: 25px;
}
.review-card {
    width: 90% !important;
}
.tutor-reviews {
    .awssld__content {
        position: relative;
        width: 95%;
        margin: auto;
    }
}
