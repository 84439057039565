.mini-card {
    padding: 0 20px;
    border: 0px solid #ffffff;
    height: 65px;
    width: 100%;
    box-shadow: 0 10px 10px 0 $light_black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    font-family: $primary_font_Regular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.5px;
    color: $battleshipGrey;
}
