.avatars {
    position: relative;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    .icon {
        @extend .flex-center-center;
        width: 60px;
        height: 60px;
        background-color: white;
        margin: 0;
        z-index: 13;
    }
    .img-rounded {
        border: 5px solid white;
        border-radius: 100%;
        z-index: 13;
        background-color: #fff;
    }
    &::after {
        @include pseudo-element;
        width: 100%;
        height: 1px;
        border-bottom: 5px dotted $vividBlue;
        top: 50%;
        z-index: 10;
    }
}

.avatarsforForAlmostThere {
    position: relative;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    margin: 0 auto 17px auto !important;
    width: 50%;
    .icon {
        @extend .flex-center-center;
        width: 60px;
        height: 60px;
        background-color: white;
        margin: 0;
        z-index: 13;
    }
    .img-rounded {
        border: 5px solid white;
        border-radius: 100%;
        z-index: 13;
    }
    &::after {
        @include pseudo-element;
        width: 100%;
        height: 1px;
        border-bottom: 5px dotted $vividBlue;
        top: 50%;
        z-index:0;
    }
}
.almostAvatarsLinks{
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
}
@media (max-width:992px) {
    .avatarsforForAlmostThere{
        width: 75%;
    }
    .booking-confirmed h1{
        font-size: 28px !important;
    }
    .almostAvatarsLinks{
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .MuiGrid-item{
            margin: 5px !important;
        }
    }
    .almostAvatarsLinks-nested{
        display: flex;
    flex-direction: column;
    text-align: center;
    }
    .avatarsLinkPad{
        padding: 10px;
    }
}
@media (max-width:768px) {
    .booking-confirmed h1{
        font-size: 28px;
    }

}
@media (max-width:576px) {
    .avatarsforForAlmostThere{
        width: 90%;
    }
}