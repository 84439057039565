.avatarsstr {
    position: relative;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    @media (max-width:767px) {
        width: 90%;
        margin: auto;
    }
    @media (max-width:400px) {
        width: 100%;
        margin: auto;
    }
 
    .icon {
        @extend .flex-center-center;
        width: 60px;
        height: 60px;
        background-color: white;
        margin: 0;
        z-index: 13;
    }
    .img-rounded {
        border: 5px solid white;
        border-radius: 100%;
        z-index: 13;
    }
    &::after {
        @include pseudo-element;
        width: 80%;
        height: 1px;
        border-bottom: 5px dotted $vividBlue;
        top: 50%;
        z-index: 10;
        left: 29px;
    }
   
}
