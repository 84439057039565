.p-60 {
    font-size: 60px;
}
.p-38 {
    font-size: 38px;
}
.p-26 {
    font-size: 26px;
}
.p-20 {   
    font-size: 20px;
}
.p-18 {
    font-size: 18px;
}
.p-16 {
    font-size: 16px;
}
.p-14 {
    font-size: 14px;
}
.p-12 {
    font-size: 12px;
}
.p-mango {
    color: $darkMango;
}
.p-blue {
    color: $vividBlue;
}
.p-white {
    color: $white_solid;
}
.p-battleship {
    color: $battleshipGrey;
}
.p-neu {
    color: #455052;
}
a {
    text-decoration: underline;
    color: $darkMango;
}
.lessons-card {
    border-radius: 7px;
    box-shadow: 0 10px 10px 0 $light_black;
    margin: 10px;
    width: max-width;
    overflow: hidden;
    img {
        margin-right: 15px;
        width: 125px;
        height: 125px;
        float: left;
    }
    p {
        margin-top: 20px;
        font-family: $primary_font_Medium;
    }
}

.confirmed-container {
    .lessons-card {
        margin-right: 90px;
        margin-left: 90px;
    }
    margin-top: 60px;
    .payment-confirmed-heading {
        text-align: center;
        margin-top: 30px;
    }
    .payment-row {
        margin-top: 40px;
    }
    .access-label {
        float: left;
    }
    .price-label {
        float: right;
        text-align: right
    }
    .button-container {
        width: 100%;
        margin-top: 20px;
        .button {
            margin-right: auto;
            margin-left: auto;
        }
    }
    a {
        text-decoration: underline;
        color: $darkMango;
        font-size: 14px;
    }
    @media (min-width:768px) and (max-width:992px) {
        .payment-confirmed-heading {

            font-size: 28px !important;
            margin: 30px 120px 1rem !important;
        }
    }
    @media (max-width:767px) {
        .payment-confirmed-heading{
            font-size: 28px !important;
            margin: 30px 100px 1rem !important;
        }
        .lessons-card{
            margin-left: 10px !important;
            margin-right: 10px !important;
        }
    }
    @media (max-width:576px) {
        .payment-confirmed-heading{
            font-size: 28px !important;
            margin: 30px 50px 1rem !important;
        }
    }
    @media (max-width:390px) {
        .payment-confirmed-heading{
            font-size: 28px !important;
            margin: 30px 25px 1rem !important;
        }
    }
}

.subscription-payment {
    max-width: 100vw;
    align-items: flex-start;
    margin: 0;
    height: 100%;
    .cancelation, .subtotal{
        padding-left: 10px !important;
        padding-right:  0 !important; 
    }
    .modal-title{
        color: $battleshipGrey;
        font-size: 30px;
    }
    .modal-description{
        color: $battleshipGrey;
        font-size: 18px;
    }
    .white-section {
        padding: 35px 18px;
        width: 100%;
        background-color: $white_solid;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
            padding-left: 27px;
            color: $darkMango;
            margin-bottom: 27px;
            font-size: 38px;
        }
        .header {
            padding-left: 27px;
            padding-bottom: 40px;
            border-bottom: solid 1px $paleGreyThree;
            .avatar {
                margin-left: 3px;
                margin-right: 39px;
                img {
                    width: 102px;
                    height: 102px;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }
        }
        .subtotal {
            border-top: 1px solid $paleGreyThree;
            border-bottom: 1px solid $paleGreyThree;
            padding: 37px 35px;
            justify-content: space-between;
            font-size: 20px;
            p {
                margin-bottom: 0px;
                font-family: $primary_font_Medium;
                color: $battleshipGrey;
            }
            span {
                color: $darkMango;
            }
        }
        .cancelation {
            padding: 20px 35px 0px 35px;
            color: $battleshipGrey;
            .description {
                margin: 0px 15px;
                p {
                    span {
                        font-weight: 600;
                    }
                    & ~ p {
                        @extend .p-link;
                    }
                }
            }
        }
    }
    .gray-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        height: 100%;
        margin: 0;
        padding: 40px 60px;
        width: 100%;
        color: $white_solid;
        background-color: $battleshipGrey;
        .multi-select-drop-down {
            margin-top: 47px;
            padding: 0;
            .custom-input {
                .input-label {
                    color: $white_solid;
                }
            }
        }
        .card-select{
            img{
                width: 35px !important;
            }
        }
        .password-section {
            margin-top: 45px;
            .custom-input {
                padding: 0;
            }
            .input-label {
                color: $white_solid;
                font-size: 16px;
                font-weight: 300;
            }
        }
        .tcd-footer {
            margin-top: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .tcd-p{
                display: flex;
                align-items: baseline;
                .checkbox-label1{
                    position: relative !important;
                    top: -8px !important;
                }
            }
            p {
                margin-bottom: 0;
                span {
                    font-size: 0.875rem;
                    color: $white_solid;
                    padding-left: 0 !important;
                }
                .checkbox-label {
                    padding-left: 35px;
                    margin-top: -35px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 25px;
                    color: $white_solid;
                    font-family: $primary_font_Regular;
                }
                .checkbox-label1 {
                    padding-left: 2px;
                    margin-top: -35px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 25px;
                    color: $white_solid;
                    font-family: $primary_font_Regular;
                }
                .MuiCheckbox-colorSecondary.Mui-checked {
                    color: $white_solid;
                }
            }
            .button {
                align-self: center;
                margin-top: 62px;
            }
        }
        & > * {
            color: $white_solid;
        }
    }
    & > * {
        color: $battleshipGrey;
        max-width: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
    }
    .card-select {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .input-container {
        position: relative;
        .card-select {
            cursor: pointer;
            padding: 0 55px 0 25px;
            border: 0px solid $white_solid;
            height: 65px;
            width: 100%;
            border-radius: 7px;
            box-shadow: 0 10px 10px 0 #0000000d;
            background: $white_solid;
            color: $battleshipGrey;
        }
        .error-box {
            margin-left: 2px;
            position: absolute;
            right: 0;
            height: 100%;
        }
        input {
            border-radius: 7px !important;
        }
    }
    .multi-select-drop-down .dropdown-box-continer {
        top: 30px;
    }
    .wrap {
        flex-wrap: wrap;
    }

    .checkout-header {
        font-size: 38px;
        font-weight: 500;
    }
    .title-right {
        font-size: 38px;
        font-weight: 500;
    }
    .checkbox-flex {
        flex-direction: row;
    }
    .plan-access {
        font-size: 20px;
        font-weight: 500;
        text-align: right;
    }
    .margen-right {
        margin-right: 12px;
    }

    .d-flex {
        display: flex;
    }
    .d-flex.end {
        justify-content: flex-end;
    }

    .availability ~ div {
        margin-top: 50px;

        .button .outline-button {
            width: 120px;
        }
    }
    .text-right {
        float: right;
    }


    .multi-select-drop-down .dropdown-box-continer .dropdown-box.paymentdropdown {
        height: unset;
        max-height: 360px;
    }

    .resume-page-input {
        label {
            font-family: $primary_font_Regular;
            font-size: 0.875rem;
        }
    }

    .gray-section .footer p span {
        color: $white_solid;
        text-decoration: none;
    }
    @media (max-width: 1023.95px) {
        .p-60 {
            font-size: 48px;
        }
    }
    @media (max-width: 767.95px) {
        .checkout-header {
            font-size: 32px;
        }
        .title-right {
            font-size: 25px;
        }
        .p-60 {
            font-size: 48px;
        }
    }

    @media (max-width: 413.95px) {
        .checkout-header {
            font-size: 32px;
        }
        .p-60 {
            font-size: 48px;
        }
    }
}

@media (min-width:768px) and (max-width: 1023.95px)  {
    .subscription-payment{
        .checkout-header{
            position: relative;
            right: 40px;
        }
        .gray-section{
            padding: 40px 80px;
        }
        .white-section{
            padding: 35px 80px !important;
        }
        .tcd-footer{
            margin-top: 20%;
        }
    }
    
   
}
@media (max-width: 767.95px){
    .lesson-whitesection-mob-pad{
        padding:0 25px !important;
    }
    .password-section{
        .resume-page-input{
            .input-label{
                font-size: 14px !important;
            }
        }
    }
    .tcd-footer{
        margin-top: 20% !important;
    }
   
}
@media (max-width:576px) {
    .password-section{
        .resume-page-input{
            .input-label{
                font-size: 13px !important;
            }
        }
    }
    .subtotal{
        flex-direction: column;
    }
    .lesson-whitesection-mob-pad{
        padding:0 5px !important;
    }
    .gray-section{
        padding: 35px !important;
        .title-right{
            margin: auto 50px;
            font-size: 32px;
        }
       
    }

}
@media (max-width: 413.5px){
    .password-section{
        .input-label{
            font-size: 11px;
        }
    }
}
