.button-container {
    @extend .d-flex;
    @extend .flex-row;
    @extend .justify-content-between;
    @extend .position-relative;
    z-index: 1;
    bottom: 0px;
}

@media (max-width: 413px) {
    .button-container {
        position: inherit;
        flex-direction: column !important;
        text-align: center;
        > :first-child {
            margin-bottom: 20px;
        }
    }
}
@media (min-width: 414px) and (max-width: 768px) {
    .button-container {
        position: inherit;
        flex-direction: column !important;
        text-align: center;
        > :first-child {
            margin-bottom: 20px;
        }
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
}
