$primary_solid_color: #f28334;
$primary_light_color: #f38d43;

$black_solid: #000000;
$white_solid: #ffffff;
$curious_blue: #2590bf;
$seashell_solid: #f1f1f1;
$silver: #cccccc;
$orange: #f28334;
$chambray: #3b5998;
$punch: #dd4b39;
$twitterColor: #1da1f2;
$mine_shaft: #262626;
$monza: #b00020;
$vividBlue: #262ffa;
$dodgerBlue: #3ebfff;
$mango: #f9a722;
$darkMango: #f28334;
$darkGrey: #2d3234;
$battleshipGrey: #686c80;
$paleGrey: #dddee8;
$white: #f6f6f6;
$coral: #ef5350;
$macaroniAndCheese: #f1be43;
$greenishTeal: #36d174;
$lightUrple: #a577ed;
$paleGreyTwo: #f3f5fb;
$paleGreyThree: #efeff3;
$red: #ff0000;
$light_black: #0000000d;
$blue: #0000ff;
$light_gray: #f4f5f8;
$black_transparent: rgba(0, 0, 0, 0.05);
$grey_transparent: rgba(238, 238, 238, 0);
$light_blue: #f0f0f5;
$dark_grey: #848aa5;
$greenish_shadow: rgba(54, 209, 116, 0.5);
$redish_shadow: rgba(255, 0, 0, 0.5);
$lite_white: #ffffffb9;
$graphite: #323232;
$cancelRed:#ff5924;
