.singlePendingRapidBook{
    max-width: 475px;
    .scroll{
        max-height: 300px;
        overflow-y: auto;
    }
    h2{
        font-family:  $primary_font_SemiBold !important;
        span{
            color: $vividBlue;
        }
    }
    h4{
        font-family: $primary_font_SemiBold !important;
        margin-top: 15px ;
        font-size: 20px;
        span{
            color :$coral !important;
        }
        u {
            margin-top: -2px;
            color :$coral !important;
        } 
    }
    .rapidBookButton1{
        width: 472px;
        height: 48px;
        background-color: $vividBlue;
        border: solid 2px $vividBlue;
        font-size: 18px;
        font-family:  $primary_font_SemiBold;
        padding :0px
    }
    .rapidBookButton2{
        width: 472px;
        height: 48px;
        color: $vividBlue;
        font-size: 18px;
        font-family:  $primary_font_Bold;
        padding :0px
    }
    @media (max-width: 515px) {
        h2{
           line-height: 30px !important;
           font-size: 22px !important;
        }
        h4{
            margin-top: 12px ;
             font-size: 20px;
         }
        .rapidBookButton1{
            width: 320px;
            font-size: 14px;
        }
        .rapidBookButton2{
            width: 320px;
            font-size: 14px;
        }
    }
}
@media (max-width :515px) {
    .singlePendingRapidBook{
        .scroll{
            max-height: 185px;
        }
    }
}
.tutorRapidBook {
    max-width: 473px !important;
}
.tutorRapidBookMulitple{
    max-width: 538px !important;
}
.rapid-book-turn-off-clock {
    text-align: center;
    .clock{
    width: 145px;
    height: 145px;
    }
}
