.rounded-mango {
    border-radius: 100%;
    background: $darkMango;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        width: 15px;
    }
}
