.session-feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Fi*/
    //  div.close-modal-button{
    //     visibility: hidden;
    // }
    .avatar {
        // max-width: 350px;
        margin: 0 auto 20px auto;
        .avatars {
            left:178px;
            width: 50%;
            @media (max-width:768px) {
                left: 0;
                width: 100%;
            }
        }

    }
    .title {
        text-align: center;
        padding: 0 80px;
        &.step2 {
            padding: 0px 0px 70px 75px;
            text-align: left;

            img {
                max-width: 70%;
                @media (max-width: 767px){
                    margin-left: 80%;
                }
                @media (max-width: 320px){
                    margin-left: 65%;
                }
                @media (min-width: 768px) and (max-width: 1024px){
                    margin-left: 5%;
                }
            }
        }
        h3 {
            font-family: $primary_font_SemiBold;
            font-size: 1.875rem;
            font-weight: 500;
            line-height: 1.53;
            text-align: inherit;
            color: $battleshipGrey;
            @media (max-width: 768px){
                text-align: center !important;
            }
            span {
                display: block;
                margin: 10px 0;
                color: $darkMango;
                font-size: 1.25rem;
            }
        }
        .outline-button {
            float: left;
            margin-top: 20px;
        }
    }
    .rates {
        margin: 23px 0;
        text-align: center;
        h5 {
            font-family: $primary_font_Regular;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.38;
            text-align: center;
            color: $darkMango;
        }
    }
    .comments {
        padding: 0 40px;
        margin-bottom: 40px;
        @media (max-width:768px) {
            padding: 0 10px;
        }

        .switch-component {
            margin-left: 15px;
            font-size: 1rem;
            padding: 20px;
            font-family: $primary_font_Medium;
            font-weight: 500;
        }
    }

    &.no-padding {
        padding: 0;
    }
    .gray-section {
        background: $white;
        width: 100%;
        padding: 70px 50px;
    }
    .rec-sent {
        @extend .default-border-radius;
        width: 100%;
        height: 242px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        background-color: white;
        text-align: center;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        color: $battleshipGrey;
        span {
            display: block;
            margin: 10px 0;

            &:nth-child(1) {
                color: $darkMango;
            }
        }
    }

    .feedback-custom-modal-body {
        overflow-y: auto;
        display: flex;
        height: 100%;
        border-radius: 25px 0px;
        .feedback-custom-modal-content {
            margin: auto;
            padding: 25px;
            width: 75vw;
            max-width: 800px;
            height: auto;
            background: $white_solid;
            border-radius: 25px 0px;
        }
    }
    @media (max-width:768px) {
        width: 100%;
        z-index: 999999999 !important;
        .title{
            padding: 0 !important;
        }
        .feedback-custom-modal-content{
            width: 97vw !important;
            margin: 0 3% auto !important;
            .centered-button{
                height: auto !important;
                margin-bottom: 25px;
                width: 90%;
                margin-left: 5%;
                padding: 10px 25px !important;
            }
        }
    }
}

.ptb-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;

    img {
        margin-bottom: 28px;
    }
}
.feedback-custom-modal-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feedback-custom-modal-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.noCloseButton{
    .close-modal-button{
        visibility: hidden;
    }
}
.maxCharacters{
    margin-top: 10px;
    text-align: right;
    font-family: "Montserrat-light";
    font-size: 0.85rem
}