// $mango: #f9a722;
$darkMango: #f28334;
$medium_text_size: 1.4vw;
$darkGrey: #2d3234;
$battleshipGrey: #686c80;

.search-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
}
.search-item-wrapper {
    display: contents;
}
.search-item {
    font-family: "Montserrat-Medium";
    background-color: white;
    width: 100%;
    height: 110px;
    border-radius: 7px;
    box-shadow: 1px 13px 25px 0 rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    .search-button-container {
        margin-right: 0%;
        height: 100%;
    }
    .search-item-tutor-hours {
        width: 15%;
        min-width: 80px;
        display: flex;
        align-items: flex-end;
        margin: auto;
    }
    .tutor-name {
        font-size: $medium_text_size;
        color: $darkMango;
        /* font-size: 1.4vw; */
        font-size: 20px;
        max-width: 10.2vw;
        max-height: 70px;
        overflow-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto !important;
        @media (max-width: 1224px) {
            max-width: none;
        }
    }
    .search-item-view-profile {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
    }
    @media (min-width: 768px) and (max-width: 1200px) {
        height: auto;
        display: flow-root;
        position: relative;
        .search-item-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .subjects-hours {
            width: 100%;
            padding: 10px 0;
            height: unset;
            .subjects {
                margin: 0px 20px 0 65px;
                height: auto;
            }
        }
        .search-item-tutor-hours {
            height: unset !important;
            display: inline-block !important;
            width: 80% !important;
            .rating-text {
                margin: 0px 20px 0 65px;
            }
        }
        .search-item-view-profile {
            height: unset !important;
            display: inline-block !important;
            text-align: right;
            max-width: 150px;
            .favorites-marker {
                position: absolute;
                top: 10px;
                right: 15px;
            }
        }
    }
    @media (max-width: 576px) {
        height: auto;
        display: flow-root;
        position: relative;
        .search-item-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .subjects-hours {
            width: 100%;
            padding: 10px 0;
            height: unset;
            .subjects {
                margin: 0px 20px 0 65px;
                height: auto;
            }
        }
        .search-item-tutor-hours {
            height: unset !important;
            display: inline-block !important;
            width: 80% !important;
            .rating-text {
                margin: 0px 20px 0 65px;
            }
        }
        .search-item-view-profile {
            height: unset !important;
            display: inline-block !important;
            text-align: right;
            max-width: 150px;
            .favorites-marker {
                position: absolute;
                top: 10px;
                right: 15px;
            }
        }
    }
}

.hours-detail {
    font-size: 0.95vw;
    color: $battleshipGrey;
    display: flex;
    align-items: center;
}

.subjects-hours {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    margin-left: auto;

    .subjects {
        font-size: 14px;
        color: $darkGrey;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.rating-text {
    color: $battleshipGrey;

    .hourly-charge {
        font-size: 20px;
    }

    .fee-label {
        font-size: 16px;
    }
}

.no-results-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.favorites-marker {
    cursor: pointer;
}
@media (min-width: 768px) and (max-width: 1050px) {
    .search-item {
        .tutor-show-name-rating {
            width: 55% !important;
        }
    }
    .session-detail-card {
        height: 130px !important;
    }
}
@media (min-width: 768px) and (max-width: 800px) {
    .max-width-45 {
        max-width: 45% !important;
    }
}
@media (max-width: 767px) {
    .from-dashboard-usession-card {
        display: block !important;
    }
    .session-content-mob-left {
        position: relative;
        left: -45px;
    }
}
.Our-Rapid-Book-live {
    justify-content: center;
    align-items: center;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #2d3234;
}
.Our-Rapid-Book-live .text-style-1 {
    font-weight: 500;
    text-align: center;
    color: #262ffa;
}
.search-text-bold {
    font-family: Montserrat-Bold;
}
