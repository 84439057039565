.sidebar {
    display: none;
    position: relative;
    flex-direction: column;
    height: 100vh;
    position: fixed !important;
    left: 0;
    top: 0;
    .svg_style {
        fill: $battleshipGrey;
        height: 32px;
        width: 33px;
    }
    .header {
        width: 100%;
        position: relative;
        .navbar-logo {
            width: 53px;
            height: 53px;
            margin: 40px 0 0 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            svg {
                width: 100%;
                height: 33px;
                @media (min-width: 768px) and (max-width: 1440px){
                    transform: scale(0.8);
                }
            }
        }
        .navbar-collapse-button {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $paleGreyThree;
            right: -15px;
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: white;
            cursor: pointer;
            .cover {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                background-color: $vividBlue;
                border-radius: 50%;
            }
        }
    }
    .nav {
        width: 100%;
        overflow-x: auto;
        margin-top: 50px;
        margin-bottom: 100px;
        li {
            margin-left: 5px;
            @media (min-width: 768px) and (max-width: 1440px) {
                margin: 0px 0px 0px 0px;
            }
        }
        .nav-link {
            color: $battleshipGrey;
            // margin: 0px auto;
            display: flex;
            text-decoration: none !important;
            cursor: pointer;
            .nav-icon {
                min-width: 35px;
                min-height: 35px;
                width: 65px;
                height: 61px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                position: relative;
                @media (min-width: 768px) and (max-width: 1440px) {
                    width: 3.3vw;
                    height: 5.5vh;
                    margin-left: auto;
                    margin-right: auto;
                }
                svg {
                    @extend .svg_style;
                    min-width: 24px;
                    min-height: 24px;
                    width: 33px;
                    height: 33px;
                    @media (min-width: 768px) and (max-width: 1440px) {
                        transform: scale(0.8);
                    }
                }
            }
            .nav-title {
                display: none;
            }
        }
       .active {
            .nav-icon {
                background-color: $primary_solid_color;
                border-radius: 8px 0px;
                svg {
                    @extend .svg_style;
                    min-height: 24px;
                    height: 33px;
                    fill: $white_solid;
                    @media (min-width: 768px) and (max-width: 1440px){
                        transform: scale(0.8);
                        margin-left: 2% !important;
                        margin-top: 3% !important;
                        margin-bottom: 4% !important;
                    }
                }
                @media (min-width: 768px) and (max-width: 1440px){
                    width: 70% !important;
                    height: 45px !important;
                }
            }
        }
    }
    width: 106px;
    position: relative;
    background-color: $paleGreyThree;
    @extend .min-vh-100;
    z-index: 2;
    li {
        @extend .w-100;
        text-align: left;
    }
    .logout-wrapper {
        padding-bottom: 20px;
        position: absolute;
        bottom: 0;
        background-color: $paleGreyThree;
        @media (min-width: 768px) and (max-width: 1440px){
            left: 15%;
            transform: scale(1);
        }
        .nav {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
.sidebar.expanded {
    width: 329px;
    z-index: 2000;
    .header {
        .navbar-logo {
            width: calc(100% - 25px);
            svg {
                height: 100%;
                @media (min-width: 768px) and (max-width: 1440px){
                    transform: scale(0.99);
                }
            }
        }
        .navbar-collapse-button {
            right: -16px;
            top: 65px;
            .cover {
                padding-left: 10px;
            }
        }
    }
    .nav {
        .nav-link {
            display: flex;
            .nav-icon {
                @media (min-width: 768px) and (max-width: 1440px) {
                    margin-left: 4%;
                    margin-right: 0;
                }
                svg {
                    @extend .svg_style;
                }
            }
            .nav-title {
                font-family: $primary_font_Medium;
                font-size: 100%;
                font-weight: 600;
                line-height: 22px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-left: 10px;
                margin: 5px 0px;
                @media (min-width: 768px) and (max-width: 1024px) {
                   margin-left: 4%; 
                }
                @media (min-width: 1025px) and (max-width: 1440px){
                    margin-left: 2%;
                }
            }
        }
        .active {
            .nav-icon {
                background-color: $primary_solid_color;
                border-radius: 8px 0px;
                svg {
                    @extend .svg_style;
                    height: 33px;
                    fill: $white_solid;
                }
                @media (min-width: 768px) and (max-width: 1440px){
                    width: 17.5% !important;
                    height: 45px !important;
                    margin-left: 2.5%;
                }
            }
            .nav-title {
                font-family: $primary_font_Medium;
                color: $primary_solid_color;
                font-size: 110%;
                margin-left: 0;
            }
        }
    }
     .logout-wrapper {
        @media (min-width: 768px) and (max-width: 1440px){
            left: 3.5%;
        }
        .nav-title{
            @media (min-width: 768px) and (max-width: 1023px){
                font-size: 88% !important;
                display: block;
                width: 80px;
            }   
            @media (min-width: 1024px) and (max-width: 1440px){
                font-size: 88% !important;
                display: block;
                width: 85px;
            }
        }
    }
}

@media (min-width: 768.95px) {
    .sidebar {
        display: flex;
    }
}