.panel {
    @extend .d-flex;
    .panel-body {
        margin-left: 0px;
        @extend .flex-grow-1;
        @extend .min-vh-100;
    }
}
@media (max-width: 768.95px) {
    .panel {
        .panel-body {
            margin-left: 0px;
            margin-top: 30px;
            padding-top: 20px;
            .MuiContainer-root{
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-top: 10px ;
            }
        }
    }
}
