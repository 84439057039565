$darkMango: #f28334;
.model_singleslotbookingrequested{
    img{
        width: 130px;
        height: 130px;
        margin: 20px auto !important;
        text-align: center;
        border-radius: 50%;
        display: block;
    }
    h3{
        font-size: 18px;
        color: #686c80;
        text-align: center;
    }
    h4{
        font-size: 30px;
        color: #686c80;
        text-align: center;
    }
    h5{
        font-size: 18px;
        color: #686c80;
        text-align: center;  
    }
    .grey_div{
        max-width: 562px;
        margin: 30px auto 0;
        padding: 20px 25px;
        border-radius: 7px;
        background-color: #f6f6f6;
        
        h4{
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            color: #686c80;
            text-align: left;
        }
        p{
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            color: #686c80;
            text-align: left;
        }
    }
    ul{
        margin: 20px 0;
        li{
            margin: 10px 15px;
            display: inline-block;
            a{
                background-color: #ffffff;
                border-radius: 26.3px;
                border: solid 2px $darkMango;
                padding: 13px 20px;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                text-align: center;
                color: $darkMango;     
                &:hover{
                    color: #ffffff !important;
                    background-color: $darkMango;
                    text-decoration: none;
                }
            }

            
        }
    }
    a.btnGoToSession{
        display: flex;
        flex-direction: column;
        margin: 20px auto !important;
        background-color: #ffffff;
        border-radius: 26.3px;
        align-self: center;
        border: solid 2px $darkMango;
        padding: 13px 20px;
        font-size: 16px;
        font-weight: 500;
        width: 50%;
        min-width: fit-content;
        font-stretch: normal;
        text-align: center;
        color: $darkMango;     
        &:hover{
            color: #ffffff !important;
            background-color: $darkMango;
            text-decoration: none;
        }
    }
    @media (max-width:576px) {
        h4{
            font-size: 20px !important;
        }
    }
}