.chat-view {
    width: 345px;
    background-color: #e3eaf7;
    overflow: hidden;
    display: flex;
    height: 100vh;
    flex-direction: column;
    .tutorChatImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .profile-container {
        height: 274px;
        img {
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    label {
        margin-bottom: 0px;
        cursor: pointer;
        margin-right: 10px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 10px;
        .camera-icon-block {
            height: 45px;
            width: 45px;
            background-color: $white_solid;
            border-radius: 50%;
            color: $darkMango;
            font-size: 12px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;

            svg {
                height: 25px;
                width: 25px;
                fill: $darkMango;
            }
        }
    }
    .caption-block {
        height: 147px;
        background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), $battleshipGrey 97%);
        margin-top: -169px;
        .content {
            position: absolute;
            font-size: 26px;
            font-weight: 500;
            line-height: 1.46;
            color: $white_solid;
            font-family: $primary_font_Medium;
            margin-top: 76px;
            margin-left: 30px;
            display: flex;
            .label {
                margin-right: 30px;
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                white-space: nowrap;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
            .icons {
                display: flex;
                .icon-block {
                    margin-right: 10px;
                    margin-left: 10px;
                    height: 45px;
                    width: 45px;
                    background-color: $white_solid;
                    border-radius: 50%;
                    color: $darkMango;
                    font-size: 12px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                }
            }
        }
    }
    .chat-users {
        width: auto;
        height: 67px;
        background-color: #00000057;

        display: flex;
        flex-wrap: wrap;
        align-content: center;
        display: flex;
        .icon-block {
            margin-right: 2%;
            margin-left: 6%;
            height: 35px;
            width: 35px;
            background-color: $darkMango;
            color: $white_solid;
            border-radius: 50%;
            font-size: 11px;
            display: inline-block;
        }
        .presenter-icon-block {
            @extend .icon-block;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
        }
        .user-image {
            height: 35px;
            width: 35px;
            border-radius: 50%;
        }
    }

    .send-chat-block {
        float: right;
        border-bottom: 4px solid $primary_solid_color;
        border-radius: 0px 25px !important;
    }
    .chat-block {
        max-width: 265px;
        width: fit-content;
        border-top-left-radius: 25px;
        border-bottom-right-radius: 25px;
        background-color: $white_solid;
        margin-left: 21px;
        margin-right: 21px;
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 18px 18px 10px 30px;
        .icon-block {
            display: flex;
            .user-image {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                display: flex;
                background-color: $battleshipGrey;
                flex-wrap: wrap;
                position: relative;
                align-content: center;
                justify-content: center;
            }
            img {
                border: none;
                outline: none;
                border-image: none;
            }
            .user-detail {
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                font-family: $primary_font_Regular;
                font-size: 14px;
                color: $darkGrey;
                margin-left: 9px;
                .user-name {
                    min-height: 20px;
                    font-family: $primary_font_Medium;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    color: $darkGrey;
                    word-break: break-word;
                    text-transform: capitalize;
                }
                .time {
                    color: $battleshipGrey;
                }
            }
        }
        .image-loader-container {
            position: relative;
            display: flex;
            .image-loader {
                height: 15vh;
                width: 15vh;
                position: absolute;
                color: $darkMango;
                opacity: 0.9;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .message {
            margin-top: 11px;
            font-family: $secondary_font_regular;
            color: $battleshipGrey;
            font-size: 14px;
            word-break: break-word;
            a {
                color: $primary_solid_color;
            }
        }
    }

    .chat-input-area {
        clear: both;
        margin-top: auto;
        display: flex;
        align-content: center;
        text-align: center;
        justify-content: center;
        margin: 14px 0px;
        position: fixed;
        bottom: 0;
        left: 10px;
        width: 325px;
        .chat-input {
            display: flex;
            background-color: $white_solid;
            border-radius: 30px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 10px;
            input {
                -webkit-user-select: initial !important;
                height: 41px;
                width: 80%;
                border-radius: 20.5px;
                padding-top: 9px;
                border: none;
                padding-bottom: 9px;
                padding-left: 20px;
                color: $battleshipGrey;
                box-shadow: none;
            }
        }
    }
    .input[type="text"]:focus {
        outline: none !important;
    }
}

.media-modal {
    display: inline-flex;
    position: relative;
    height: 100vh;
    width: 100vw;
    .close-modal-button {
        position: absolute;
        color: $darkMango;
        cursor: pointer;
        margin: 25px;
        font-size: 28px;
        right: 0px;
    }
    .media-modal-container {
        height: 0;
        overflow: hidden;
        width: 90%;
        padding-top: calc(591.44 / 1127.34 * 100%);
        position: relative;
        margin: auto;
        display: flex;
    }
    .media-modal-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: auto;
        width: inherit;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
    }
}
.chatUserProfile {
    position: relative;
    width: 100%;
    .UserImage {
        width: 345px;
        height: 252px;
        object-fit: cover;
    }
    .bottom-left {
        position: absolute;
        width: 100%;
        padding-left: 30px;
        bottom: -16px;
        color: $white_solid;
        font-size: 2rem;
        background-color: #00000069;
    }
}

@media (max-width: 1921px) {
    .chatUserProfile {
        position: relative;
    }
}
@media (max-width: 1367px) {
    .chatUserProfile {
        position: relative;
    }
}
@media (max-width: 1025px) {
    .chatUserProfile {
        position: relative;
    }
}

@mixin func_replace-text() {
    .chat-view {
        display: block;
        position: fixed;
        z-index: 2;
        width: 100%;
        .chatUserProfile {
            display: flex;
            background-color: white;
            padding-left: 40px;
            padding-bottom: 5px;
            padding-top: 5px;
            .UserImage {
                height: 52px;
                width: 52px;
                border-radius: 50px;
            }
            p {
                position: relative;
                bottom: 0;
                margin-bottom: 0px;
                line-height: 52px;
                font-size: 16px;
                color: #686c80;
                background-color: white;
            }
        }
        .chat-users {
            display: none;
        }
        #chat-messages {
            height: 85% !important;
            .chat-input-area {
                width: 100%;
            }
        }
    }
}

.platform-true {
    @include func_replace-text();
}
@media (max-width: 768px) {
    .chat-view {
        display: none;
    }
    #mobile-chat-view {
        @include func_replace-text();
    }
}
