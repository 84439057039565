@import "../../../../../src//assets/styles/variables";
$darkMango: #f28334;
.student-looking-options-component{
   
    .student-Looking-Card-Content{
    text-align: center;
    img{
        height: 155px;
        width: 155px;
    }
    .student-looking-grid-text{
            padding: 30px 20px;
           h1{
            font-family:$primary_font_Regular;
            font-size: 26px;
            line-height: 1.46;
           }
           p{
            font-family: $primary_font_Regular;
            font-size: 14px;
            line-height: 1.43;
            color: #686c80;
            padding: 0 15px;
        }
    }
  
    }
}
.and-or-div-lg{
    width: 100%;
    position: absolute;
    .and-or{
        width: 370px;
        text-align: center;
        position: relative;
        left: 185px;
        hr{
            position: relative;
            top: 30px;
            border-top: 4px dotted $darkMango !important;
            z-index: -111;
        }
        .and-or-span{
            padding: 10px 20px;
            color: #fff;
            background-color: $darkMango;
            border-radius: 25px;
        }
    }
    @media (min-width:1024px) {
        .and-or{
            top: 25px;
        }
    }
    @media (min-width:992px) and (max-width:1200px) {
        .and-or{
            width: 310px;
            left: 160px;
        }
    }
    @media (min-width:769px) and (max-width:992px) {
        .and-or{
            left: 160px;
        }
    }
}
.and-or-div-mob{
    display: none;
}
@media (max-width: 1023px){
    .student-Looking-Card-Content{
        img{
            height: 80px;
            width: 80px;
        }
    }
   
}
@media (min-width:992px) {
    .student-looking-options-grid{
        justify-content: space-between;
        .onboarding-college-counseling-grid{
            flex-basis: 31.333333% !important;
            
        }
    }
}
@media (min-width:701px) and (max-width:769px) {
    .student-looking-options-grid{
        max-width: 50% !important;
    }
}
@media (max-width:700px) {
    .student-looking-options-grid{
        max-width: 95% !important;
    }
}
.student-looking-grid-card{
    z-index: 1;
    box-shadow: 0 10px 10px 0 #0000000d;
    background-color: #ffffff;
    border-radius: 0 0 25px;
    cursor: pointer;
}
@media (max-width:992px) {
    .onboarding-college-counseling-grid{
        margin-top: 2rem !important;
    }
}
@media (min-width:992px) and (max-width:1025px){
    .stuent-cards-sing-grid{
        flex-basis: 65.33333333% !important;
    }
}
@media (max-width:768px) {
    .and-or-div-lg{
        display: none;
    }
    .and-or-div-mob{
        display: block;
        .and-or{
            text-align: center;
            width: 60%;
            margin: auto;
            position: relative;
            top: -50px;
            hr{
                position: relative;
                top: 30px;
                border-top: 3px dotted $darkMango !important;
                z-index: -111;
            }
            .and-or-span{
                padding: 10px 20px;
                color: #fff;
                background-color: $darkMango;
                border-radius: 25px;
            }
        }
    }
    .student-looking-options-grid{
        margin: auto;
    }
}
.view-lessons-link{
    display: inline-block;
    color: $darkMango !important;
    border-bottom: 2px solid $darkMango;
    padding-bottom: 5px !important;
}
