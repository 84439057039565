.college-counseling-card {
    a {
        color: $darkMango;
    }
    .sub-title {
        margin-bottom: 0px;
    }
    .navigation-card {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: -20px;
        padding-bottom: 40px;
        height: fit-content;
        border-radius: 0 0 10px;
        cursor: pointer;
    }
    .bottom-content {
        display: none;
    }
}

@media (max-width: 1024px) {
    .college-counseling-card {
        .card-content {
            display: flex;
            flex-direction: column-reverse;
        }
        .sub-title {
            display: none;
        }
        a {
            display: none;
        }
        .navigation-card {
            border-radius: 0 0 25px;
            border-bottom: 0px;
            margin-left: 0px;
            margin-right: 0px;
        }
        .bottom-content {
            display: block;
        }
    }
}
@media (max-width: 768px) {
    .college-counseling-card {
        .card-content {
            display: flex;
            flex-direction: row;
        }
        .sub-title {
            display: block;
        }
        a {
            display: block;
        }
        .navigation-card {
            border-radius: 0 0 10px;
        }
        .bottom-content {
            display: none;
        }
    }
}

@media (max-width: 414px) {
    .college-counseling-card {
        .card-content {
            display: flex;
            flex-direction: column-reverse;
        }
        .sub-title {
            display: none;
        }
        a {
            display: none;
        }
        .navigation-card {
            border-radius: 0 0 25px;
            border-bottom: 0px;
            margin-left: 0px;
            margin-right: 0px;
        }
        .bottom-content {
            display: block;
        }
    }
}
.dashboard-college-counselig-button{
    background-color:#fff !important;
    color: #262ffa !important;
    outline: 2px solid #262ffa !important;
    border: 2px solid #262ffa !important;
    font-family: Montserrat-Medium !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 1.38 !important;
}