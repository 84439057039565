.custom-note {
    background-color: rgba(104, 108, 128, 0.05);
    margin-top: 20px;
    padding: 28px;
    font-size: 16px;
    line-height: 22px;
    font-family: $primary_font_Regular;
    font-weight: 600;
    p.note-title {
        color: #ff5924;
        font-family: $primary_font_Medium;
        margin: 0;
        font-size: inherit;
        line-height: inherit;
    }
    p.note-content {
        margin: 14px 0 0 0;
        font-size: inherit;
        line-height: inherit;
    }
}