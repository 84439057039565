.button {
    button {
        @extend .normal-font-style;
        width: 200px;
        height: 48px;
        border-radius: 26.3px;
        font-family: $primary_font_Medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        color: $white_solid;
        background-color: $vividBlue;
        outline: none !important;
        border: none;
        margin: 0 auto;
        @extend .text-center;
        .right-side-icon {
            padding-left: 1em;
        }
        .left-side-icon {
            padding-right: 1em;
        }
        .button-spinner {
            @extend .d-block;
            padding-top: 8px;
        }
    }
    .outline-button {
        width: 200px;
        height: 48px;
        color: $vividBlue;
        border-radius: 26.3px;
        border: solid 2px $vividBlue;
        background-color: $white_solid;
        &:disabled {
            border: solid 2px $paleGrey;
            color: $battleshipGrey;
        }
    }
    .no-outline-button:disabled {
        background-color: $paleGrey;
    }

    .notext-button {
        width: 40px;
        height: 40px;
        .right-side-icon {
            padding-left: 0em;
        }
        .left-side-icon {
            padding-right: 0em;
        }
    }

    .small-button {
        width: 36px;
        height: 36px;
    }
    BeatLoader {
        color: $paleGrey;
    }
}

.button-updated {
    height: 100%;
    @media(max-width: 991px){
        margin-right: -5px;
    }
    button {
        @extend .normal-font-style;
        width: 100%;
        /* height: 100%; */
        height: 38px;
        border-radius: 26.3px;
        font-family: $primary_font_Medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        color: $white_solid;
        background-color: $vividBlue;
        outline: none !important;
        border: none;
        margin: 0 auto;
        @extend .text-center;
        .right-side-icon {
            padding-left: 1em;
        }
        .left-side-icon {
            padding-right: 1em;
        }
        .button-spinner {
            @extend .d-block;
            padding-top: 8px;
        }
        div{
            padding: 0 10px;
            white-space: nowrap;
            font-size: 14px !important;
        }
    }
    .outline-button {
        width: 185px;
        height: 48px;
        color: $darkMango;
        border-radius: 26.3px;
        border: solid 2px $darkMango;
        background-color: $white_solid;
    }
    button:disabled {
        background-color: $paleGrey;
    }
}
