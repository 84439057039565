$darkMango: #f28334;
.model_tutorstdacceptmysuggestion{
    .imgs_div{
        text-align: center;
        max-width: 450px;
        margin: auto;
        padding-bottom: 15px;
        .avatars{
            width: 100%;
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 1px;
                border-bottom: 3px dotted #262ffa;
                top: 50%;
                z-index: 10;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            }
            .img-rounded{
                border: none;
                padding: 0;
                margin: 0 !important;
            }
        }
        .img_icons{
            width: 102px;
            height: 102px;
            border-radius: 50%;
            display: inline-block;
        }
        .img_icon1{
            text-align: left;
        }
        .img_icon2{
            text-align: right;
        }
        span{
            img{

            }
            &:before{

            }
            &:after{

            }
        }
    }
    h2{
        color: #686c80;
        font-size: 26px;
        line-height: 46px;
        text-align: center;
        margin-bottom: 10px;
    }
    h4{
        color: #686c80;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        max-width: 575px;
        margin: auto;
    }
    ul {
        margin-top: 20px;
        li{
            list-style: none;
            margin: 15px 0px;  
        }
    }
    .btn_stdwhentutoracceptsomeofmyreuests{
        text-align: center;
        margin-top: 30px;
        .button{
            vertical-align: middle;
            button{
                background-color: #fff;
                color: $darkMango;
                border-radius: 26.3px;
                border: solid 2px $darkMango;
                padding: 13px 20px;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                text-align: center;
                margin: 20px auto;
                display: inline-block;
                margin: 0px 10px;
                width: auto;
                &:hover{
                    background-color: $darkMango;
                    color: #fff;
                    border: solid 2px $darkMango;
                }
            }
        }
    }
}