.user-panel-pic {
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top: 50px;
    left: -28px;
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.user-panel-head {
    color: $white_solid;
    font-family: $primary_font_Medium;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    p {
	padding-left: 85px;
    position: relative;
    top:20px;
    }
    .user-panel-head-background-img {
	filter: blur(5px);
	position: absolute;
	top: 0px;
	height: 400px;
	width: 100%;
    }
    .user-panel-head-name {
	font-size: 30px;
    line-height: 30px;
    overflow-wrap: break-word;
    @media (min-width:1024px) {
            .user-panel-head-name{
                position: relative;
                top: 20px !important;
            }
        }
    }
    .MuiGrid-item {
	z-index: 1;
    }
    .MuiGrid-container {
	overflow: hidden;
	position: relative;
    }
    .user-panel-head-school {
	line-height: 22px;
    position: relative;
    top:10px !important
    }
    .user-panel-head-background-check-indicator {
	font-size: 12px;
	padding-bottom: 20px;
    }
    .background-check-button {
	position: absolute;
	bottom: -19px;
	right: 20px;
	.button {
	    button {
		font-weight: bold;
		height: 38px;
		width: fit-content;
		padding-right: 22px;
		padding-left: 22px;
	    }
	}
    }
}

.user-panel-container {
    height: 80vh;
    font-family: $primary_font_Medium;
    .calender-panel{
        padding-bottom: 20px;
        overflow: unset !important;
    }
}
.user-panel-body {
    padding: 43px;
}
@media (min-width:1024px) and (max-width:1600px) {
    .user-panel-body {
        padding: 15px;
    }
}
@media  (max-width:992px) {
    .user-panel-body{
        padding: 40px 15px;
    }
}
@media (max-width:768px) {
    .user-panel-container{
        .user-panel-body{
            padding: 5px;
        }
        .calender-session-btn{
            position: relative;
            top: 25px;
        }
    }
}
