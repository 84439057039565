.form-file-picker-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width:768px) {
        padding-bottom: 2rem;
    }
}

.form-file-picker {
    width: 162px;
    height: 162px;
    border-radius: 162px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $battleshipGrey;
    &:hover {
        cursor: pointer;
    }
}

.form-file-picker-error {
    border: 2px solid red;
}
.form-file-picker-error-span {
    color: red;
}
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);  
    z-index:999;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  .popup_inner {
    position: fixed;
    height: fit-content !important;
    padding: 1.5rem;
    align-content: center;
    margin: auto;
    border-radius: 20px;
    background: white;
    text-align: center;

   @media (max-width:768px) {
        width: 99%;
        padding: 0.5rem;
       canvas{
           width: 100% !important;
       }
       .button button:first-child{
          margin: 5px;
       }
   }
  }
