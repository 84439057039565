.slot-container {
    @extend .d-flex;
    @extend .justify-content-between;
    margin: 0;
    user-select: none;
    box-shadow: 0 16px 10px 0 rgba(0, 0, 0, 0.05);
    height: 42px;
    margin-bottom: 13px;
    .tab-view {
        width: calc(100% - 70px);
        background-color: $white_solid;
        overflow: inherit;
        display: flex;
        border-radius: 6px;
        font-family: $primary_font_Medium;
        padding: 4px 5px;
        height: 49px;
        .tab-mobile {
            display: none;
        }
        .tabs {
            font-size: 19.2px;
            text-align: center;
            color: $battleshipGrey;
            font-family: $primary_font_SemiBold;
            cursor: pointer;
            margin: 0px 2.5px;
            background-color: $white;
            box-shadow: none;
            border-radius: 4px;
            min-width: 100px;
            width: 100px;
            height: 40.4px;
            p {
                margin: auto;
                white-space: nowrap;
                overflow: visible;
                text-overflow: unset;
                z-index: 3;
                font-size: 12px;
            }
        }
        .active {
            background-color: $darkMango;
            color: $white;
        }
        .first-slot {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        .last-slot {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        .selected {
            background-color: $darkMango;
            height: 40.4px;
            width: 106px;
            p {
                padding-left: 24.328px;
            }
        }
        .both {
            border-radius: 4px;
            .tabs {
                font-size: 16px;
                p {
                    padding-left: 0px;
                    font-size: 11px;
                }
            }
        }
        .tab-active {
            border-radius: 6px 6px 0px 0px;
            background-color: $primary_solid_color;
            color: $white_solid;
        }
    }

    .vl {
        border-left: 1px solid $battleshipGrey;
        height: 7px;
        opacity: 0.2;
        margin-left: -1.5px;
        position: relative;
        top: 40px;
    }
    .add-border-top-right-radius {
        border-top-right-radius: 0px !important;
    }

    .add-border-top-left-radius {
        border-top-left-radius: 0px !important;
    }
    @media (max-width: 768px) {
        box-shadow: none;
        height: auto;
        .tab-view {
            display: block;
            margin-top: 15px;
            width: 100%;
            padding: 0px;
            .tabs {
                display: none;
            }
            .tab-mobile {
                height: 54px;
                border-top: solid 1px $paleGrey;
                display: block;
                P {
                    margin-top: 7px;
                    font-size: 12px;
                    padding-left: 32.5px;
                    font-family: $primary_font_SemiBold;
                    color: $battleshipGrey;
                }
            }
            .selected {
                .tab-mobile {
                    background-color: $darkMango;
                    border-top: solid 1px $darkMango;
                    p {
                        color: $white_solid;
                    }
                }
            }
        }
    }
    @media (max-width: 414px) {
        .tab-view-title {
            width: 55px;
            font-size: 0.875rem;
            padding: 8px 0 8px 8px;
        }
        .vl {
            top: 30px;
        }
    }
}
