.tutor-cancellation-policy {
    @extend .position-relative;
    display: flex;
    justify-content: center;
    .onboarding-header {
        margin-bottom: 40px;
    }
    .details {
        margin: 0.5em;
        // display: flex !important;
        // justify-content: flex-start;
        color: $battleshipGrey;
        font-size: 0.875rem;
        font-family: $primary_font_Regular;
    }
    .centered-button {
        margin-top: 0 !important;
    }
    .cancel-policy {
        margin: 20px 0px;
        .header {
            margin: 0.5em;
            display: flex !important;
            justify-content: center;
            color: $black_solid;
            font-size: 1.3rem;
            font-family: $primary_font_Regular;
        }
        .description {
            @extend .header;
            margin: 0.5em 0;
            color: $battleshipGrey;
            font-size: 16px;
            display: block !important;
            font-family: $primary_font_Regular;
            justify-content: flex-start;
        }
    }

    @media (min-width: 1024px) {
    }
}

.policy-page-input {
    .input-container {
        position: relative;

        input {
            padding-left: 40px;
        }

        svg {
            position: absolute;
            margin-left: 20px;
        }
    }
}
