.mangoIcon{
    color: $darkMango;
}
.whiteIcon{
    color: $white_solid;
}
.grayIcon{
    color: $battleshipGrey
}
.rating{
    margin-right: 14px
}