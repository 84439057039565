.rating-container {
    display: flex;
    flex-direction: row;
    // background-color: red;
    justify-content: space-between;
}

.rating-icon {
    margin-right: 0.3rem;
}
.search-item {
    .rating-container {
        max-width: 100px;
    }
}