.custom-calendar-popper {
    width: 100%;
    transform: translate3d(11px, 90px, 0px) !important;
    z-index: 90 !important;

    & > * {
        font-family: "Montserrat-Medium";
        z-index: 90 !important;
    }

    .react-datepicker__month-container {
        width: 100%;
    }
    .react-datepicker-popper {
        transform: translate3d(10px, 94px, 0px) !important;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker {
        font-size: 1rem !important;
        background-color: #fff !important;
        color: $mine_shaft !important;
        border: 0 !important;
        border-radius: 7px 0px 0px 7px !important;
        box-shadow: 0 10px 10px 0 #0000000d;
        width: 95.5%;
    }
    .react-datepicker__header {
        background-color: white !important;
        border-bottom: 0 !important;
        border-radius: 0 !important;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        margin-top: 10px;
        margin-bottom: 10px;
        color: $darkMango !important;
        font-weight: bold;
        font-size: 1rem;
        font-family: "Montserrat-Medium";
    }
    .react-datepicker__day-name,
    .react-datepicker__day:not(.react-datepicker__day--keyboard-selected),
    .react-datepicker__day:not(.react-datepicker__day--today),
    .react-datepicker__time-name {
        color: $darkGrey;
        display: inline-block;
        width: 1.7rem;
        line-height: 2.1rem !important;
        text-align: center;
        margin: 0.5rem 1rem !important;
    }
    .react-datepicker__day-name {
        color: $mine_shaft !important;
        font-size: 0.95rem;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover
    {
        background-color: transparent !important;
        color: $darkMango !important;
    }
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover {
        background: none !important;
        color: $mine_shaft !important;
    }
    .react-datepicker__navigation {
        outline: none;
        top: 23px !important;
        border: 0 !important;
        width: 15px !important;
        height: 30px !important;
    }
    .react-datepicker__navigation--previous {
        background: url(../../assets/images/icons/left-arrow.svg) top center no-repeat !important;
        background-size: auto 17px !important;
        left: 30px !important;
    }
    .react-datepicker__navigation--next {
        background: url(../../assets/images/icons/right-arrow.svg) top center no-repeat !important;
        background-size: auto 17px !important;
        right: 30px !important;
        border-left-color: $darkMango !important;
    }

    .react-datepicker__day.datepicker__day--outside-month {
        color: $silver !important;
    }
    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today {
        font-weight: normal !important;
        color: $darkMango !important;
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled {
        cursor: default;
        color: #ccc !important;
    }
}

@media only screen and (max-width: 1070px) {
    .custom-calendar-popper .react-datepicker__day-name,
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--keyboard-selected),
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--today),
    .custom-calendar-popper .react-datepicker__time-name {
        width: 0.7rem;
        margin: 0.5rem 0.3rem !important;
    }
}
@media only screen and (max-width: 975px) {
    .custom-calendar-popper .react-datepicker__day-name,
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--keyboard-selected),
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--today),
    .custom-calendar-popper .react-datepicker__time-name {
        width: 0.7rem;
        margin: 0.5rem 0.9rem !important;
    }
}
@media only screen and (max-width: 768px) {
    .custom-calendar-popper .react-datepicker__day-name,
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--keyboard-selected),
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--today),
    .custom-calendar-popper .react-datepicker__time-name {
        width: 1.7rem;
        margin: 0.5rem 0.3rem !important;
    }
}
@media only screen and (max-width: 600px) {
    .custom-calendar-popper .react-datepicker__day-name,
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--keyboard-selected),
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--today),
    .custom-calendar-popper .react-datepicker__time-name {
        width: 0.7rem;
        margin: 0.5rem 0.3rem !important;
    }
    .custom-calendar-popper .react-datepicker {
        width: 93.1%;
    }
}
@media only screen and (max-width: 475px) {
    .custom-calendar-popper .react-datepicker__day-name,
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--keyboard-selected),
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--today),
    .custom-calendar-popper .react-datepicker__time-name {
        width: 0.7rem;
        margin: 0.5rem 1rem !important;
    }
}

@media only screen and (max-width: 400px) {
    .custom-calendar-popper .react-datepicker__day-name,
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--keyboard-selected),
    .custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--today),
    .custom-calendar-popper .react-datepicker__time-name {
        width: 0.7rem;
        margin: 0.5rem 0.8rem !important;
    }
}
.react-datepicker__input-container {
    position: relative;
    &:after {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        top: 24px;
        right: 20px;
        background: url(../../assets/images/icons/calendar.svg) top center no-repeat;
        background-size: 20px;
        pointer-events: none;
    }
    &>* {
        cursor: pointer;
    }
}
