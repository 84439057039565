.educational-background-page-container {
    @extend .d-flex;
    @extend .justify-content-center;
}
.educational-background-page-subcontainer {
    .custom-input{
        margin-left: 0;
        padding: 15px 0;
    }
    .form-container {
        @extend .d-flex;
        @extend .flex-row;
        .first-input {
            width: 65%;
        }
        .last-input {
            width: 35%;
        }
    }
}

@media (max-width: 413px) {
    .educational-background-page-subcontainer {
      
        .button-container {
            position: relative;
        }
        .form-container {
            flex-direction: column !important;
            .first-input {
                width: 100%;
            }
            .last-input {
                width: 100%;
            }
        }
    }
}
@media (min-width: 414px) and (max-width: 768px) {
    .educational-background-page-subcontainer {
     
        .button-container {
            position: fixed;
        }
        .form-container {
            flex-direction: column !important;
            .first-input {
                width: 100%;
            }
            .last-input {
                width: 100%;
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .educational-background-page-subcontainer {
   
        .form-container {
            flex-direction: column !important;
            .first-input {
                width: 100%;
            }
            .last-input {
                width: 100%;
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1340px) {
    .educational-background-page-subcontainer {
   
        .form-container {
            flex-direction: row !important;
            .first-input {
                width: 65%;
            }
            .last-input {
                width: 35%;
            }
        }
    }
}
