.dropdown-wrapper {
   

    &.simple-dropdown {
        padding: 0 15px;
        margin: 0;
        min-height: 120px;
        @media (max-width:992px){
            padding: 15px 0;
        }

        label {
            margin: 0;
        }
    }
    .input-textfield {
        & .MuiOutlinedInput-root {
            & fieldset {
                padding-left: 20px;
                border: 0px solid #ffffff;
                height: 65px;
                width: "100%";
                border-radius: "7px 7px";
                box-shadow: "0 10px 10px 0 #0000000d";
            }

            &.Mui-focused fieldset {
                border-color: $darkMango;
            }
        }
        input{
            -webkit-user-select: all !important;
        }
    }

    .options-box p {
        margin-left: 0;
        margin-right: 1.5rem;
    }
    & .MuiInput-underline:before {
        display: none;
        border: 0;
    }

    .input {
        position: relative;
        .error {
            position: absolute;
            height: 65px;
            width: 7px;
            background: #ff0000;
            right: 0;
            z-index: 10;
            top: 0;
            border-radius: 0 7px 7px 0;
        }
        > div > div{

            background-color: white;
        }
        input {
            margin-top: -10px;
        }
        input::placeholder {
            font-family: $primary_font_Regular;
            font-size: 16px;
            color: $battleshipGrey;
            opacity: 1;
        }
        .MuiInput-underline.Mui-error:after {
            transform: scaleX(0);
            display: none;
        }
        .MuiInputLabel-formControl {
            top: 50%;
            left: 20px;
            position: absolute;
            transform: none;
            z-index: 1200;
        }
    }
    & .MuiAutocomplete-endAdornment {
        right: 20px;
        top: 25px;
    }
    & .MuiFormHelperText-root {
        color: #ff0000;
        font-size: 14px;
        line-height: 20px;
        min-height: 20px;
        font-family: $primary_font_Regular;
        text-align: right;
    }
    .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty {
        display: none;
    }

    & .MuiAutocomplete-popupIndicator {
        span {
            display: none;
        }
        &::before {
            @include pseudo-element;
            width: 15px;
            height: 15px;
            background: url("../../assets/images/search.svg") center center no-repeat;
            background-size: contain;
            right: 7px;
        }
    }
    & .MuiAutocomplete-popupIndicatorOpen {
        &::before {
            @include pseudo-element;
            width: 16px;
            height: 16px;
            background: url("../../assets/images/close.svg") center center no-repeat;
            background-size: contain;
            margin-top: -4px;
            right: -12px;
        }
    }
}
.MuiAutocomplete-popper {
    z-index: 99999 !important;
    background-color: white;
}

.positioned-dropdown .dropdown-wrapper .input .error {
    top: 15px;
}
.MuiFormLabel-root.Mui-error {
    color: $battleshipGrey !important
}

.MuiListItem-button:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: white !important;
}
ul.MuiList-root.MuiMenu-list.MuiList-dense{
    height: 125px !important;
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.makeStyles-menuItem-16.MuiMenuItem-gutters.MuiListItem-dense.MuiMenuItem-dense.MuiListItem-gutters.MuiListItem-button, li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.makeStyles-menuItem-28.MuiMenuItem-gutters.MuiListItem-dense.MuiMenuItem-dense.MuiListItem-gutters.MuiListItem-button {
    padding: 0.7rem 1rem !important;
}
.input-label-blue{
 color :$blue
}
.makeStyles-menuItem-11 {
    padding: 0.75rem !important;
}