.rapid-booking {
    .separator {
        background-color: $darkMango;
        margin: 16.9px 0px 14.5px 0px;
        width: 100%;
        height: 2px;
    }
    .rapidbook-toggle-section {
        font-family: $primary_font_Medium;
        font-size: 20px;
        .title {
            font-family: inherit;
            font-size: inherit;
            font-weight: 600;
            .now {
                color: #d90c0c;
            }
        }
        .rapid-book {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-left: 0;
            .switch-component {
                margin-right: 10px;
            }
            .clock {
                margin-right: 10px;
                fill: $vividBlue;
                width: 31px;
                height: 29px;
                font-size: 20px;
                line-height: 28px;
            }
            .title {
                margin-right: 10px;
                color: $vividBlue;
                font-size: 20px;
                line-height: 28px;
            }
            .info {
                margin-right: 10px;
                color: $vividBlue;
                font-size: 20px;
                line-height: 28px;
                width: 21px;
                height: 21px;
            }
            .warning {
                font-family: $primary_font_Medium;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}
