.video-player {
    position: relative;
    video {
	max-width: 100%;
	border-radius: 8px;
	z-index: 1;
    }
    .video-player-controls {
	z-index: 2;
	height: -webkit-fill-available;
	width: -webkit-fill-available;
		.button {
			position: absolute;
			top: 50%;
			left: 50%;
			button {
			z-index: 99;
			transform: translate(-60px,-60px);
			height: 120px;
			width: 120px;
			border-radius: 50%;
				svg {
					height: 70px;
					width: 70px;
				}
			}		
			@media (max-width: 959.95px){
				button {
					height: 60px;
					width: 60px;
					transform: translate(-30px,-30px);
					svg {
						height: 35px;
						width: 35px;
					}
				}
			}
		}
    }
}
