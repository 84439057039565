.onboarding-header {
    @extend .d-flex;
    flex-direction: column;
    padding: 25px 0px 30px 0px;
    .heading {
        @extend .normal-font-style;
        @extend .font-weight-normal;
        @extend .text-center;
        font-family: $primary_font_SemiBold;
        margin-top: 20px;
        font-size: 18px;
        line-height: 1.56;
        color: $battleshipGrey;
    }
    .sub-heading {
        @extend .normal-font-style;
        @extend .text-center;
        font-family: $primary_font_Medium;
        font-size: 40px;
        font-weight: 500;
        line-height: 1.3;
        color: $darkGrey;
    }

    @media (max-width: 1024px) {
        padding: 15px 0px 20px 0px;
    }
    @media (max-width: 768px) {
        .sub-heading {
            font-size: 40px;
        }
    }
}

@media (max-width: 320px) {
    .onboarding-header {
        .heading {
            font-size: 15px;
        }
        .sub-heading {
            font-size: 34px;
        }
    }
}

@media (min-width: 414px) and (max-width: 768px) {
    .onboarding-header {
        .heading {
            font-size: 18px;
        }
        .sub-heading {
            font-size: 48px;
        }
    }
}

@media (max-width: 414px) {
    .onboarding-header {
        .sub-heading {
            font-size: 30px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .onboarding-header {
        .heading {
            font-size: 18px;
        }
        .sub-heading {
            font-size: 48px;
        }
    }
}
@media (min-width: 1024px) {
}
.onboarding-subheader {
    margin: 2rem 0;
    font-size: 1rem;
    display: block;
    .heading {
        font-weight: 500;
    }
    .sub-heading {
        color: $battleshipGrey;
        font-weight: 300;
        .navigation {
            display: inline-block;
            margin-left: 5px;
            color: $darkMango;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
.gutter-top {
    margin-top: 2.2rem;
}
.login-flow {
    @extend .d-inline-flex;
    @extend .position-relative;
    background-color: $paleGreyTwo;
    min-height: 100vh;
    min-width: 100%;
    .login-flow-container {
        display: flex;
        justify-content: center;
        margin: auto;
        align-items: center;
        @media (max-width: 1024px) {
            display: block;
        }

        .card-view {
            border-radius: 25px 0;
        }
        .login-flow-form {
            width: 505px;
            u {
                @extend .d-block;
                // @extend .text-right;
                @extend .position-relative;
                text-align: right;
                padding-bottom: 25px;
            }
            button {
                @extend .d-block;
            }
        }
    }
    &.forgot-password {
        .login-flow-container {
            .login-flow-form {
                button {
                    margin-top: 30px;
                }
            }
        }
    }
    .background-container {
        z-index: 0;
    }
}
.login-flow.sign-in {
    .login-flow-container {
        .login-flow-form {
            .error-message {
                color: red;
                text-align: center;
                padding: 0 20px;
            }
            u {
                color: $black_solid;
                padding-right: 12px;
            }
            button {
                @extend .d-block;
            }
        }
    }
}
@media (max-width: 320px) {
    .login-flow {
        z-index: 20;
        background-color: $darkMango;
        background-image: url(../../assets/images/onboarding-card/triangle-shape.svg);
        background-repeat: no-repeat;
        background-position: top 44px left 234px;

        &::before {
            @include pseudo-bg;
            width: 100vw;
            height: 100vh;
            clip-path: circle(100% at 100% 100%);
        }
        .login-flow-container {
            margin: 0 auto;
            width: 300px !important;
            .login-flow-form {
                width: 220px;
            }
        }
    }
}

@media (min-width: 321px) and (max-width: 413px) {
    .login-flow {
        z-index: 20;
        background-color: $darkMango;
        background-image: url(../../assets/images/onboarding-card/triangle-shape.svg);
        background-repeat: no-repeat;
        background-position: top 44px left 234px;

        &::before {
            @include pseudo-bg;
            width: 100vw;
            height: 100vh;
            clip-path: circle(100% at 100% 100%);
        }
        .login-flow-container {
            margin: 0 auto;
            width: 340px !important;
            .login-flow-form {
                width: 260px;
            }
        }
    }
    .login-flow.mobile-bg::before {
        clip-path: circle(80% at 100% 100%);
    }
}
@media (min-width: 414px) and (max-width: 768px) {
    .login-flow {
        z-index: 20;
        background-color: $darkMango;
        background-image: url(../../assets/images/onboarding-card/triangle-shape.svg);
        background-repeat: no-repeat;
        background-position: top 44px left 234px;

        &::before {
            @include pseudo-bg;
            width: 100vw;
            height: 100vh;
            clip-path: circle(100.5% at 87% 100%);
        }
        .login-flow-container {
            margin: 0 auto;
            width: 350px;
            .login-flow-form {
                width: 270px;
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .login-flow {
        z-index: 20;
        background-color: $darkMango;
        background-repeat: no-repeat;
        background-image: url(../../assets/images/onboarding-card/triangle-shape.svg),
            url(../../assets/images/onboarding-card/rectagle-shape.svg);
        background-position: top 35px left 239px, top 310px left 20px;

        &::before {
            @include pseudo-bg;
            width: 100vw;
            height: 100vh;
            clip-path: circle(100% at 50% 130%);
        }
        .login-flow-container {
            margin: 0 auto;
        }
    }
}
@media (min-width: 1024px) and (max-width: 1070px) {
    .login-flow {
        .login-flow-container {
            .login-flow-form {
                width: 480px;
            }
        }
    }
}
.mt-30 {
    margin-top: 30px;
}
.onboarding-actions {
    max-width: 100%;
    margin: 0;
    padding-bottom: 60px;
    .button-container {
        .button {
            button {
                font-size: 27.2px;
                width: 200px;
                height: 48px;
                border-radius: 42px;
                border-color: $blue;
            }
            .outline-button {
                color: $blue;
            }
            .no-outline-button {
                background-color: $blue;
            }
        }
        span:first-child {
            button {
                font-family: $primary_font_SemiBold;
                font-size: 16px;
            }
        }
        span:last-child {
            button {
                font-family: $primary_font_Bold;
                font-size: 19px;
            }
        }
    }
    @media (max-width: 768px) {
        margin-bottom: 60px;
        .button-container {
            display: flex !important;
            flex-direction: unset !important;
        }
    }
    @media (max-width: 414px) {
        margin-bottom: 0;
        .button-container {
            display: flex !important;
            flex-direction: column-reverse !important;
            span:first-child {
                position: relative;
                left: 0;
            }
            span:last-child {
                right: 0;
                position: relative;
            }
        }
    }
}
