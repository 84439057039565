.modal-box {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .flex-column;
    padding: 20px;
    .capitalize {
        text-transform: capitalize;
    }

    img {
        @extend .m-3;
        width: 8rem;
    }
    .avatarsstr{
        img{
            margin: 0 !important;
        }
    }
    h2 {
        font-family: $primary_font_Medium;
        font-size: 33px;
        color: $battleshipGrey;
        text-align: center;
        margin-bottom: 20px;
    }
    p {
        font-family: $primary_font_Regular;
        font-size: 1.2rem;
        color: $darkMango;
        text-align: center;
    }
}

.modal-buttons {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .flex-row;
    max-width: fit-content;
    flex-direction: row !important;
}

.modal-button {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

@media (max-width: 768.95px) {
    .modal-buttons {    
        flex-direction: column !important;
    }
    .modal-box{

        img{
            width: 6.5rem;
        }
        h2{
            font-size: 25px;
        }
    }
}


.modal-tip-info {
    .body{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: $battleshipGrey;
        .info-icon{
            width: 50px !important;
            height: 50px !important;
            color: $darkMango;
        }
        .content {
            color: $battleshipGrey;
        }
    }
    .action-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button {
            width: 150px;
        }
    }
}