@import "utility";

.normal-font-style {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}
.error-message {
    color: red;
    text-align: center;
    padding: 0 20px;
}
@mixin pseudo-element($display: block, $pos: absolute, $content: "") {
    content: $content;
    display: $display;
    position: $pos;
}
@mixin pseudo-bg {
    z-index: -1;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.15);

    @media only screen and (min-width: 1440px) {
        background-color: rgba(255, 255, 255, 0.05);
    }
}

.page-content {
    min-height: calc(100vh - 260px);
}

.datepicker-wrapper {
    margin: 0.63rem 0 1.5rem 0;

    &.p-30 {
        padding: 0 30px;
    }
    &.p-15 {
        padding: 0 15px;
    }
    .datepicker-root {
        label {
            color: $darkGrey;
        }
        & * {
            cursor: pointer;
        }
        input:disabled {
            cursor: not-allowed;
        }
    }
    .mui-datepicker-input {
        width: 100%;
        position: relative;
        border: 0px;
        height: 65px;
        box-shadow: 0 10px 10px 0 #0000000d;
        background: white;
        width: 100%;
        padding: 10px;
        border-radius: 7px 0px 0px 7px;

        &:after {
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            top: 24px;
            right: 20px;
            background: url(../images/icons/calendar.svg) top center no-repeat;
            background-size: 20px;
            pointer-events: none;
        }
        &.Mui-error {
            border-right: 7px solid #f00;
            border-radius: 7px;
        }
    }
    .MuiFormHelperText-root.Mui-error {
        text-align: right;
        color: #ff0000;
        font-size: 14px;
        line-height: 20px;
        min-height: 20px;
        font-family: $primary_font_Regular;
    }
    .MuiInputBase-input::-webkit-input-placeholder {
        color: darken($color: $battleshipGrey, $amount: 20%);
        padding-left: 7px;
        opacity: 1;
        font-family: $primary_font_Light;
    }
    .MuiInputBase-input:disabled::-webkit-input-placeholder,
    .MuiInputBase-root.Mui-disabled {
        background: #fafafa;
    }
}

.custom-modal {
    display: flex;
    justify-content: center;
    .close-modal-button {
        text-align: end;
        color: $darkMango;
        
        svg {
            cursor: pointer;
        }

        &.gray-bg {
            margin: 25px;
        }
    }
    .button button {
        width: auto;
        padding: 5px 30px;
    }
    .custom-modal-body {
        overflow-y: auto;
        display: flex;
        height: auto;
        // width: 100%;
        .custom-modal-content {
            margin: auto;
            padding: 25px;
            width: 75vw;
            max-width: 800px;
            height: auto;
            background: white;
            border-radius: 25px 0px;
            margin: 70px auto 10px;
            @media (max-width:992px) {
                overflow-y: scroll;
            }
        }
    }
}

.float_auto {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-column-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 50px;
    .button-container{
        padding-top: 10%;
    }
}
.onboarding-divider {
    width: 100%;
    height: 60px;
    display: block;
    @media (max-width:768px) {
        height: 0;
    }
}

abbr[title],
abbr[data-original-title] {
    cursor: pointer !important;
}

.MuiTooltip-popper {
    z-index: 1300 !important;
    .MuiTooltip-tooltip{
        font-size: 18px!important;
    }
}

* {
    user-select: none !important;
}

.my-account-page .my-page-content .payment-tab-content .next-payment{
    font-size: 18px;
}

.my-account-page .my-page-content .payment-tab-content .last-payment{
    font-size: 18px;
    color: #2d3234;
}
.my-account-page .my-page-content .payment-tab-content .next-payment .large{
    font-size: 60px;
}
.my-account-page .my-page-content .payment-tab-content .last-payment .p-grey{
    color: #686c80 !important;
}
.view-history-btn button {
    width: 304px !important;
    height: 54px !important;
    border-radius: 30px !important;
    font-size: 18px !important;
    @media (max-width:414px) {
        width: 250px !important;
    }
}
.header-menu {
    background-color: $darkMango;
    height: 60px;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    @media (max-width:767px) {
        height: 75px;
    }
}
.toggle_filter_search {
    display: none;
}
.clear{
    clear: both;
}
.search_inner{
    display: none;
}

.reschedule-time-modal{
    p{
        font-size: 18px;
        span{
            font-weight: 600;
            color: #ff5924;
        }
    }
}
.text-warning {
    color: #f28334 !important;
}
@media screen and (max-width: 768px){
    .payment-tab-content .MuiGrid-grid-xs-12{
        max-width: 100%;
        flex-basis: 100%;
        margin: 5px 0px;    
    }
    .my-account-page .my-page-content .payment-tab-content .next-payment .large {
        font-size: 48px;
    }
    .my-account-page .my-page-content .payment-tab-content .last-payment .p-grey {
        font-size: 26px;
    }
    .tabs-container .sort-label-container .sort-active-label{
        color: $darkMango;
    }
    .highlight-active div{
        width: 100% !important;
    }


}
@media screen and (max-width: 768px){
    .navigator-page .sidenav, .navigator-page .search-results-panel{
        flex: 1 1 100% !important;
        max-height: none;
    }
    .navigator-page .sidenav {
        margin-top: 2em; 
        margin-bottom: 5em;   
    }
    .navigator-page .sidenav .filters-panel{
        padding: 1em 1em 0 1em;
        width: 100%;
        margin: auto;  
    }

    .hide-main-filter > div + .sidenav{
        display: none;
        position: relative;
    }

    .hide-main-filter > div + div + .search-results-panel, .hide-main-filter > .toggle_filter_search{
        display: flex; 
    }
    .hide-main-filter > div + div + .search-results-panel{
        height: inherit;
        max-height: 100vh;
    }
    .show-main-filter > div + .sidenav {
        display: block;
        position: relative;
    }
    .navigator-page .sidenav .filters-panel .filter-panel-header .clear-label {
        display: none;
    }
    .show-main-filter > div + div + .search-results-panel, .show-main-filter > .toggle_filter_search {
        display: none;
    }
    .sidenav .filters-panel {
        position: relative;
    }
    

    .sidenav .tutor-search-filter .custom-input .input-label{
        max-width: 450px;
        margin-right: 20px;
    }
    .toggle_filter_search {
        position: relative;
        top: 20px;
        left: 0;
        right: 0;
        width: 100% !important;
        line-height: normal;
        height: 50px !important;
        .filter_search_heading{
            float: left;
            color: $darkMango;
            font-size: 32px;
        }
        .filter_search_icon{
            float: right;
            svg{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                cursor: pointer;
            }
        }
        .content-container {
            display: block;
            margin: auto;
        }
    }
    .search_inner {
        display: block;
        position: relative;
        top: 0%;
        left: 0;
        right: 0;
        width: 100% !important;
        .filter_search_heading{
            float: right;
            color: $darkMango;
            font-size: 32px;
        }
        .filter_search_icon{
            float: left;
            position: relative;
            top: 10px;
            svg{
                background-color:$blue;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                padding: 10px;
                cursor: pointer;
            }

        }
        .content-container {
            display: block;
            margin: auto;
            .clear-label {
                color: $vividBlue;
                text-decoration: underline;
                font-size: 18px;
                cursor: pointer;
                font-family: $primary_font_Bold;
                margin-right:25px;
                float :right;
                margin-top: 21px;
            }
        }
    }   
    .header-menu {
        display: block !important;
        z-index: 999999;
    }   
    .sidebar {
        display: none !important;
    }
    .panel {
        .panel-body {
            margin-left: 0 !important;
            .navigator-page{
                flex-flow: wrap;        
            }
        }
    }
    
    .sidenav .filter-panel-header .page-header{
        display: none;
    }
    .search-results-panel .content-container{
        margin: 20px auto 0;
    }
    .navigator-page .content-container {
        padding: 1em 1em 0 1em;
        width: 100%;
    }
    #mySidenav .search-button-container .button-updated{
        width: auto !important;
    }
    #mySidenav .search-button-container .button-updated button{
        position: fixed;
        bottom: 20px;
        margin-top: 5px;
        width: 340px !important;
        height:54px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    #mySidenav .search-button-container .button-updated button > div{
        font-size: 0 !important;
        position: relative;
    }
    #mySidenav .search-button-container .button-updated button:before{
        content: "See Results";
    }
    #mySidenav .search-button-container .button-updated button:disabled:before{
        content: "";
    }
    .search-results-panel .search-item .tutor-show-name-rating {
        width: 25% !important;
        float: left;
    }           
    .search-results-panel .search-item .subjects-hours {
        width: 25% !important;
        padding: 10px 0  !important;
        height: auto  !important;
        float: left  !important;
    }
    .search-results-panel .search-item .search-item-tutor-hours {
        height: auto !important;
        display: flex !important;
        width: 15% !important;
        float: left;
    }
    .search-results-panel .search-item .search-item-view-profile {
        height: unset !important;
        display: flex !important;
        text-align: right  !important;
        max-width: 150px  !important;
        float: right  !important;
    }
}
@media screen and (max-width: 767px){
    .search-results-panel .search-item .tutor-show-name-rating {
        width: 100% !important;
        float: left;
    }           
    .search-results-panel .search-item .subjects-hours {
        width: 100% !important;
        padding: 10px 0  !important;
        height: auto  !important;
        float: left  !important;
    }
    .search-results-panel .search-item .search-item-tutor-hours {
        height: auto !important;
        display: flex !important;
        width: 100% !important;
        float: left;
    }
    .search-results-panel .search-item .search-item-view-profile {
        height: unset !important;
        display: flex !important;
        text-align: right  !important;
        max-width: 150px  !important;
        float: right  !important;
    }
}


@media screen and (max-width: 1024px){
    .account-profile .MuiGrid-grid-lg-6 {
        flex-grow: 0;
        max-width: 100% !important;
        flex-basis: 100% !important;
        margin-left: 20px;
    }    
    .account-profile .MuiGrid-grid-lg-4, .MuiGrid-align-items-xs-baseline > div{
        flex-grow: 0;
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
    .year-handle .drop-w-button{
        position: relative;
        top: -3px;
    }
}
@media screen and (max-width: 768px){
    .account-profile .MuiGrid-root:nth-child(2) .MuiGrid-root:last-child .custom-input{
        padding-right: 20px;
    }
    .account-profile .datepicker-wrapper.p-15{
        padding-right: 20px !important;
    }
    .account-profile .custom-input{
        padding-right: 10px;
    }
    .account-profile .MuiGrid-grid-lg-6{
        margin-left: 0;
    }
    .switch-tabs .tabs-container .tab{
        width: auto !important;
    }
    .session-time-modal .session-time-selector-wrapper .time, .session-time-modal .session-time-selector-wrapper .duration {
        float: left;
        width: 48%;
        margin: 0px 1%;
        min-width: auto;
    }

    .session-time-modal .session-time-selector-wrapper .session-time-selector-input:first-child{
        width: 98%;
    }
    .session-time-modal .session-time-selector-wrapper  span.button {
        clear: both;
        display: block;
    }
    .filters-panel-container .search-button-container .button-updated{
        width: 200px !important;
        padding-top: 45px;
    }
    .search-button-container{
        
        .mobile-button-background{
        position: fixed !important;
        bottom:0px;
        background-color: #fcfcfcd6;
        height: 100px;
        width: 100%;
        }
    }  
}
@media screen and (max-width: 414px){
   
    .custom-modal-content-p{
        height: 400px !important;
        overflow-y: scroll;
        word-break: break-word;
        position: relative;
        top: 2%;
    }
    .p-5 {
        padding: 0rem!important;
    }
    .tabs-container .sort-label-container {
        vertical-align: bottom;
        max-width: 100%;
        flex-basis: 100%;
        text-align: center;
    }
    .tabs-container .sort-label, sort-active-label {
        margin-right: 0px !important;
    }
    .tabs-container .sort-label-container > div {
        margin: 0 !important;
    }
    .tutor-account .switch-tabs .tabs-container .tab{
        width: auto !important;
        padding: 0px 7px !important;
    }
    .toggle_filter_search{
        top: 20px;
    }
    .search-results-panel .content-container {
        margin-top: 20px;
    }
    .search_inner{
        top: -1%;
    }
    .navigator-page{
        padding-top: 0px;
        position: relative ;
    }
    .search_inner .filter_search_icon svg{
        width: 40px;
        height: 40px;
    }
    .sidenav .tutor-search-filter .custom-input .input-label {
        max-width: 280px;
        font-size: 16px;
    }

}

@media screen and (max-width: 320px){
    .navigator-page .content-container, .navigator-page .sidenav .filters-panel {
        padding-right: 3em;
    } 
    .navigator-page .sidenav{
        margin-bottom: 8em;
    }
}

.account-profile .datepicker-wrapper.p-15{
    @media (max-width:768px) {
        padding-left: 0 !important;
    }
}
@media (max-width:576px) {
    .navigator-page .sidenav .filters-panel .filter-panel-header .clear-label{
        display: none;
    }
}
#menu #tools{
    padding-top: 40px !important;
}
#menu .tools{
    padding-top: 40px !important;
}