.teacher-myprofile {
    .teacher-myprofile-form {
        .person-name{
            input{
                text-transform: capitalize;
            }
        }
    }
    .phone-number {
        box-shadow: 0 10px 10px 0 #0000000d;
        width: 100%;
        border-radius: 10px;
        background-color: $white_solid;
        input {
            -webkit-user-select: all !important;
        }
        fieldset {
            display: none;
        }
    }
    .other-registration {
        @extend .overflow-hidden;
        @extend .text-center;
        margin: auto;
        max-width: 550px;
        position: relative;
        padding: 30px 0;
        margin-bottom: 5rem;
        color: $battleshipGrey;

        span {
            display: inline-block;
            background-color: white;
            padding: 0 10px;
            @extend .normal-font-style;
            @extend .font-weight-normal;
            @extend .text-center;
            font-family: $primary_font_Regular;
            font-size: 16px;
            line-height: 1.56;
            color: $battleshipGrey;
        }
        &:after {
            content: "";
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            border-bottom: 0.13rem solid $paleGrey;
            z-index: -1;
        }
    }
    
    .teacher-myprofile-options {
        @extend .text-center;
        @extend .position-relative;
        margin: 70px 0 29px 0;
        img {
            width: 60px;
            height: 60px;
            margin: 0px 17px;
        }        
    }
}
.firstNameCol{
    padding-right: 15px !important;
    @media (max-width:768px){
        padding-right: 0 !important;
    }}