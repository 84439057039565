.flex {
    display: flex;
}

.margin-auto {
    margin: auto;
}

.subscription-optins {
    .custom-check .container {
        font-family: $primary_font_Bold;
        font-weight: 600;
    }
}

.account-profile {
    .phone-number-wrapper {
        padding: 20px 0px 0px 20px;

        .phone-number {
            box-shadow: 0 10px 10px 0 #0000000d;
            width: 100%;
            border-radius: 10px;
            background-color: $white_solid;

            input {
                -webkit-user-select: all !important;
            }

            fieldset {
                display: none;
            }
        }
    }

    .subscription-optins {
        .custom-check .container {
            margin-left: 10px;
            font-size: 14px;
        }
    }

    @media (min-width: 2560px) {
        .subscription-optins {
            .custom-check .container {
                font-size: 34px;
            }
        }
    }

    @media (max-width: 768px) {
        .phone-number-wrapper {
            padding: 10px 0px;
        }
    }
}

.tutor-account {
    max-width: 95% !important;

    .phone-number-wrapper {
        padding: 20px 0px 0px 10px;

        .phone-number {
            box-shadow: 0 10px 10px 0 #0000000d;
            width: 100%;
            border-radius: 10px;
            background-color: $white_solid;

            input {
                -webkit-user-select: all !important;
            }

            fieldset {
                display: none;
            }
        }

        @media (max-width: 768px) {
            padding: 20px 0px 20px 0px;
        }
    }

    .subscription-optins {
        .custom-check .container {
            font-size: 14px;
            line-height: 20px;
        }
    }

    @media (min-width: 2560px) {
        .subscription-optins {
            .custom-check .container {
                font-size: 34px;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1200px) {
        .account-head {
            width: 100%;
        }

        .my-account-tabs-container {
            width: 100%;
        }
    }

    @media (max-width: 1024px) {
        .account-profile {
            .plus_button {
                margin-left: 0;

                img {
                    margin-left: 0;
                }
            }
        }

        .file-wrapper {
            padding-left: 0;
            padding-right: 0;

            &>.input-label {
                margin-left: 0 !important;
            }
        }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        .education-wrapper {
            .simple-dropdown {
                padding-left: 0 !important;
            }
        }

        .on-dashboard-profile {
            max-width: 50% !important;
            padding-left: 0;

            &:nth-of-type(2) {
                padding-right: 0;
            }

            .custom-input {
                padding-left: 0;
            }
        }

        .tutor-license-inputs {
            .datepicker-wrapper {
                padding-left: 0 !important;
            }

            .MuiGrid-grid-lg-6 {
                margin-left: 0;
                padding-right: 15px !important;
                max-width: 50% !important;

                &:nth-child(even) {
                    padding-right: 0;
                }

                .custom-input {
                    padding-left: 0 !important;
                }
            }
        }

        .Bio {
            .custom-input {
                padding-left: 0 !important;
            }
        }

        .year-handle {
            max-width: 50% !important;

            .simple-dropdown {
                padding-left: 0 !important;
            }
        }

        .subjects-and-tests {
            &>.MuiGrid-grid-md-4 {
                max-width: 50%;
                flex-basis: 50%;
                padding: 20px 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        max-width: 100% !important;

        .account-profile {
            width: 100%;
            margin: auto;
            max-width: 510px;

            .datepicker-wrapper {
                padding-right: 0 !important;
            }
        }

        .onboarding-subheader {
            margin: 0;
            font-size: 0.9rem;
        }

        .my-account-tabs-container {
            margin: auto !important;
            width: 100%;
        }

        .switch-tabs {
            margin: 35px auto auto;
            max-width: 510px;
            width: 100% !important;
            float: none !important;

            .active-tab,
            .tab {
                width: 33% !important;
            }

            .tabs-container {
                .tab {
                    width: 33% !important;
                }
            }
        }

        .custom-input,
        .simple-dropdown,
        .datepicker-wrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .time-zone {
            .simple-dropdown {
                width: 100% !important;
            }
        }

        .on-dashboard-profile {
            padding: 0 !important;
        }

        .year-handle {
            .drop-w-div .simple-dropdown {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .drop-w-button {
                display: none;
            }
        }

        .subjects-and-tests {
            width: 100%;
            margin: 20px 0;
            padding: 15px 0;

            &>div {
                padding-left: 0;
                padding-right: 0;
            }

            .notext-button {
                border-radius: 50px;
            }
        }
    }
}

.student-account {
    padding: 20px !important;
    margin-top: 10px !important;

    .my-page-content .tab-heading {
        font-size: 26px !important;
        margin-top: 40px !important;
    }

    @media (max-width: 1024px) {
        .about-me {
            order: 1;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {

        /* .account-head{
            width: 100%;
        }*/
        .my-account-tabs-container {
            width: 100%;
            display: flex;
            margin: auto;
            justify-content: center;
        }
    }

    @media (max-width: 768px) {
        .account-head {
            width: 100%;
        }

        .my-account-tabs-container {
            width: 100%;
            max-width: 510px;
            margin: 15px auto auto;
        }

        .account-profile {
            width: 100%;
            max-width: 510px;
            margin: auto;
        }

        .onboarding-subheader {
            margin: 0;
            font-size: 0.9rem;
        }

        .switch-tabs {
            width: 100% !important;

            .active-tab,
            .tab {
                width: 33% !important;
            }

            .tabs-container {
                .tab {
                    width: 33% !important;
                }
            }
        }

        .account-profile {
            .custom-input {
                padding-left: 0 !important;
                padding-top: 10px !important;
                padding-bottom: 10px !important;
                padding-right: 0 !important;
            }

            .simple-dropdown {
                padding-left: 0 !important;
                padding-right: 0 !important;
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }

            .datepicker-wrapper.p-15 {
                padding-left: 0 !important;
                padding-top: 10px !important;
                padding-bottom: 10px !important;
                margin-bottom: 0 !important;
            }

            .datepicker-wrapper {
                padding-left: 0 !important;
            }

            .button {
                padding-top: 40px;
            }
        }

        &>.MuiGrid-item {
            padding: 0 !important;
        }

        .account-profile .MuiGrid-spacing-xs-3 {
            width: 100% !important;

            .custom-input {
                padding-right: 0 !important;
            }

            .datepicker-wrapper {
                padding-right: 0 !important;
            }
        }

        .user-profile-revervse-col {
            width: 100% !important;

            .custom-input {
                padding-right: 0 !important;
            }

            .about-me {
                order: -1;
            }
        }
    }

    /* @media (min-width:415px) and (max-width:768px) {
        .my-account-tabs-container {
            width: 85%;
            margin: 15px auto 0px;
        }
    } */
}

.my-account-page {
    padding: 20px 0 0 0;
    margin-top: 50px;

    .my-account-tabs-container {
        .switch-tabs {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: fit-content;
            float: right;
            max-width: 100%;

            .tabs-container {
                height: 60px;
                flex: 0 auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-justify: center;

                .tab {
                    font-size: 14px !important;

                    @media (min-width: 768px) {
                        width: 180px !important;
                    }
                }

                .active-tab {
                    font-size: 14px !important;
                }
            }
        }
    }

    .button .notext-button {
        width: 62px;
        height: 62px !important;
    }

    .button button {
        height: 45px;
        border-radius: 31px;
        font-size: 18px;

        @media (max-width: 768px) {
            height: 48px;
            border-radius: 26.3px;
            font-size: 16px;
        }
    }

    >.MuiGrid-item {
        @media (max-width: 768px) {
            margin: 0;
        }
    }

    .switch-tab-design {

        .active-tab,
        .tab {
            width: 180px;
            height: 60px !important;
        }
    }

    .display-flex {
        display: flex;
    }

    .margin-auto {
        margin: auto;
    }

    .float-right {
        float: right;
    }

    .text-center {
        text-align: center;
        padding-left: 2px;
        padding-right: 2px;
    }

    span.card-type {
        background-color: $battleshipGrey;
        padding: 5px;
        color: $white_solid;
        font-style: italic;
        font-size: 12px;
    }

    .modal-title {
        font-family: $primary_font_Medium;
        font-size: 24px;
        color: $battleshipGrey;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    h1 {
        color: $darkMango;
        font-size: 38px;

        @media (max-width: 768px) {
            font-size: 32px;
        }
    }

    .my-page-content {
        // margin-left: 25px;
        // margin-right: 25px;
        width: auto;
        padding: 25px 50px;

        @media (max-width: 1024px) {
            padding: 0;
        }

        .tab-heading {
            margin-top: 40px;
            margin-bottom: 0px;
            line-height: 50px;
            font-size: 35px;
            font-family: $primary_font_Medium;
            color: $darkGrey;
        }

        .tab-buttons {
            margin-top: 13px;
            margin-right: 0%;
            .tab-button {
                float: right;
            }
        }

        .payment-tab-content {
            margin-top: 12px;

            .show-more {
                margin-top: 20px;
                float: right;
                color: $darkMango;
                text-decoration: underline;
                cursor: pointer;
            }

            .payment-history-title {
                margin-top: 50px;
                color: $darkMango;
                display: flex;
                margin-bottom: 20px;

                span {
                    border-radius: 50px;
                    border-width: 2px;
                    border-style: solid;
                    border-color: $vividBlue;
                    padding-right: 5px;
                    padding-top: 2px;
                    padding-bottom: 3px;
                    padding-left: 3px;
                    cursor: pointer;
                }

                p {
                    margin-bottom: 0px;
                    margin-left: 20px;
                    display: table-cell;
                    vertical-align: middle;
                    line-height: 34px;
                }
            }

            .view-history-btn {
                margin-top: 5rem;
            }

            .payment-details-title {
                color: $darkMango;
                padding-left: 20px;
            }

            .next-payment {
                background-color: $darkMango;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                padding: 30px;
                color: $white_solid;
                font-family: $primary_font_Medium;

                p {
                    margin-bottom: 0px;
                }

                .large {
                    font-size: 50px;

                    @media (max-width: 768px) {
                        font-size: 35px;
                    }
                }
            }

            .last-payment {
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                padding: 30px;
                font-family: $primary_font_Medium;
                box-shadow: 0px 5px #f4f4f4;

                p {
                    margin-bottom: 0px;
                }

                .p-grey {
                    font-size: 30px;
                    color: #a5abc0;

                    @media (max-width: 768px) {
                        font-size: 25px;
                    }
                }
            }

            table {
                width: 100%;

                tr {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;

                    th {
                        font-family: $primary_font_Medium;
                        color: $battleshipGrey;
                        padding: 10px;
                        border-bottom-color: $paleGrey;
                        padding-bottom: 20px;
                    }

                    td {
                        span {
                            background-color: $battleshipGrey;
                            padding: 5px;
                            color: $white_solid;
                            font-style: italic;
                        }

                        font-family: $primary_font_Regular;
                        color: $battleshipGrey;
                        padding: 10px;
                        padding-bottom: 15px;
                        padding-top: 15px;
                        border-bottom-color: $paleGrey;
                        max-width: 300px;
                    }

                    td:last-child {
                        color: $darkMango;
                    }
                }
            }

            .payment-method-header {
                margin-bottom: 30px;
            }

            .payment-method-tile {
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 20px;
                padding-bottom: 20px;
                font-family: $primary_font_Medium;
                color: $battleshipGrey;
                border: #f1ecec;
                border-width: 1px;
                border-style: double;
                box-shadow: 2px 2px 8px 2px #eae4e4;
                margin-bottom: 20px;
                align-items: center;

                .delete-card-col {
                    margin: auto;
                }

                .button-delete-card {
                    float: right;
                }

                @media (max-width: 768px) {
                    padding-right: 0;

                    .delete-card-col {
                        text-align: center;
                        padding: 40px 20px 20px;
                    }

                    .button-delete-card {
                        float: unset;
                    }
                }

                .subscription-title {
                    font-size: 18px;
                    font-weight: bold;
                }

                .subscription-description {
                    font-size: 14px;
                    display: none;
                }

                .subscription-price {
                    font-size: 16px;
                    font-weight: bold;
                }

                .subscription-days {
                    span {
                        color: $darkMango;
                    }
                }

                .subscription-change {
                    color: $darkMango;
                    text-decoration: underline;
                    cursor: pointer;
                    margin: 2px 0px;
                }
            }

            @media (min-width: 768px) and (max-width: 992px) {
                .payment-history-table {
                    td {
                        font-size: 14px;
                    }
                }
            }

            @media (min-width: 360px) and (max-width: 768px) {
                .payment-history-table {
                    display: none;

                    th,
                    td {
                        padding: 5px;
                    }

                    td {
                        font-size: 12px;
                    }
                }
            }
        }

        .subscription-tab-content {
            margin-top: 35px;
            border: #f1ecec;
            border-width: 1px;
            border-style: double;
            box-shadow: 2px 2px 8px 2px #eae4e4;

            .subscription-tile {
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 40px;
                padding-bottom: 40px;
                font-family: $primary_font_Medium;
                color: $battleshipGrey;
                align-items: center;

                .text-alert {
                    color: $red;
                }

                .subscription-title {
                    font-size: 16px;
                    font-weight: bold;
                }

                .subscription-description {
                    font-size: 12px;
                }

                .subscription-price {
                    font-size: 16px;
                    font-weight: bold;
                }

                .subscription-change {
                    color: $darkMango;
                    text-decoration: underline;
                    cursor: pointer;
                    margin: 2px 0px;
                    line-height: 25px;
                }

                .subscribtion-change-style {
                    display: block;
                    width: 100%;
                }
            }

            @media (max-width: 768px) {
                .subscribtion-card-content {
                    margin: unset !important;
                    padding-bottom: 10px;

                    :last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .tutor-payment-history {
            .no-border {
                border-bottom-style: none;
                border-bottom-width: 0px;
            }

            th:last-child {
                text-align: center;
            }

            td:last-child {
                text-align: center;
            }

            .session-details {
                background-color: $paleGreyThree;

                .date {
                    padding-left: 20px;
                    margin-left: 20px;
                }

                .length {
                    padding-right: 80px;
                    text-align: right;
                    color: $battleshipGrey;
                }

                b {
                    color: $darkGrey;
                }
            }
        }
    }

    .custom-input .input-container textarea {
        height: 205px;
    }

    .my-account-form {
        margin: 55px 0;

        .first-column {
            .custom-input:nth-child(3) {
                z-index: 2;
            }
        }

        .display-flex {
            display: flex;
        }
    }

    .user-payment-form {
        .phone-number-wrapper {
            padding: 10px 15px 0px 15px;

            input {
                -webkit-user-select: all !important;
            }

            label {
                display: block;
            }
        }

        h3 {
            margin-left: 13px;
        }

        .custom-input,
        .simple-dropdown {
            padding: 15px;
        }

        .address-details-heading {
            margin-left: 13px;
            margin-top: 10px;
            margin-bottom: 15px;
        }

        margin: 55px 0;

        @media (max-width: 768px) {

            h3,
            .address-details-heading {
                margin-left: 0;
            }
        }
    }

    .submit-area {
        margin-top: 45px;
        text-align: center;
    }

    @media (max-width: 768px) {
        .header {
            margin-top: 30px;
            padding: 20px;

            .switch-tabs {
                margin-top: 20px;
            }

            h1 {
                font-size: 30px;
                font-family: $primary_font_Bold;
            }
        }

        .tutor-availability {
            .tutor-availability-container {
                .slots-content-mobile {
                    .content {
                        .slots {
                            height: 30vh;
                        }
                    }
                }
            }
        }
    }
}

.account-profile label+.MuiInput-formControl {
    margin-top: 6px;
}

.account-profile .datepicker-wrapper {
    margin: 0rem 0 1.5rem 0;
}

.account-profile .custom-input {
    padding-left: 10px;
    padding-top: 20px;

    @media (max-width: 768px) {
        padding-left: 0px;
    }
}


.account-profile .dropdown-wrapper.simple-dropdown {
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 0px;


    @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.account-profile .education-dropdown-grid {
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.account-profile .datepicker-wrapper.p-15 {
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 0px !important;
}

.account-profile {
    .plus_button {
        margin-left: 15px;

        img {
            width: 62px;
        }
    }

    .tutor-license-inputs {
        .datepicker-wrapper {
            padding-left: 20px;
        }
    }
}

.list {
    .canvas-margin {
        margin-left: -56px;

        canvas {
            margin: auto;
        }

        @media (max-width: 576px) {
            margin: -35px;
        }
    }
}

.account-profile .MuiGrid-spacing-xs-3>.MuiGrid-item {
    padding: 2px 0px;
    margin-top: 1%;
}

.account-profile .MuiGrid-spacing-xs-3 {
    width: 100% !important;
    margin: 0px;

    @media (min-width: 415px) and (max-width: 768px) {
        width: 85% !important;
        margin: 0 auto;
    }
}

.account-profile .MuiGrid-root:nth-child(2) .MuiGrid-root:last-child .custom-input,
.account-profile .MuiGrid-root:nth-child(3) .MuiGrid-root:last-child .custom-input {
    padding-left: 20px;

    @media (max-width: 768px) {
        padding-left: 0px;
    }
}

$size: 140px;

.user-avatar {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;
    position: relative;
    width: $size;
    height: $size;
    border-radius: 100%;
    max-width: $size;
    margin-top: 20px;

    &>div {
        margin: auto;
        display: table;
    }

    &:after {
        transition: all 0.3s ease;
        opacity: 0;
        position: absolute;
        content: "";
        width: $size;
        height: $size / 2;
        bottom: 0px;
        left: 0px;
        border-bottom-left-radius: $size * 2;
        border-bottom-right-radius: $size * 2;
        background: url("../../../assets/images/icons/camera-icon.svg") center center no-repeat;
        background-size: 40px 25px;
        background-color: $battleshipGrey;
    }

    &:hover {
        &:after {
            opacity: 0.7;
        }
    }

    &.open {
        &:after {
            opacity: 0.7;
        }
    }

    @media (max-width: 992px) {
        margin: 1rem auto;
    }
}

.avatar-options {
    z-index: 20;
    position: absolute;
    top: 126px;
    left: 25vw;
    min-height: fit-content;

    .options {
        position: relative;
        background-color: #fff;
        width: 432px;
        height: 266px;
        border-radius: 4px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        margin-left: -60px;

        .cover {
            position: absolute;
            height: 43px;
            width: 25px;
            overflow: hidden;
            top: 40%;
            left: -25px;

            .arrow-left {
                position: absolute;
                width: 30px;
                height: 32px;
                left: 20px;
                background: white;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                border-radius: 4px;
            }
        }

        .list {
            width: 100%;

            ul {
                list-style: none;
                width: 100%;
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;

                li {
                    transition: all 0.2s ease;
                    background-color: white;
                    font-size: 1.125rem;
                    color: $battleshipGrey;
                    padding: 30px 0;
                    text-align: center;
                    border-bottom: 1px solid $paleGrey;
                    cursor: pointer;

                    &:nth-child(2) {
                        padding: 0;

                        label {
                            width: 100%;
                            padding: 30px 0;
                            cursor: pointer;
                            z-index: 50;
                        }
                    }

                    &:hover {
                        background-color: $white;
                    }

                    &:last-of-type {
                        color: $darkMango;
                        border: 0;
                        display: flex;
                        flex-direction: column;
                        padding-left: 5px !important;
                        padding-right: 5px !important;
                        background-color: darkgray;

                        @media (max-width: 576px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &.hidden {
        display: none;
    }
}

/* .bordered {
    border: 1px solid #f00 !important;
} */

@media only screen and (min-width: 1600px) {
    .avatar-options {
        left: 30vw;
    }
}

.take-picture-container {
    width: 50%;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    margin: auto;
    position: relative;
    padding: 25px;

    @media (max-width: 768px) {
        width: 80%;
        padding: 10px;
        margin-top: 111px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        height: 70vh;
    }

    .close-modal-button {
        margin: 0 !important;
        right: 25px;
    }

    .button-wrapper {
        padding-top: 10px !important;
        justify-content: space-evenly !important;

        button {
            width: 170px;
        }
    }

    h2 {
        font-size: 1.875rem;
        color: $battleshipGrey;
        margin-bottom: 40px;
    }

    video {
        width: 100%;
    }
}

.my-account-wrapper {
    margin: 45px 0 0 0;

    .slots-container {
        width: 90%;
        overflow-y: scroll;
        margin: auto;
    }

    .slots-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }

    .slots-container::-webkit-scrollbar {
        width: 2px;
        height: 8.4px;
        background-color: #f5f5f5;
    }

    .slots-container::-webkit-scrollbar-thumb {
        background-color: $darkMango;
    }

    h3 {
        margin-left: 13px;
        margin-bottom: 35px;
        font-size: 26px;
        font-family: $primary_font_SemiBold;
    }

    .container {
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .centered-button {
        margin: 50px auto;
        display: table;
    }
}

.subjects-and-tests {
    padding: 15px;

    h3 {
        font-size: 1rem;
    }

    .actions {
        display: flex;
        align-items: center;
        font-size: 1rem;

        button {
            margin-right: 20px;
        }
    }
}

// .modal-sub-wrapper div {
// }
//

.modal-box>div {
    width: 100%;
}

.subjects-tests-modal {
    width: 100%;

    h3 {
        font-size: 1.875rem;
        color: $battleshipGrey;
        width: 100%;
        text-align: center;
    }

    button {
        margin: auto;
        display: block;
    }

    .options-box p {
        font-family: $primary_font_Medium;
        font-size: 0.875rem;
        color: white;
    }
}

.actions-profile {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.payment-history-cards-container {
    display: none;
}

.subscription-description-hidden {
    display: none;
}

@media (max-width: 992px) {
    .user-profile-img {
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .my-account-tabs-container {
        margin: auto;
    }

    .user-profile-img {
        padding: 20px;
    }

    .payment-history-cards-container {
        display: block;
    }

    .payment-grey-text {
        color: #686c80;
    }

    .payment-orange-text {
        color: $darkMango;
    }

    .tab-buttons {
        margin-top: 15px !important;
    }

    .payment-card {
        border-left: none !important;
        border-right: none !important;
    }

    .payment-detail-dropdown-span {
        svg {
            fill: $darkMango !important;
        }
    }

    .payment-text-end {
        text-align: end;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .subscription-description {
        display: none;
    }
}

@media (min-width: 414px) and (max-width: 1024px) {
    .subscription-description-hidden {
        display: block;
        font-size: 12px;
    }

    .flex-none-on-tab {
        flex-direction: column;
    }

    .on-tab-hidden {
        display: none;
    }

    .subscription-title {
        margin: 0 !important;
        text-align: left !important;
    }
}

.grid-on-mob-show {
    display: none;
}

.subscription-price-day-align {
    .subscription-days {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .subscription-price-day-align {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .user-profile-revervse-col {
        flex-direction: column-reverse !important;
        flex-wrap: nowrap !important;
    }

    .grid-on-mob-show {
        display: flex;
        flex-direction: column-reverse;
    }

    .grid-on-mob-hide {
        display: none;
    }

    .subscription-cancel-links {
        .subscribtion-card-content {
            text-align: left !important;
            float: left !important;
        }
    }

    .subscription-price-day-align {
        padding: 1rem 0;

        .subscription-days {
            display: inline-block;
            margin-left: 10%;
        }
    }

    .subscription-days-grid-hide {
        display: none;
    }
}

@media (min-width: 415px) and (max-width: 768px) {
    .user-profile-revervse-col {
        width: 85% !important;
        margin: 0 auto;
    }
}

.previewProfile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.teacher-myprofile-form {
    .simple-dropdown {
        padding: 0px !important;
    }
}
.walletModel{
    margin-top: 21px;
    .wallet {
        min-height: 85px;
        max-width: 100%;
        background: #f28334;
        border-radius: 7px;
        display: flex;
        color: white;
        margin:auto;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    .balance {
        padding: 9px 0px 4px 20px;

        @media (max-width: 768px) {
            padding: 9px 20px;
            align-self: center;
        }

        div {
            
            // @media (min-width: 768px) {
                border-right: 1px solid white;
            // }

            @media (max-width: 768px) {
                align-items: center;
                border-right: 0px;
                border-bottom: 1px solid white;
                min-width: 200px;
            }

            h1{
                font-size: 30px;
                color: white;
                
                @media (max-width: 768px) {
                    text-align: center;
                }
            }
            p {
                margin-bottom: 0rem;
                
                @media (max-width: 768px) {
                    text-align: center;
                }
            }
        }
    }

    .description {
        padding: 0px 13px 10px 13px ;
        color: white;
        display: flex;

        p {
            font-size: 13px;
            margin-bottom: 0;
        }
                                                
        .circle {
            margin-right: 10px;
            color: #fff;
            font-size: 20px;
            line-height: 28px;
            width: 45px;
            height: 21px;
            min-width: 16px;
            max-width: 16px;
          }
    }
}
.subscription-days{
    .text-warning{
        color: #f28334 !important;
    }
}