$battleshipGrey: #686c80;
$normalGrey: #dddee8;
// $mango: #f9a722;
$darkMango: #f28334;

.upcoming-session-dialog {
    .input-label {
        font-weight: 1000 !important;
    }
    .custom-input {
        .input-container {
            textarea {
                border: 1px solid #eeeeee !important;
                border-radius: 0px !important;
            }
        }
    }
}
.session {
    &-container {
        padding: 40px 60px;
        @media (max-width: 768px) {
            padding: 20px;
        }
    }
    &-heading {
        color: $primary_solid_color;
        font-size: 38px;
        @media (max-width: 992px) {
            font-size: 32px;
        }
    }
    &-subheading {
        color: $battleshipGrey;
        font-size: 14px;
        margin-left: 4px;
        font-family: $primary_font_Medium;
    }
    &-request-card {
        position: relative;
        box-shadow: 1px 13px 25px 0px rgba(0, 0, 0, 0.07) !important;
        margin: 15px 38px 10px 20px;
        overflow: visible !important;
    }
    &-card {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        &.past {
            justify-content: flex-start;
        }
    }

    &-card-expanded {
        padding: 10px 64px 20px;
        @media (max-width: 768px) {
            padding: 10px 0;
        }
    }

    &-modal-exit {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    &-request-list {
        &-heading {
            display: flex;

            h6 {
                flex: 0 1 60%;
                font-family: $primary_font_Medium;
                font-size: 14px;
                color: $battleshipGrey;

                &:nth-child(2) {
                    flex-grow: 1;
                    flex-basis: 40%;
                }
            }
            @media (max-width: 768px) {
                display: block;
            }
        }
        &-item {
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
            &:last-child {
                border-bottom: 0;
            }
            div {
                flex: 0 1 60%;
                p {
                    color: $battleshipGrey;
                    margin-bottom: 5px;
                    font-family: $primary_font_Regular;
                    font-size: 14px;
                    width: 100%;
                    @media (max-width: 768px) {
                        span {
                            button {
                                width: 110px !important;
                                margin: 5px 0;
                            }
                            .suggest-btn {
                                width: auto !important;
                                display: block;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    flex-grow: 1;
                    flex-basis: 40%;
                }
            }
            @media (max-width: 768px) {
                display: block;
            }
        }
        &-options {
            display: flex;
            justify-content: space-between;
        }
    }

    &-time-selector {
        &-wrapper {
            @extend .d-flex;
            margin-bottom: 60px;
            align-items: baseline;
            @media (max-width: 768px) {
                display: block !important;
            }
        }
        &-input {
            text-align: left;
            label {
                color: $battleshipGrey;
            }
            &:nth-child(1) {
                flex: 0 1 60%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
            }
        }
    }

    &-comment {
        padding: 20px 0 40px;
        p {
            color: $battleshipGrey;
            font-family: $primary_font_Regular;
        }

        &-form {
            @extend .d-flex;
            flex-direction: column;
            background-color: $battleshipGrey;
            padding: 20px;
            border-radius: 7px;
            color: $white;

            &-buttons {
                @extend .d-flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 768px) {
                    display: block !important;
                    text-align: center;
                }
            }
        }
    }

    &-userdata {
        display: flex;
        max-width: 200px;
        width: 100%;
        &.past {
            flex: 0 1 39%;
            max-width: 100%;
            margin-left: 2%;
        }
    }

    &-card-headings {
        //flex: 0 1 170px;
        h6 {
            font-size: 18px;
            color: $battleshipGrey;
            margin-bottom: 4px;
        }
        p {
            margin-bottom: 0;
            font-size: 18px;
            font-family: $primary_font_Medium;
            color: $darkGrey;
        }
        @media (max-width: 768px) {
            h6 {
                font-size: 16px;
            }
        }
        &.upcoming {
            flex: 0 1 60%;
            p {
                font-size: 14px;
                color: $battleshipGrey;
                &.now-label {
                    color: $greenishTeal;
                    font-size: 14px !important;
                }
            }
        }

        &.past {
            h6 {
                color: $darkGrey;
                @media (max-width: 576px) {
                    font-size: 18px;
                }
            }
            p {
                font-size: 14px;
                color: $battleshipGrey;
            }
            margin-left: 0px;
            flex: 0 1 33%;
            @media (max-width: 576px) {
                padding-bottom: 1rem;
            }
            &.subject {
                h6 {
                    color: $battleshipGrey;
                    font-size: 14px;
                }
                p {
                    color: $darkGrey;
                    font-size: 18px;
                }
            }
        }
    }
    &-time-modal {
        max-width: 940px;
        width: 100%;
        text-align: center;
        h4 {
            font-size: 30px;
            color: $battleshipGrey;
            margin-bottom: 23px;
        }
        p {
            font-size: 16px;
            color: $battleshipGrey;
            margin-bottom: 60px;
        }
    }

    &-past-heading {
        display: flex;
        align-items: flex-start;
        margin: 15px 38px 10px 20px;
        justify-content: space-between;
        .past-content-mob-hide {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        .past-content-mob-show {
            display: none;
        }
        @media (max-width: 768px) {
            display: block;
            margin: 10px;
            .past-filters {
                display: block;
            }
            input {
                padding: 10px !important;
            }
            .past-date-picker {
                margin: 0;
                width: 100%;
            }
            .datepicker-root-past {
                width: 100%;
                input {
                    padding: 10px 0 !important;
                }
            }
            .past-content-mob-hide {
                display: none;
            }
            .past-content-mob-show {
                display: block;
            }
            .past-content-div-mob-show {
                .past-title-w {
                    width: 20%;
                }
                .past-date-w {
                    width: 30%;
                }
                .past-search-w {
                    width: 50%;
                    margin-left: 2%;
                }
            }
        }
        @media (max-width: 576px) {
            .past-content-div-mob-show {
                display: block !important;
                .past-title-w {
                    width: 40%;
                    display: inline-block;
                    h4 {
                        position: relative;
                        top: 20px;
                        left: 15px;
                    }
                }
                .past-date-w {
                    width: 60%;
                    display: inline-block;
                }
                .past-search-w {
                    width: 100%;
                }
            }
        }
        h4 {
            margin-top: 10px;
            font-size: 20px;
        }
    }
}
.change-modal-drop-box {
    height: 200px;
}
.change-modal-popper.custom-calendar-popper .react-datepicker__day:not(.react-datepicker__day--keyboard-selected),
.change-modal-popper.custom-calendar-popper
    .react-datepicker__day:not(.react-datepicker__day--today).react-datepicker__day {
    margin: 0.2rem 1rem !important;
}
.session-card-content-hide {
    display: none;
}
.session-card-content-show {
    display: block;
    width: 100%;
}
.session-card {
    @media (max-width: 992px) {
        .request-btn-absolute {
            position: absolute;
            bottom: -20px;
            right: 60px;
        }
        .session-card-content {
            max-width: 33% !important;
            padding-bottom: 10px;
            margin-left: 0;
        }
    }
    @media (max-width: 768px) {
        display: flex;
        .session-card-headings p {
            font-size: 16px;
        }
    }
    @media (max-width: 767px) {
        .session-card-headings .past-p-14 {
            font-size: 14px;
        }
    }
    @media (max-width: 576px) {
        display: block;
        .session-userdata {
            max-width: 100% !important;
        }
        .session-card-content-hide {
            display: flex;
            justify-content: space-between;
            padding: 15px 0;
            .session-card-headings {
                max-width: 50% !important;
            }
        }
        .session-card-content-show {
            display: none;
        }
    }
}
.session-request-card {
    @media (max-width: 768px) {
        margin: 15px 20px 30px 20px !important;
    }
    @media (max-width: 576px) {
        margin: 15px 20px 25px 20px !important;
    }
}
.upcoming-card {
    justify-content: flex-start;
    .upcoming {
        margin-left: 10%;
        p {
            color: $battleshipGrey;
            font-size: 14px;
        }
    }
    .session-card-headings {
        max-width: 30%;
        p {
            color: $darkMango;
            font-size: 18px;
        }
        h6 {
            color: $battleshipGrey;
            font-size: 14px;
        }
        @media (max-width: 768px) {
            max-width: none;
            h6 {
                font-size: 14px;
            }
            p {
                font-size: 18px;
            }
        }
        @media (max-width: 576px) {
            margin-left: 0;
        }
    }
    .cancel-booked-session {
        position: absolute;
        top: 10%;
        right: 20px;
        cursor: pointer;
        font-size: 20pt;
        @media (max-width: 768px) {
            top: 1%;
            right: 5px;
        }
    }
    .reschedule-button {
        position: absolute;
        cursor: pointer;
        top: 80%;
        right: -10px;
        width: 150px;
        height: 42px;
        font-size: 14px;
        padding: 0 20px;
        font-family: "Montserrat-Medium";
        @media (max-width: 768px) {
            width: 140px;
            top: 85%;
            right: 40px;
            z-index: 99;
        }
        @media (max-width: 576px) {
            top: 89%;
        }
    }
    .join-button {
        position: absolute;
        cursor: pointer;
        top: 80%;
        right: -10px;
        width: 150px;
        height: 42px;
        font-size: 14px;
        padding: 0 20px;
        font-family: "Montserrat-Medium";
        @media (max-width: 768px) {
            width: 110px;
            top: 85%;
            right: 40px;
            z-index: 99;
        }
        @media (max-width: 576px) {
            top: 89%;
        }
    }
}
.datepicker-root {
    width: 100%;
    justify-content: center;
    label {
        font-size: 16px;
        margin-top: -37px;
        font-family: Montserrat-Medium;
        font-weight: 500;
    }
    &-past {
        width: 13em;
        height: 5em;
    }
}

.past-date-picker {
    padding: 0px;
    width: 28em;
    margin-left: 40px;
    color: $battleshipGrey;

    & input {
        font-family: Montserrat-Medium;
        font-weight: 500;
        color: $battleshipGrey;

        &::placeholder,
        &:-moz-placeholder,
        &:-webkit-input-placeholder {
            font-family: $primary_font_Medium;
            font-weight: 500;
        }
    }
}

.past-date-picker .input-container {
    input::placeholder {
        font-family: $primary_font_Medium;
        font-weight: 500;
        color: lightgray;
    }
}
.past-filters {
    display: flex;
}
.request-buttons {
    @extend .d-flex;
    justify-content: flex-end;
    align-items: center;
    .custom-round {
        &-accept {
            border: solid 2px $greenishTeal;
            margin: 0 10px;
            width: 36px;
            height: 36px;
            svg {
                color: $greenishTeal;
                width: 14px;
                height: 14px;
            }
            &:hover {
                svg {
                    color: white;
                }
                background-color: $greenishTeal;
            }
        }

        &-accepted {
            svg {
                color: white;
            }
            background-color: $greenishTeal;

            &:hover {
                svg {
                    color: white;
                }
                background-color: $greenishTeal;
            }
        }
        &-reject {
            border: solid 2px $red;
            margin: 0 10px;
            width: 36px;
            height: 36px;
            svg {
                color: $red;
                width: 14px;
                height: 14px;
            }
            &:hover {
                svg {
                    color: white;
                }
                background-color: $red;
            }
        }

        &-rejected {
            svg {
                color: white;
            }
            background-color: $red;
            border: solid 2px $red;

            &:hover {
                svg {
                    color: white;
                }
                background-color: $red;
            }
        }
    }
}
.view-request-h6 {
    font-family: Montserrat-Medium;
    font-size: 14px;
    color: #686c80;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .session {
        &-container {
            padding: 40px 20px;
        }
        &-request {
            &-card {
                margin: 15px 30px 25px 0px;
            }
            &-list-item {
                div {
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
        &-card-expanded {
            padding: 10px 0px 20px;
        }
        &-comment {
            &-form {
                h4 {
                    font-size: 14px;
                }
                p {
                    font-size: 12px;
                }
            }
            p {
                font-size: 12px;
            }
        }
        &-card-headings {
            flex: 0 1 auto;
            margin-left: 20px;
            h6 {
                font-size: 14px;
            }
            p {
                font-size: 16px;
            }
        }
    }

    .request-buttons .custom-round {
        &-accept {
            width: 30px;
            height: 30px;
        }
        &-reject {
            width: 30px;
            height: 30px;
        }
    }
}

.duration {
    width: 20%;
    &-selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        margin-top: 0.3em;
        box-shadow: 0 10px 10px 0 $light_black;
        input {
            border: none;
            text-align: center;
            max-width: 44%;
        }
    }
    &-label {
        margin: 0.8em 0 0;
    }
    @media (max-width: 768px) {
        width: 50%;
    }
}

.session-time-selector-input.time {
    min-width: 250px;
    .MuiSelect-selectMenu {
        height: 43px;
    }
}
.reschedule-time-selector-input.time {
    min-width: 250px;
    margin-left: 77px;
    text-align: left;
    .MuiInput-root {
        padding: 0;
        .MuiSelect-select {
            line-height: 43px;
            padding-left: 14px;
        }
    }
    .MuiSelect-selectMenu {
        height: 43px;
    }
}
.view-request-content {
    display: flex;
    justify-content: space-between;
}
@media (min-width: 992px) and (max-width: 1200px) {
    .session-card {
        .session-card-headings {
            p {
                font-size: 14px;
            }
        }
    }
}
@media (max-width: 768px) {
    .suggest-modal {
        margin-top: 40px;
        .makeStyles-root-38 {
            padding: 60px 25px 25px !important;
            overflow: scroll;
            margin: 84px 20px 40px !important;
        }
        .MuiPaper-root {
            overflow: scroll !important;
            padding: 70px 30px 70px !important;
        }
        .MuiDialog-paper {
            margin: 32px 10px !important;
        }
        .Cancel-modal-btn {
            flex-direction: column;
        }
        .cancel-booked-session {
            top: 1%;
            right: 5px;
        }
    }
}
@media (max-width: 576px) {
    .view-request-content {
        display: block;
    }
    .suggest-btn {
        margin-top: 5px !important;
    }
    .suggest-page-btn {
        width: 120px !important;
    }

    .upcoming {
        padding: 15px 0;
    }
}
@media (max-width: 414px) {
    .suggest-modal {
        .MuiPaper-root {
            padding: 70px 10px !important;
        }
        .MuiDialog-paper {
            margin: 0 !important;
        }
        .suggest-page-btn {
            width: 110px !important;
        }
    }
}
.past-session-card-content {
    .session-card-past-headings {
        font-size: 14px;
    }
    .session-card-past-p {
        font-size: 18px;
        @media (max-width: 576px) {
            font-size: 18px !important;
        }
    }
    .cancelled {
        color: red !important;
    }
    .completed {
        color: green !important;
    }
}
.session-accept-reject-card {
    h6 {
        font-size: 14px !important;
    }
    p {
        font-size: 18px !important;
    }
}
.session-upcoming-card-user {
    p {
        font-size: 18px !important;
    }
    h6 {
        font-size: 14px !important;
    }
}
.session-comment-form-buttons {
    .button .outline-button {
        margin-bottom: 15px;
    }
}
.suggest-modal {
   .MuiDialog-paper{
    padding: 60px 51px 50px;
   }
   .session-time-selector-wrapper{
       margin-bottom: 30px;
   }
   label + .MuiInput-formControl{
       margin-top: 0;
       padding: 5px 0 10px;
       display: flex;
       align-items: center;
       @media (max-width:768px) {
        padding: 5px 0 7px;
       }
   }
   .dropdown-wrapper .input .MuiInputLabel-formControl{
    top: 30px;
    color: #686c80;
   }
   .session-time-selector-input label {
    color: #2d3234;
    }
    .session-time-selector-input.time .MuiSelect-selectMenu{
        color: #686c80;
    }
    .session-time-selector-input.time .MuiSelect-selectMenu{
        display: flex;
        align-items: center;
        padding: 15px 10px 21px;
        height: unset;
    }

}
    