@font-face {
    font-family: "Montserrat-Black";
    src: url("./font/Montserrat-Black.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-BlackItalic";
    src: url("./font/Montserrat-BlackItalic.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("./font/Montserrat-Bold.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-BoldItalic";
    src: url("./font/Montserrat-BoldItalic.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-ExtraBold";
    src: url("./font/Montserrat-ExtraBold.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-ExtraBoldItalic";
    src: url("./font/Montserrat-ExtraBoldItalic.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-ExtraLight";
    src: url("./font/Montserrat-ExtraLight.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-ExtraLightItalic";
    src: url("./font/Montserrat-ExtraLightItalic.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-Italic";
    src: url("./font/Montserrat-Italic.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-Light";
    src: url("./font/Montserrat-Light.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-LightItalic";
    src: url("./font/Montserrat-LightItalic.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("./font/Montserrat-Medium.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-MediumItalic";
    src: url("./font/Montserrat-MediumItalic.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url("./font/Montserrat-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("./font/Montserrat-SemiBold.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-SemiBoldItalic";
    src: url("./font/Montserrat-SemiBoldItalic.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-Thin";
    src: url("./font/Montserrat-Thin.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat-ThinItalic";
    src: url("./font/Montserrat-ThinItalic.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-Bold";
    src: url("./font/OpenSans-Bold.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-BoldItalic";
    src: url("./font/OpenSans-BoldItalic.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-ExtraBold";
    src: url("./font/OpenSans-ExtraBold.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-ExtraBoldItalic";
    src: url("./font/OpenSans-ExtraBoldItalic.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-Italic";
    src: url("./font/OpenSans-Italic.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-Light";
    src: url("./font/OpenSans-Light.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-LightItalic";
    src: url("./font/OpenSans-LightItalic.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("./font/OpenSans-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-SemiBold";
    src: url("./font/OpenSans-SemiBold.ttf") format("opentype");
}

@font-face {
    font-family: "OpenSans-SemiBoldItalic";
    src: url("./font/OpenSans-SemiBoldItalic.ttf") format("opentype");
}
