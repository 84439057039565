.MuiInput-root {
    height: 65px;
    width: 100%;
    border: 0px solid #ffffff !important;
    border-radius: 7px;
    box-shadow: 0 10px 10px 0 $light_black !important;
    padding: 14px;
}
.user-panel-calendar {
    text-align: center;
    .react-calendar__month-view__weekdays__weekday > * {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: default !important;
    }
    .react-calendar__navigation > * {
        border: 0px;
        background-color: $white_solid;
        font-size: 20px;
    }
    .react-calendar__navigation > * {
        margin-bottom: 20px;
        color: $darkMango;
    }
    .react-calendar__navigation__label {
        font-size: 18px;
    }
    .user-panel-calendar-tile {
        font-family: $primary_font_Medium;
        font-size: 14px;
        border: 0px;
        background-color: transparent;
        color: $battleshipGrey;
        position: relative;

        .marked-date-tile-child {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $paleGrey;
        }
        .user-calendar-tile {
            visibility: visible;
            font-size: 14px;
            border-radius: 10px;
            padding: 10px 0;
            cursor: default;
        }
        .today-user-calendar-tile {
            border: 1.7px solid $darkMango;
            border-radius: 10px;
            color: $battleshipGrey;
        }
        abbr {
            visibility: visible;
        }
    }
}

.user-panel-todo-list-container {
    font-weight: bold;
    min-height: 170px;
    .user-panel-todo-heading {
        font-size: 16px;
        color: $darkMango;
        margin-bottom: 0px;
        .todo-heading-icon {
            position: relative;
            img {
                position: absolute;
                top: -5px;
                left: -5px;
                height: 25px;
            }
        }
        .user-panel-todo-heading-text {
            padding-left: 25px;
        }
    }
    .user-panel-todo-item {
        font-size: 14px;
        .user-panel-todo-item-name {
            color: $darkGrey;
            margin-right: 10px;
        }
        .user-panel-todo-item-date {
            color: $battleshipGrey;
        }
    }
}
.user-panel-todo-button {
    padding-top: 10vh;
    button {
        svg {
            color: $white_solid;
        }
    }
}
div.react-calendar__viewContainer {
    button {
        font-size: 10px;
        abbr {
            visibility: visible;
            font-size: 15px;
        }
        .user-calendar-tile {
            visibility: hidden;
            display: none;
        }
    }
}

.react-calendar__tile.react-calendar__month-view__days__day.user-panel-calendar-tile {
    abbr {
        display: none;
    }

    .user-calendar-tile {
        display: block;
        visibility: visible;
        font-size: 0.8rem;
    }
}

.react-calendar__tile.react-calendar__year-view__months__month.user-panel-calendar-tile,
.react-calendar__tile.react-calendar__decade-view__years__year.user-panel-calendar-tile,
.react-calendar__tile.react-calendar__century-view__decades__decade.user-panel-calendar-tile {
    padding: 15px 0;
}
 div.user-panel-body > div > .MuiGrid-root.MuiGrid-item {
     width: 100%;
 }
 .calender-session-btn{
    margin: 0px auto;
    display: table;
    position: relative;
    bottom: 20px;
    font-size: $extra_small_font !important;
    height: 40px !important;
 }
 @media (min-width:1024px) and (max-width:1599px) {
     .calender-session-btn{
         bottom: 20px;
     }
 }