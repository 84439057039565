.header-menu {
    background-color: $primary_solid_color;
    height: 60px;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    .menu-btn {
        height: 60px;
        padding-top: 9px;
        margin-top: 10px;
        &:hover {
            background-color: transparent;
        }
        @media (max-width: 575px) {
            position: relative;
        }
    }
    display: none;
}

.slide-menu {
    position: fixed;
    z-index: 999;
    opacity: 0;
    left: -768px;
    top: 0px;
    transition: 0.5s ease-in-out;
    display: none;
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 75px;
        margin: 20px 0 0px 0;
        position: relative;
        height: auto;
        .navbar-logo {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 60%;
            height: 100%;
            padding-left: 37px;
            @media (max-width: 320px) {
                width: 70%;
            }
        }
        .close {
            width: 40%;
            height: 100%;
            padding-right: 20px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: $primary_solid_color;
        }
    }
    .nav {
        background-color: $paleGreyThree;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding-top: 25px;
        transition-duration: 0.5s;
        width: 100%;
        position: absolute;
        top: 12% !important;
        li {
            margin-left: 20px;
            @media (min-width: 768px) and (max-width: 1440px) {
                padding: 5px 0;
            }
        }
        .nav-link {
            color: $darkGrey;
            display: flex;
            text-decoration: none !important;
            cursor: pointer;
            .nav-icon {
                width: 65px;
                height: 61px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                position: relative;
                @media (min-width: 321px) and (max-width: 767px) {
                    width: 14vw;
                    height: 6vh;
                }
                @media (max-width: 320px) {
                    width: 14.5vw;
                    height: 5.7vh;
                }
                svg {
                    @extend .svg_style;
                    min-width: 24px;
                    min-height: 24px;
                    width: 32px;
                    height: 32px;
                    @media (min-width: 321px) and (max-width: 767px) {
                        transform: scale(0.8)
                    }
                    @media (max-width: 320px) {
                        transform: scale(0.7);
                    }
                }
            }
            .nav-title {
                font-family: $primary_font_Medium;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-left: 10px;
                @media (max-width: 320px) {
                    font-size: 100%;
                }
            }
        }
        .active {
            .nav-icon {
                background-color: $primary_solid_color;
                border-radius: 8px 0px;
                svg {
                    @extend .svg_style;
                    height: 33px;
                    fill: $white_solid;
                }
            }
            .nav-title {
                font-family: $primary_font_Medium;
                color: $primary_solid_color;
            }
        }
    }
    li {
        @extend .w-100;
        @extend .text-center;
    }
}
@media (max-width: 768.95px) {
    .header-menu {
        display: block;
        z-index: 999999;
    }
    .slide-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 55%;
        height: 100vh;
        background-color: $paleGreyThree;
        .logout-wrapper {
            .nav {
                position: relative;
                bottom: 0;
                margin-bottom: 18vh !important;
            }
        }
    }
    .log-out-mob {
        svg {
            fill: #fff !important;
        }
    }
}
@media (max-width: 576px) {
    .slide-menu {
        width: 94%;
    }
}
