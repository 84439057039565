html > body {
    font-family: "Montserrat-Medium";
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.navbar-logo {
    height: 53px;
    margin: 15px 0 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.info-tooltip {
    margin-left: 5px;
}

.info-icon {
    width: 20px !important;
    height: 20px !important;
}

.navigator-page {
    height: 100%;
    display: flex;
    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
    .sidenav.filters-panel-container {
        transition: 0.5s ease-in-out !important;
    }
    .filters-panel-container .button-updated button div {
        font-size: 16px !important;
    }
    & > div {
        width: 100%;
        height: auto;
        @media (max-width: 1200px) {
            width: 50%;
        }
        @media (max-width: 768px) {
            flex: 1 1 100% !important;
            max-height: none;
        }
    }
    .sort-label-container:first-child > .sort-active-label {
        max-width: 135px;
        white-space: nowrap;
    }
    .filters-panel {
        .filter-panel-header {
            display: flex;
            margin-top: 1rem;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .page-header {
                color: $vividBlue;
                font-size: 38px;
                font-weight: 400;
                font-family: $primary_font_Bold;
                @media (max-width: 768px) {
                    font-size: 32px;
                }
            }

            .clear-label {
                color: $vividBlue;
                text-decoration: underline;
                font-size: 18px;
                cursor: pointer;
                font-family: $primary_font_Bold;
            }
        }

        .inline {
            display: -webkit-inline-box !important;
        }

        .blue-tab {
            .active-tab {
                background-color: $vividBlue;
            }
        }
        .tutor-search-filter {
            padding: 10px 10px 10px 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: fit-content;
            float: right;
            width: 100%;
            .input-label {
                font-family: $primary_font_Bold;
            }

            .dropdown-wrapper {
                padding: 0px;
            }
            .filter {
                display: inline-flex;
            }
            .switch-component {
                font-size: 14px;
                color: $darkGrey;
                font-family: $primary_font_Bold;
            }
            .tab {
                width: unset !important;
            }
            .active-tab {
                width: unset !important;
                font-size: 15px !important;
                min-height: 60px;
            }
            .range {
                color: $darkMango;
                font-size: "14px";
                font-weight: 500;
                .row {
                    justify-content: space-between;
                    width: 100%;
                    display: inline-block;

                    .rightRange {
                        text-align: right;
                        margin-left: 40px;
                        box-shadow: 0 10px 10px 0 #0000000d !important;
                        flex-grow: 0;
                        left: auto !important;
                        justify-content: space-between;
                        float: right;
                        right: 11%;
                        width: 58px;
                    }
                    .leftRange {
                        margin-left: 51px;
                        box-shadow: 0 10px 10px 0 #0000000d !important;
                        flex-grow: 0;
                        float: left;
                        width: 58px;
                    }
                }
            }
        }

        .margin-top-zero {
            margin-top: 0px;
        }

        .tabs-container {
            display: table;

            .tab {
                display: table-cell;
                width: 33%;
                vertical-align: middle;
                font-size: 16px !important;
            }
        }

        .switch-tab {
            .switch-tabs {
                width: 100%;
            }
        }

        .multi-selector {
            width: 100%;
            box-shadow: 0px 8px 1px -8px rgba(0, 0, 0, 0), 0px 8px 20px 1px rgba(0, 0, 0, 0.05),
                0px 1px 5px 0px rgba(0, 0, 0, 0.12);

            .my-option {
                width: 100%;
                background-color: $white_solid;
                border-color: $white_solid;
                border-right: 1px solid $paleGrey;
                color: $battleshipGrey;
            }

            .my-option-selected {
                width: 100%;
                background-color: $darkMango;
                border-color: $white_solid;
                border-right: 1px solid $paleGrey;
                color: $battleshipGrey;
            }
        }

        .simple-dropdown {
            padding: 0px;
        }
    }
}

.search-results-panel {
    height: 100%;
    flex: 5;
    box-shadow: 0 10px 10px 0 $light_black;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    justify-content: center;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .svg_style {
        fill: $darkMango;
        height: 25px;
        width: 25px;
        margin: auto;
    }

    .icon-wrapper {
        cursor: pointer;

        svg {
            @extend .svg_style;
        }
    }
}

.page-container {
    display: flex;
    flex-direction: row;
    height: 90%;
    width: 95%;
}

.content-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 85%;
}

.small-bold-label {
    font-size: smaller;
    font-weight: 500;
}

.temp-label {
    color: $darkMango;
    font-size: 3vw;
}

.toggle_filter_search {
    .content-container {
        .button-updated {
            button {
                background-color: $vividBlue;
            }
            div {
                font-size: 20px !important;
            }
        }
    }
}
.filters-panel-container {
    .search-button-container {
        button {
            background-color: $vividBlue;
            height: 55px;
        }
    }
}
