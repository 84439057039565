.module {
	// margin: 90px;
	.lesson-header {
		background: linear-gradient( rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.77) ), url(../../assets/images/lessonHeadBackground.png);
		padding-top: 2.7em;
		padding-left: 73px;
		padding-right: 73px;
		padding-bottom: 2.7em; 
		height: 377px;
		background-size: cover;
		max-width: 100% !important;
	}
	.-Medium-Regular-Left-Dark-Copy-4 {
		padding-top: 31px;
		
		font-size: 26px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 1.08;
		letter-spacing: normal;
		color: $darkMango;
	  }
	  .Path-2-Copy {
		width: 100%;
		height: 1px;
		margin: 120px 0 49px;
		border: solid 1px #dddee8;
	  }
	  .Path-2-border {
		width: 100%;
		height: 1px;
		border: solid 1px #dddee8;
	  }
	  .-XXL-Medium-Left-Dark-Copy-13 {
		height: 44px;
		// 		font-family: $primary_font_Regular;

		font-size: 30px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		// line-height: 1.8;
		letter-spacing: normal;
		color: #2d3234;
	  }
	  .-Bullet-regular-tex {
				font-family: $primary_font_Regular;

		font-size: 26px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		// line-height: 1.5;
		letter-spacing: normal;
		color: #2d3234;
	  }
	  .Path-3-border {
		width: 100%;
		height: 1px;
		margin: 29px 0 45px;
		border: solid 1px #dddee8;
	  }
	  .-Medium-Regular-Left-Dark-ref{
	
				font-family: $primary_font_Regular;

		font-size: 25px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: $darkMango;
	  }
	  .Rectangle-Footer {
		width: 100%;
		height: 299px;
		margin: 15px 0 16px;
		padding: 66px 115px 11px 380px;
		background-color: $darkMango;
	  }
	  .Rectangle-Footer-btn {
		width: 207px;
		height: 62px;
		margin: 0px 294px 9px 237px;
		padding: 17px 67px;
		border-radius: 31px;
		border: solid 3px $darkMango;
		background-color: #ffffff;
	  }
	  .Lets-Go {
		width: 73px;
		height: 28px;
		font-family: $primary_font_Regular;
		font-size: 18px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.56;
		letter-spacing: normal;
		text-align: center;
		color: $darkMango;
	  }
	  .-XXL-Medium-Left-Dark-footer-Iner {
		width: 681px;
		height: 54px;
		margin: 0 57px 35px 0;
				font-family: $primary_font_Regular;

		font-size: 38px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.42;
		letter-spacing: normal;
		color: #ffffff;
	  }
	  .Previous-Module {
		width: 171px;
		margin: 0px 0px 30px 0px;
				font-family: $primary_font_Regular;

		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: $darkMango;
	  }
	  
	  .Next-Module {
		width: 130px;
		margin: 0px 0px 30px 0px;
				font-family: $primary_font_Regular;

		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: $darkMango;
	  }
	  
	  .-XXL-Medium-Left-Dark-Copy-10 {
		width: 291px;
		height: 64px;
		margin: 0px 625px 0px 0;
		// 		font-family: $primary_font_Regular;

		font-size: 30px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.8;
		letter-spacing: normal;
		color: #2d3234;
	  }
	  
	  .text-style-1 {
		font-weight: bold;
	  }
	  .text-style-2 {
		color: $darkMango;
	  }
	  .-Medium-Regular-Left-Dark-Copy-5 {
		width: 1083px;
		height: 155px;
		margin: 49px 0px 0px 0px;
		font-family: OpenSans;
		font-size: 40px;
		font-weight: bold;
		font-stretch: normal;
		font-style: italic;
		line-height: 1.25;
		letter-spacing: normal;
		color: #2d3234;
	  }
	  .Rectangle-Copy-37 {
		width: 421.4px;
		height: 229px;
		margin: 69px 0px 120px 0px;
		padding: 30px 28.4px 29px 28px;
		border-radius: 25px;
		background-color: #ededed;
	  }
	  .Lorem-ipsum-dolor-si {
		width: 365px;
		height: 114px;
		margin: 2px 0 0;
				font-family: $primary_font_Regular;

		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.93;
		letter-spacing: normal;
		color: #2d3234;
	  }
	  .-XXL-Medium-Left-Dark-Copy-11 {
		width: 128px;
		height: 54px;
		margin: 0 119px 2px 118px;
				font-family: $primary_font_Regular;

		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.7;
		letter-spacing: normal;
		text-align: center;
		color: #2d3234;
	  }
	  .-Medium-Regular-Left-Dark-Copy {
		width: 641px;
		height: 195px;
		margin: 85px 0px 138px 0px;
		font-family:Arial, Helvetica, sans-serif;
		font-size: 26px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #2d3234;
	  }
	  .-Medium-Regular-Left-Dark-Copy-3 {
		padding-top: 42px;
		font-size: 40px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		color: #2d3234;
	  }
	.contentPadding{
		padding-left: 178px;
		padding-right: 178px;
		width: 100%;
	}
	.contentPaddingHeader{
		padding-left: 60px;
		padding-right: 60px;
		width: 100%;
	}
	
	.-Medium-Regular-Left-Dark-Copy-2 {
		font-family: $primary_font_Regular;
		font-size: 26px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.35;
		letter-spacing: normal;
		color: #2d3234;
	  }
	.-XXL-Medium-Left-Dark-Copy-14 {
		margin-top:  61px;
		
		font-family: $primary_font_Regular;
		font-size: 38px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: #2d3234;
	  }
	  .-XXL-Medium-Left-Dark {
		margin-top:  17px;
		
		font-family: $primary_font_Regular;
		font-size: 38px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: #2d3234;
	  }
	.lesson-title {
		color: #ffffff;
		font-size: 60px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
	}
    .module-content {
	margin: 90px;
	.module-title {
	    margin-top: 90px;
	    color: $darkMango;
	    font-size: 38px;
	}
	.module-text {
	    margin: 0px;
	}
	.module-tip {
	    margin-top: 50px;
	    border-radius: 25px 0px;
	    background-color: #ededed;
	    padding-top: 50px;
	    padding-bottom: 50px;
	    padding-left: 90px;
	    padding-right: 90px;
	}
	.module-references {
	    margin: 0px;
	    margin-top: 35px;
	}
    }
}
