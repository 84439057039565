.availability-page {
    .my-account-wrapper {
        margin-top: 25px;
        .tutor-availability {
            .onboarding-actions {
                max-width: 89%;
                margin: auto;
            }
            padding-right: 30px;
            .tutor-availability-container {
                .slots-content {
                    max-width: 95%;
                }
                .slots-content-mobile {
                    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
                    .select-all {
                        max-width: 80%;
                    }
                    .content {
                        .slots {
                            max-width: 80%;
                        }
                        .day-titles {
                            .wrapper {
                                max-width: 80%;
                            }
                        }
                    }
                }
            }
        }
        .button {
            button {
                width: 200px;
                height: 45px !important;
                background-color: $blue;
                font-family: $primary_font_SemiBold;
                margin-top: 0;
            }
        }
        @media (max-width: 768px) {
            .tutor-availability {
                padding-right: 0;
            }
            h3 {
                display: none;
            }
        }
        @media (max-width: 414px) {
            .tutor-availability {
                .tutor-availability-container {
                    .slots-content {
                        max-width: 95%;
                    }
                    .slots-content-mobile {
                        .select-all {
                            max-width: 100%;
                        }
                        .content {
                            .slots {
                                max-width: 100%;
                            }
                            .day-titles {
                                .wrapper {
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.tutor-availability {
    .background-svg {
        z-index: -1;
    }
    .onboarding-actions {
        max-width: 89%;
        margin: auto;
    }
    .tutor-availability-container {
        width: 100%;
        .onboarding-header {
            padding-bottom: 0;
        }
        .rapid-book {
            max-width: 80%;
            margin: auto;
            .head {
                display: flex;
                justify-content: center;
                .title {
                    margin: auto 0;
                    font-family: $primary_font_Bold;
                    font-size: 20.8px;
                    color: $blue;
                    margin-left: 10px;
                }
                svg {
                    width: 31px;
                }
            }
            p {
                text-align: center;
                font-size: 14px;
                font-family: $primary_font_SemiBold;
            }
        }
        .custom-check {
            label {
                span {
                    border: 2px solid $blue !important;
                }
                input:checked ~ .checkmark {
                    background-color: $blue;
                }
            }
        }
        .slots-content {
            max-width: 90%;
            margin: auto;
            .custom-check {
                height: 67.2px;
                display: flex;
                margin-bottom: 12.5px;
                height: 42.6px;
                label {
                    margin: 0;
                    font-family: $primary_font_Medium;
                    font-size: 16px;
                    span {
                        border: 2px solid $blue;
                    }
                    input:checked ~ .checkmark {
                        background-color: $blue;
                    }
                }
            }
            .day-checks {
                max-width: 90px;
                .custom-check {
                    .container {
                        margin: auto;
                        font-family: $primary_font_SemiBold;
                        line-height: 24.3px;
                    }
                }
            }
            .all-slots {
                overflow-x: scroll;
                overflow-y: hidden;
                max-width: calc(100% - 120px);
                margin-left: 30px;
                padding-bottom: 10px;
            }
            .all-slots::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
            }

            .all-slots::-webkit-scrollbar {
                width: 2px;
                height: 8.4px;
                background-color: #f5f5f5;
            }

            .all-slots::-webkit-scrollbar-thumb {
                background-color: $darkMango;
            }
            .tab-view-title {
                width: 65px;
                font-size: 1rem;
                padding: 12px 0 12px 12px;
                text-transform: uppercase;
                color: $battleshipGrey;
                display: flex;
                align-items: center;
            }
        }
        .slots-content-mobile {
            display: none;
        }
        .details {
            @extend .d-flex;
            @extend .text-center;
            margin: 75px 0px;
            justify-content: center;
            color: rgb(104, 108, 128);
            font-family: $primary_font_Medium;
            font-size: 18px;
            margin-top: 38px;
            margin-bottom: 25px;
        }
    }
    @media (max-width: 1024px) {
        .tutor-availability-container {
            .onboarding-header {
                padding-bottom: 15px;
                .heading {
                    margin-top: 10px;
                }
            }
            .details {
                margin-top: 55px;
            }
        }
    }
    @media (max-width: 768px) {
        .tutor-availability-container {
            .slots-content {
                display: none;
            }
            .slots-content-mobile {
                padding-bottom: 10px;
                box-shadow: inset 0px -10px 10px 0px rgba(0, 0, 0, 0.05);
                display: flex;
                .content {
                    margin: auto;
                    max-width: 100%;
                    position: relative;
                    .slots {
                        max-width: 80%;
                        margin: auto;
                        height: 50vh;
                        overflow: scroll;
                        scroll-behavior: smooth;
                    }
                    .day-titles {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        padding-top: 10.5px;
                        padding-bottom: 10.5px;
                        border-top: solid 1px $paleGrey;
                        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
                        .wrapper {
                            margin: auto;
                            max-width: 80%;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            .title {
                                height: 51px;
                                width: 51px;
                                display: inline-flex;
                                border-radius: 50%;
                                p {
                                    margin: auto;
                                    font-size: 12px;
                                    font-family: $primary_font_SemiBold;
                                }
                            }
                        }
                    }
                    .select-all {
                        height: 56px;
                    }
                    .down-svg {
                        position: absolute;
                        bottom: -30px;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                        text-align: center;
                        width: 42px;
                        height: 42px;
                        background-color: $vividBlue;
                        border-radius: 50%;
                        display: flex;
                        svg {
                            margin: auto;
                        }
                    }
                    .selected {
                        background: $darkMango;
                        color: $white_solid;
                    }
                }
            }
            .select-all {
                max-width: 80%;
                margin: auto;
                display: flex;
                .custom-check {
                    margin: auto 0;
                    label {
                        margin-bottom: 0;
                        font-size: 16px;
                        font-family: $primary_font_Medium;
                        span {
                            border: 2px solid $paleGrey;
                        }
                    }
                }
            }
            .details {
                margin-top: 38px;
            }
        }
    }
    @media (max-width: 414px) {
        .tutor-availability-container {
            .details {
                margin-top: 25px;
                font-size: 16;
            }
        }
    }
}
