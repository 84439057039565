.looking-for {
    @extend .position-relative;
    .card-container {
        width: 360px;
        height: 325px;
        border-radius: 0 0 25px 0;
        box-shadow: 0 10px 10px 0 $light_black;
        background-color: $white_solid;
        align-items: center;
        display: grid;
    }
    .button {
        position: inherit;
        user-select: none;
    }
    .onboarding-header{
        .heading{
            font-size: 18px;
        }
        .sub-heading{
            font-size: 38px;
            font-family: $primary_font_Regular;
        }
    }
   
    @media (max-width: 413px) {
        .onboarding-header {
            .heading {
                font-size: 16px;
            }
            .sub-heading {
                font-size: 2.5rem;
            }
        }
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 40px auto;
                height: 181px;
                padding: 0;
                .content {
                    width: 300px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                        .heading {
                            font-size: 1.375rem;
                        }
                        .sub-heading {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
        .button {
            display: flex;
            button {
                margin: 70px auto;
            }
        }
    }
    @media (min-width: 390px) and (max-width: 768px) {
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 55px auto;
                height: 181px;
                .content {
                    width: 354px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
        .button {
            display: flex;
            button {
                margin: 20px auto;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 40px auto;
                height: 181px;
                .content {
                    width: 354px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }

        .button {
            display: flex;
            button {
                margin: 70px auto;
            }
        }
    }
    @media (min-width: 1024px){
        .multiple-cards{
            margin-top: 0;
            padding: 4rem 0;
        }
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
        .option-screen-component .multiple-cards {
            display: block !important;
            .intent-card {
                margin: 40px auto;
                height: 181px;
                .content {
                    width: 354px;
                    img {
                        height: 80px;
                        width: 80px;
                    }
                    .text-container {
                        padding: 17px 20px;
                    }
                }
            }
            .card-view {
                margin: auto;
            }
        }
        .button {
            display: flex;
            button {
                margin:0;
            }
        }
    }
    @media (min-width: 120px) {
    }

    
}
.button button.tutor-button {
    width: 320px;
    margin: 10px auto;
}