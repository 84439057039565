.subscription-options-onBoarding{
    .subscription-options{
        .subscription-heading{
            font-size: 38px;
            font-weight: bold;
            @media (min-width: 1024px) and (max-width: 1399.5px) {
               
                font-size: 30px;
            }
            @media (min-width: 1400px) {
              
            }

            @media (max-width: 768px) {
                font-size: 170% !important;
            }
        }
        .subscription-subhead{
            margin-top: 0;
        }
        .subscription-subhead-no-bottom {
            @media (min-width: 1024px) and (max-width: 1399.5px) {
                font-size: 14.4px;
                margin-top: -8px;
                margin-bottom: 2%;
            }
            @media (min-width: 1400px) {
                font-size: 18px;
            }
        }
    }
    .subscription-options-back-btn{
        display: inline-block;
        position:relative;
        top: 68px;
        left: -25px;
    }
    .subscription-options-back-btn-inapp{
        display: inline-block;
        position:relative;
        top: 40px;
        left: -25px;
    }
    .top-space {
        margin-top: 2%;
    }
    .color-logo-sigin-screen{
        height: 75px;
        width: 237px;
        top: 1rem;
    }
    .left-space {
        position: relative;
        left: -7%;
        @media(min-width: 1400px){
            left: -20%;
        }
        @media (min-width: 321px) and (max-width: 767px) {
            left: 8%;
            width:auto;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
            left: 2%;
        }
        @media (max-width: 320px) {
            left: 3%;
            width:auto;
        }
    }
    .right-space {
        float: right;
        margin-top: 2%;
        cursor: pointer;
        @media (min-width: 1024px) and (max-width: 1399.5px) {
            position: relative;
            right: -6%;
        }
        @media (min-width: 1400px) {
            position: relative;
            right: -20%;
        }
        @media (max-width: 320px) {
            margin-top: -11%;
            margin-right: 4%;
        }
        @media (min-width: 321px) and (max-width: 767px) {
            margin-top: -10%;
            margin-right: 4%;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
            margin-top: 3%;
            margin-right: 6%;
        }
    }
    .bottom-space{
        margin-bottom: 2.5%;
        @media (min-width: 1024px) and (max-width: 1399.5px) {
            margin-top: -3%;
        }
        @media (max-width: 320px) {
            margin-bottom: 5%;
        }
        @media (min-width: 321px) and (max-width: 767px) {
            margin-bottom: 4%;
        }
    }
    .subscription-subhead-no-bottom {
        @media (min-width: 1400px) {
            margin-bottom: 15px;
        }
    }
    .view-lesson-button {
        width: 160px;
        height: 37px;
    }
 
    @media (max-width:1023px) {
            .subscription-options{
                .subscription-heading{
                    font-size: 48px;
                }
            }
            .subscription-options-back-btn{
                top: 0;
                left:20px;
            }
            .subscription-options-back-btn-inapp{
                top: 0;
                left:20px;
            }
    }
    @media (max-width:767px) {
            .subscription-options{
                .subscription-head{
                    font-size: 40px;
                }
            }
        }
}
