.new-chat-modal {
    .new-chat-modal-body {
        display: flex;
        height: 100%;
        width: 100%;
        .new-chat-modal-content {
            margin: auto;
            padding: 25px;
            width: 65vw;
            height: auto;
            background: white;
            border-radius: 25px 0px;
            .close-modal-button {
                text-align: end;
                color: $darkMango;
                cursor: pointer;
            }
            .container {
                padding: 80px;
                .title {
                    font-family: Montserrat-Medium;
                    font-size: 30px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.53;
                    letter-spacing: normal;
                    text-align: center;
                    color: #686c80;
                }

                .button-content {
                    display: flex;
                    padding-top: 50px;
                    justify-content: space-around;
                }

                .filter-input__control {
                    padding-left: 20px;
                    border: 0px solid #ffffff;
                    height: 65px;
                    overflow: auto;
                    width: 100%;
                    border-radius: 7px;
                    box-shadow: 0 10px 10px 0 #0000000d;
                }
                .filter-input__indicators {
                    display: none;
                }
                .filter-input__menu {
                    overflow: hidden;
                    height: 11em;
                    display: block;
                    margin: 0px;
                    border: 0px solid #ffffff;
                    box-shadow: 0 10px 10px 0 #0000000d;
                }
                .filter-input__multi-value {
                    background-color: black;
                    color: white;
                    border-radius: 4px;
                    .filter-input__multi-value__label {
                        color: white;
                    }
                    .filter-input__multi-value__remove {
                        cursor: pointer;
                        &:hover {
                            color: #a7a7a2;
                            background-color: black;
                        }
                    }
                }
            }
        }
    }
}
