$battleshipGrey: #686c80;
$bgWhite:#fff;
.filters-panel {
    max-height: 93vh;
    overflow-y: auto;
    padding: 1rem 2rem 2rem 3rem;
    margin-bottom: -9px;
    // padding-left: 3rem;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
    }
    @media(max-width: 1024px){
        padding: 1rem 1rem 2rem 2rem;
    }
    @media(max-width: 768px){
        max-height: none;
    }

    .switch-container {
        padding: 12px;
    }

    .range-slider-container {
        // padding-top: 10px;
        padding-bottom: 30px;
    }
    .availability-filter-container {
        padding: 12px;
    }
    .availability-filter-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .select-all-switch {
            color: $battleshipGrey;
            align-items: center;
            display: flex;
            flex-direction: row;
        }
    }

    
}

.search-button-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 30px;
}
.Our-Rapid-Book-live {
    .oops{
        img{
            width: 120px;
        }
        h2{
            font-family: $primary_font_Bold;
            font-size: 30px;
            font-weight: 600;
            margin-top :20px;
        }
        p{
            font-size :19px;
            margin-top: 6px;
            a{
                text-decoration: underline;
                color:$vividBlue;
                font-size :19px;
            }
        }
    }
    p{
    justify-content: center;
    align-items: center;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #2d3234;
    font-size: 25px;
    margin-top :30px;
    span{
        font-family: $primary_font_Bold;
        font-size: 28px;
    }
    }
    .reshedule-button {
             width: 413px;
            height: 81px;
            padding: 16px 26px 17.4px 27px;
            border-radius: 47.6px;
            font-size: 18px;
            background-color: $vividBlue;
            margin-top:20px;
      }
  }
  @media(max-width: 1024px){
    .Our-Rapid-Book-live {
        .oops{
            h2{
                font-size: 27px;
                font-weight: 500;
                
            }
            p{
                font-size :18px;
                a{
                    text-decoration: underline;
                    color:$vividBlue;
                    font-size :18px;
                }
            }
        }
    }
}

@media(max-width: 414px){
    .Our-Rapid-Book-live {
        p{
            font-size: 24px;
            }
        .reshedule-button {    
            width: 353px;
            height: 81px;
            padding: 17px 20px 16.4px;
            border-radius: 47.6px;
            font-size: 16px;
            }
    }
}